import { AnyObject } from 'common/interfaces';

interface DefaultColumnsProps {
  columnId?: string;
}

interface ColumnsDataProps extends DefaultColumnsProps {
  isChecked: boolean;
}

export const getCustomizedOrder = (
  customizedColumnsData: ColumnsDataProps[],
  defaultArray: DefaultColumnsProps[]
) => {
  const newSettingsArray: any[] = [];
  let currentColumn: AnyObject = {};

  customizedColumnsData.forEach(columnCustomized => {
    const foundCheckedItem = defaultArray.find(
      columnDefault => columnDefault.columnId === columnCustomized.columnId && columnCustomized.isChecked
    );
    if (foundCheckedItem) {
      newSettingsArray.push(foundCheckedItem);
    }
  });

  defaultArray.forEach(columnDefault => {
    const foundExistingSetting = customizedColumnsData.find(columnCustomized => {
      currentColumn = columnDefault;
      return columnDefault.columnId === columnCustomized.columnId;
    });

    if (!foundExistingSetting) {
      newSettingsArray.push(currentColumn);
    }
  });

  return newSettingsArray;
};

export const lastAndCurrentYears = ['Letztes Jahr', 'Aktuelles Jahr'];

export const threeYearsArray = ['Letztes Jahr', 'Aktuelles Jahr', 'Nächstes Jahr'];

