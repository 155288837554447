import React, { useContext } from 'react';
import { Bar } from 'react-chartjs-2';
import classes from './mileage-chart.module.scss';
import { MileageChartDataProps } from './mileage-chart.props';
import { CarOnRoadIcon } from 'components/icons-new/car-on-road';
import { Distance } from 'common/common-components.styled';
import { useTheme } from 'styled-components';
import { EmptyDataIcon } from 'components/icons-new/empty-data';
import { PAGES } from 'common/enums';
import { CRM_VALUE, emtyFilterState, emtyFilterStateForServer } from 'pages/my-vehicles/my-vehicles';
import {
  MileageRecommendationValueEnum,
  MileageRecommendationLabelEnum,
  VehicleStateCodeValue,
  VehicleStateCodeLabel,
  VehicleStatusesLabel,
  VehicleStatusesValue
} from 'pages/my-vehicles/my-vehicles.props';
import { FleetManagerDashboardContext } from '../dashboard.context';
import { ChartData } from 'chart.js';

export const MileageChart = ({ data }: MileageChartDataProps) => {
  const { openPage } = useContext(FleetManagerDashboardContext);

  const theme = useTheme();

  const averageCars = data?.laufleistung?.allCount || 0;
  const increaseMileageCars = data?.laufleistung?.erhohenKilometerstand || 0;
  const reduceMileageCars = data?.laufleistung?.verringernKilometerstand || 0;
  const minValue = averageCars * 0.1;
  const adjustedAverageCars = averageCars !== 0 ? Math.max(averageCars, minValue) : 0;
  const adjustedIncreaseMileageCars = increaseMileageCars !== 0 ? Math.max(increaseMileageCars, minValue) : 0;
  const adjustedReduceMileageCars = reduceMileageCars !== 0 ? Math.max(reduceMileageCars, minValue) : 0;

  const chartData: ChartData<'bar'> = {
    labels: ['1', '2', '3'],
    datasets: [
      {
        label: 'Dataset 1',
        data: [adjustedAverageCars],
        backgroundColor: theme.colors.lightBlue,
        hoverBackgroundColor: theme.colors.lightBlue,
        stack: 'Stack',
        barThickness: 22,
        borderWidth: { top: 1, right: 4, bottom: 1, left: 4 },
        borderColor: theme.colors.white,
        hoverBorderColor: theme.colors.white,
        borderSkipped: false,
        borderRadius: {
          topLeft: 8,
          topRight: 8,
          bottomLeft: 8,
          bottomRight: 8
        }
      },
      {
        label: 'Dataset 2',
        data: [adjustedIncreaseMileageCars],
        backgroundColor: theme.colors.blue,
        hoverBackgroundColor: theme.colors.blue,
        stack: 'Stack',
        barThickness: 22,
        borderWidth: { top: 1, right: 4, bottom: 1, left: 4 },
        borderColor: theme.colors.white,
        hoverBorderColor: theme.colors.white,
        borderSkipped: false,
        borderRadius: {
          topLeft: 8,
          topRight: 8,
          bottomLeft: 8,
          bottomRight: 8
        }
      },
      {
        label: 'Dataset 3',
        data: [adjustedReduceMileageCars],
        backgroundColor: theme.colors.darkGold,
        hoverBackgroundColor: theme.colors.darkGold,
        stack: 'Stack',
        barThickness: 22,
        borderWidth: { top: 1, right: 4, bottom: 1, left: 4 },
        borderColor: theme.colors.white,
        hoverBorderColor: theme.colors.white,
        borderSkipped: false,
        borderRadius: {
          topLeft: 8,
          topRight: 8,
          bottomLeft: 8,
          bottomRight: 8
        }
      }
    ]
  };

  const chartOptions = {
    scales: {
      x: {
        stacked: true,
        display: false
      },
      y: {
        stacked: true,
        display: false
      }
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false
      }
    },
    elements: {
      point: {
        radius: 5
      }
    },
    responsive: true,
    maintainAspectRatio: false
  };

  const openPageMyVehicleMillageIncrease = openPage({
    page: PAGES.MY_VEHICLES,
    companyCrmName: CRM_VALUE.company,
    filterForServer: {
      ...emtyFilterStateForServer,
      [CRM_VALUE.mileageRecommendation]: MileageRecommendationValueEnum.Increase,
      [CRM_VALUE.stateCode]: [VehicleStateCodeValue.Active],
      [CRM_VALUE.status]: [
        VehicleStatusesValue.InOperation,
        VehicleStatusesValue.ControlRequested,
        VehicleStatusesValue.InControl
      ]
    },
    filterForClient: {
      ...emtyFilterState,
      [CRM_VALUE.mileageRecommendation]: MileageRecommendationLabelEnum.Increase,
      [CRM_VALUE.stateCode]: [VehicleStateCodeLabel.Active],
      [CRM_VALUE.status]: [
        VehicleStatusesLabel.InOperation,
        VehicleStatusesLabel.ControlRequested,
        VehicleStatusesLabel.InControl
      ]
    }
  });

  const openPageMyVehicleMillageReduce = openPage({
    page: PAGES.MY_VEHICLES,
    companyCrmName: CRM_VALUE.company,
    filterForServer: {
      ...emtyFilterStateForServer,
      [CRM_VALUE.mileageRecommendation]: MileageRecommendationValueEnum.Reduce,
      [CRM_VALUE.stateCode]: [VehicleStateCodeValue.Active],
      [CRM_VALUE.status]: [
        VehicleStatusesValue.InOperation,
        VehicleStatusesValue.ControlRequested,
        VehicleStatusesValue.InControl
      ]
    },
    filterForClient: {
      ...emtyFilterState,
      [CRM_VALUE.mileageRecommendation]: MileageRecommendationLabelEnum.Reduce,
      [CRM_VALUE.stateCode]: [VehicleStateCodeLabel.Active],
      [CRM_VALUE.status]: [
        VehicleStatusesLabel.InOperation,
        VehicleStatusesLabel.ControlRequested,
        VehicleStatusesLabel.InControl
      ]
    }
  });

  return (
    <div className={classes.container}>
      {averageCars > 0 || increaseMileageCars > 0 || reduceMileageCars > 0 ? (
        <>
          <div className={classes.canvas_block}>
            <Bar data={chartData} options={chartOptions} />
          </div>
          <div className={classes.data_block}>
            <div className={classes.data_block_item} onClick={openPageMyVehicleMillageIncrease}>
              <div className={classes.data_block_item_count}>
                <CarOnRoadIcon color={theme.colors.darkGold} />
                <span>{increaseMileageCars}</span>
              </div>
              <p>vertragl. Laufleistung sollte erhöht werden</p>
            </div>
            <Distance top='40' />
            <div className={classes.data_block_item} onClick={openPageMyVehicleMillageReduce}>
              <div className={classes.data_block_item_count}>
                <CarOnRoadIcon color={theme.colors.blue} />
                <span>{reduceMileageCars}</span>
              </div>
              <p>vertragl. Laufleistung sollte verringert werden</p>
            </div>
          </div>
        </>
      ) : (
        <div className={classes.empty_data}>
          <EmptyDataIcon hovered />
          <span>
            Es gibt keine Daten
            <br /> zu Laufleistung
          </span>
        </div>
      )}
    </div>
  );
};
