import authService from './auth-service';
import axios, { AxiosResponse } from 'axios';
import { RequestDataModel, ResponseModel } from 'common/interfaces';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export interface CarDealershipRequestData extends RequestDataModel {
  carBrands: string[];
}

export async function updateCarDealership(data: CarDealershipRequestData): Promise<AxiosResponse<ResponseModel>> {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/autohausEinstellung/update`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data
  });
}

export async function createCarDealership(data: CarDealershipRequestData): Promise<AxiosResponse<ResponseModel>> {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/autohausEinstellung/create`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data
  });
}

export async function getCarDealerships(accountId: string): Promise<AxiosResponse<ResponseModel>> {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/autohausEinstellung/getList`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      accountId
    }
  });
}
