import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import RequestCar from '../../../components/request-car/RequestCar-redux';
import { useDispatch, useSelector } from 'react-redux';
import { setForm, setSnackbarComponent, toggleModal } from '../../../actions/app_action';
import {
  BASKET_TYPES,
  CONFIGURATION_TYPES,
  crmDataTypes,
  MAX_DISTANCE,
  MIN_DISTANCE,
  MODAL_NAMES,
  MODALS,
  OFFER_STATUSES,
  REQUEST_STATUS,
  RequestStatuses,
  TAXES_COEFFICIENT,
  VEHICLE_OFFER_ASSIGNMENT,
  VEHICLE_OFFER_EVENT_DESCRIPTIONS,
  VEHICLE_OFFER_STATUSES
} from '../../../constants';
import { sendNotification } from '../../../services/user-service';
import { useOverlay } from '../../../hooks';
import Configurator from '../../../components/configurator/configurator-redux';
import { Button, Distance, Divider, Flex, Text, Title } from '../../../common/common-components.styled';
import {
  AnyObject,
  FileModel,
  LeasingOffer,
  ResponseModel,
  User,
  VehicleOfferEvent
} from '../../../common/interfaces.js';
import {
  StyledButton,
  StyledButtonsContainer,
  StyledConfiguratorContainer,
  StyledCostContainer,
  StyledDistance,
  StyledFileComponent,
  StyledFilesContainer,
  StyledInfoBlockTitle,
  StyledInfoBlockValue,
  StyledInfoCard,
  StyledInfoCardsContainer,
  StyledOneTimeCostsContainer,
  StyledOrderWidget,
  StyledSubmitButtonContainer
} from './order-widget.styled';
import MonthsComponent, {
  MonthsData
} from '../../../components/configuration-from-pdf/months-component/months-component';
import DistanceComponent, {
  DistanceData
} from '../../../components/configuration-from-pdf/distance-component/distance-component';
import DeliveryDateComponent, {
  DeliveryDateData
} from '../../../components/configuration-from-pdf/delivery-date-component/delivery-date-component';
import { CircleCheckMark } from '../../../components/icons/circle-check-mark.js';
import { useTheme } from 'styled-components';
import { UsersIcon } from '../../../components/icons/users-icon.js';
import { DateInfo } from 'components/product/product-view';
import { convertUTCDateToLocalDate, getDateString } from '../../../utils/convert-UTC-date-to-local-date.js';
import { OrderIcon } from '../../../components/icons/order-icon.js';
import InfoHint from '../../../components/info-hint';
import ApproversInfo from '../approvers-info/approvers-info';
import DeclineDialog from '../decline-dialog/decline-dialog';
import { CalendarIcon } from 'components/icons/calendar-icon';
import { getMoneyValue } from 'utils/get-money-value';
import LeasingbankInfo from 'pages/details/leasingbank-info/leasingbank-info';
import { getFormValues } from 'utils/get-form-values';
import { getRequestItem } from 'utils/get-response-data';
import {
  createEvent,
  duplicateFahrzeugangebote,
  updateLeasingOffer,
  updateVehicleOffer
} from 'services/fleet-vehicle-service';
import { AxiosResponse } from 'axios';
import sharepointService from 'services/sharepoint-service';
import Snackbar from 'components/snackbar';
import { useHistory, useLocation } from 'react-router-dom';
import Spinner from 'components/spinner';
import { hasDuplicates } from 'utils/has-duplicates';
import { decodeBase64, encodeBase64 } from 'utils/decode-encode-base64';
import { copyToPersonalConfig, getLeasingangebotes } from 'components/barpreisanfragen-component/utils';
import { getLeasingOffersData } from 'pages/details/details-view';
import LoadingMessage from 'pages/details/loading-message/loading-message';
import classes from '../../../components/alert/alert.module.scss';
import BudgetScale from 'pages/details/budget-scale/budget-scale';
import { CircleErrorMark } from 'components/icons/circle_error_mark';
import { useQuery } from 'hooks';
import { InfoBlockProps, InfoCardProps, OrderWidgetProps } from 'pages/details/order-widget/order-widget.props';
import VehicleContainer from 'pages/existing-vehicle-page/vehicle-container/vehicle-container';
import { ClipIcon } from 'components/icons/clip-icon';
import DocumentClassicComponent from 'components/document-classic-component/document-classic-component';
import { useVehicleListQuery } from 'hooks/react-query/vehicle-list';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { isEmpty } from 'lodash';
import { SnackbarComponentTypes } from 'components/snackbar-component/snackbar-component.props';
import SuccessSnackbar from 'pages/details/success-snackbar';
import { Tooltip } from '@mui/material';
import { PAGES } from 'common/enums';
import { AdditionalDataPanel } from 'components/barpreisanfragen-component/components/leasing-request/leasing-request';
import cloneDeep from 'lodash/cloneDeep';
import DealerContactInfo from 'pages/details/dealer-contact-info/dealer-contact-info';
import {
  getCheapestLeasingOffer,
  getLowestLeasingFactorLeasingOffer,
  getOneTimeCost
} from 'pages/details/order-widget/utils';
import { SettingsIconOutlined } from 'components/icons/settings-icon-outlined';
import { useForm } from 'react-hook-form';
import DealerCompanyDetails from 'pages/details/dealer-company-details';
import CarDealershipSelection from 'pages/details/car-dealership-selection';
import { useBudgetDataAndFuelCost } from 'pages/details/react-query/use-budget-data-and-fuel-cost';
import GrvLeasingOffersSwitcher from 'pages/details/grv-leasing-offers-switcher';
import {
  GrvLeasingOffersSwitcherTypes,
  SwitcherOption
} from 'pages/details/grv-leasing-offers-switcher/grv-leasing-offers-switcher.props';
import { getUniqueValues } from 'pages/details/grv-leasing-offers-switcher/utils';
import { fleetManagerReleaseOrder } from 'services/event-service';
import { useUserCompaniesReferenceValues } from 'hooks/use-user-companies-reference-values';
import { OrderWidgetPeriodIcon } from 'components/icons/order-widget-period-icon';
import { OrderWidgetDistanceIcon } from 'components/icons/order-widget-distance-icon';
import VehicleOrderRedirect from 'components/vehicle-delivery-redirect';
import { VehicleOrderRedirectTypes } from 'components/vehicle-delivery-redirect/vehicle-order-redirect.types';
import useOfferStatus from 'hooks/react-query/use-offer-status';
import useLatestEvents from 'hooks/use-latest-events';
import useOldestEvents from 'hooks/use-oldest-events';
import { InfoHintType } from 'components/info-hint/info-hint.props';
import ThreeStateCheckbox from 'components/three-state-checkbox';
import useAcceptConditions from './hooks/use-accept-conditions';
import { copyFaToPersonalConfig, createApproveRecords } from 'services/vehicle-service';
import { useGetFilesByDocumentTypeQuery } from 'hooks/react-query/sharepoint-documents/get-files-by-document-type/get-files-by-document-type';
import { useDocumentTypeByAppQuery } from 'hooks/react-query/document-type/document-type-by-app/document-type-by-app';
import {
  documentTypeByAppDataQueryOptions,
  getFilesByDocumentTypeQueryOptions
} from 'pages/damage-detail-page/components/damage-document-section/components/downloaded-documents/downloaded-documents.query-opitons';
import ApproveStatus from '../approve-status';
import useApprovalRecords from '../approve-status/hooks/use-approvals';
import useLatestValue from 'hooks/use-latest-value';
import { sendRequestToCarDealer } from 'services/vehicle-offer-service';

export const InfoCard = ({ value, units, icon, reference }: InfoCardProps) => {
  return (
    <StyledInfoCard reference={reference} justify='space-between' align='flex-start'>
      <Flex direction='column'>
        <Text id={`infoCardValue${units}`} color='white' bold size='24'>
          {value}
        </Text>
        <Text color={reference ? 'grey400' : 'blue'} size='12' bold uppercase>
          {units}
        </Text>
      </Flex>
      {icon}
    </StyledInfoCard>
  );
};

export const InfoBlock = ({ title, value, align = 'center', textAlign = 'right' }: InfoBlockProps) => {
  return (
    <Flex justify='space-between' align={align}>
      <StyledInfoBlockTitle>{title}</StyledInfoBlockTitle>
      <StyledInfoBlockValue textAlign={textAlign}>{value}</StyledInfoBlockValue>
    </Flex>
  );
};

const SUBMIT_BUTTON_TEXT = {
  [OFFER_STATUSES.configurationCreated]: 'Leasingangebot anfragen',
  [OFFER_STATUSES.offerRequested]: 'Autohaus auswählen',
  [OFFER_STATUSES.requirementApproved]: 'Leasingfahrzeug anfordern',
  [OFFER_STATUSES.purchaseTriggered]: 'Leasinganforderung freigeben'
};

export const SUBMIT_GRV_BUTTON_TEXT_DRIVER = {
  [OFFER_STATUSES.vehicleConfigurationBasedOnGRVCreated]: 'Leasingfahrzeug anfordern'
};

export const SUBMIT_GRV_BUTTON_TEXT_FLEET_MANAGER = {
  [OFFER_STATUSES.vehicleConfigurationBasedOnGRVCreated]: 'Zur Autohausauswahl',
  [OFFER_STATUSES.vehicleRequested]: 'Preise und Baubarkeit anfragen',
  [OFFER_STATUSES.buildAbilityConfirmed]: 'Leasinganforderung freigeben',
  [OFFER_STATUSES.approvedByFleetManager]: 'Bestellfreigabe erteilen'
};

const defaultDealerInfoSection = {
  name: 'Händler-Kontaktinformationen anzeigen',
  expand: false,
  leasingInputs: []
};

const firstDayCurrentMonth = (() => {
  const date = new Date();
  return new Date(date.getFullYear(), date.getMonth(), 1);
})();

const twoYearsFromNow = (() => {
  const date = new Date();
  date.setFullYear(date.getFullYear() + 2);
  return date;
})();

const OrderWidget = ({
  leasingOffer,
  item,
  refreshData,
  leasingOffers,
  changeActiveLeasingOffer,
  setFilteredGRVLeasingOffers,
  filteredGRVLeasingOffers,
  referenceLeasingOffer
}: OrderWidgetProps) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const forms = useSelector((state: AnyObject): AnyObject => state['app'].forms);
  const formsLatest = useLatestValue(forms);
  const vehicleListQueryKey = useTypedSelector(state => state.app.vehicleListQueryKey);
  const theme = useTheme();
  const reactHookFormData = useForm();
  const {
    handleSubmit,
    formState: { isValid }
  } = reactHookFormData;
  const [showOverlay, hideOverlay] = useOverlay();
  const user = useSelector((state: AnyObject): User => state['app'].user);
  const [formErrors, setFormErrors] = useState(false);
  const [showApproversInfo, setShowApproversInfo] = useState(false);
  const [showDeclineDialog, setShowDeclineDialog] = useState(false);
  const {
    offerRequestedEvent,
    vehicleRequestedEvent,
    approvedByFleetManagerEvent,
    approvalRejectedEvent,
    globalReferenceVehicleGeneratedViaAPIEvent,
    latestEvent,
    budgetCheckPositive,
    orderApprovedEvent
  } = useLatestEvents(item.events);
  const {
    configurationCreatedEvent,
    vehicleConfigurationBasedOnGRVCreatedEvent,
    offerPublishedForUsersEvent,
    vehicleBasedOnBulkOrderCreatedEvent
  } = useOldestEvents(item.events);

  const [loading, setLoading] = useState(true);
  const [files, setFiles] = useState<FileModel[]>([]);
  const [snackbarText, setSnackbarText] = useState({ type: 'success', text: '' });
  const [dealerInfoSection, setDealerInfoSection] = useState(defaultDealerInfoSection);

  const query = useQuery();
  const queryData = query.data ? JSON.parse(decodeBase64(query.data as string)) : null;
  const driverId: string | null = queryData?.driverId || null;
  const driverLevel: string | null = queryData?.uds_carpolicynutzer || null;
  const responsiblePerson: string | null = queryData?.uds_kstverantw || null;
  const oldCar: string | null = queryData?.uds_altes_fahrzeugid || null;
  const vehicleOfferAssignment = driverId
    ? VEHICLE_OFFER_ASSIGNMENT.employeeAssigned.value
    : VEHICLE_OFFER_ASSIGNMENT.poolCar.value;
  const basketTypeCode = driverId ? BASKET_TYPES.personal.value : BASKET_TYPES.poolcar.value;
  const isGlobalReferenceVehicle = !!globalReferenceVehicleGeneratedViaAPIEvent;
  const isBasedOnGlobalReferenceVehicle = !!vehicleConfigurationBasedOnGRVCreatedEvent;
  const isBulkOrderVehicle =
    !!offerPublishedForUsersEvent && item.uds_configurationtypecode_value === CONFIGURATION_TYPES.bulkOrder.value;
  const isBasedOnBulkOrderVehicle = !!vehicleBasedOnBulkOrderCreatedEvent;
  const isPoolcar = item.uds_zuordnung_value === VEHICLE_OFFER_ASSIGNMENT.poolCar.value;
  const isShowArchiveButton =
    !item.uds_configurationtypecode_value ||
    item.uds_configurationtypecode_value === CONFIGURATION_TYPES.personalConfiguration.value;

  const { referenceDistance, referenceDuration } = useUserCompaniesReferenceValues();

  const [months, setMonths] = useState<MonthsData>({
    name: 'uds_wunschlaufzeitcode',
    crmFieldType: crmDataTypes.Picklist,
    valueFromCompany: false,
    value: 36
  });

  const [distance, setDistance] = useState<DistanceData>({
    name: 'uds_laufleistungkm',
    crmFieldType: crmDataTypes.Integer,
    value: MIN_DISTANCE
  });

  const { refetch: refetchVehicleList } = useVehicleListQuery({
    ...vehicleListQueryKey
  });

  const isReferenzfahrzeugeDetailsPage = location.pathname.includes(PAGES.REFERENCE_VEHICLES_DETAILS);
  const isMitarbeiterkonfigurationenDetailsPage = location.pathname.includes(PAGES.EMPLOYEE_CONFIGURATION_DETAILS);
  const isKonfigurationenDetailsPage = location.pathname.includes(PAGES.CONFIGURATION_DETAILS);
  const isDriverReferenzfahrzeugeDetailsPage =
    location.pathname.includes(PAGES.CONFIGURATION_DETAILS) &&
    item.new_status_anfrage_value === REQUEST_STATUS.newConfiguration;
  const isApprovalDetails = location.pathname.includes(PAGES.APPROVALS_DETAILS);

  const { documentTypeByAppData } = useDocumentTypeByAppQuery(
    isKonfigurationenDetailsPage ? PAGES.CONFIGURATION_DETAILS : PAGES.REFERENCE_VEHICLES_DETAILS,
    documentTypeByAppDataQueryOptions,
    isBulkOrderVehicle
  );

  const documentTypeIds = useMemo(
    () => documentTypeByAppData?.data?.documentTypes?.map(({ id }) => id) || [],
    [documentTypeByAppData?.data?.documentTypes?.length]
  );

  const { files: bulkOrderFiles, isLoading: bulkOrderFilesLoading } = useGetFilesByDocumentTypeQuery(
    {
      entityId: item.uds_fahrzeugangeboteid,
      documentTypeIds
    },
    { ...getFilesByDocumentTypeQueryOptions, options: { enabled: isBulkOrderVehicle && !!documentTypeIds.length } }
  );

  const { offerStatus } = useOfferStatus(
    item.events,
    isReferenzfahrzeugeDetailsPage || isMitarbeiterkonfigurationenDetailsPage,
    isDriverReferenzfahrzeugeDetailsPage
  );
  const { acceptConditions, toggleAcceptConditions } = useAcceptConditions();
  const { approvalRecords, refetchApprovalRecords } = useApprovalRecords(
    item.uds_fahrzeugangeboteid,
    isBasedOnBulkOrderVehicle || isBasedOnGlobalReferenceVehicle
  );

  const oneTimeCost = useMemo(() => {
    return getOneTimeCost(item);
  }, [item]);

  const isUseReferenceLeasingOffer = isGlobalReferenceVehicle || isBasedOnGlobalReferenceVehicle;

  const priceWithoutFuelCost =
    isBasedOnGlobalReferenceVehicle &&
    ([
      OFFER_STATUSES.vehicleConfigurationBasedOnGRVCreated,
      OFFER_STATUSES.vehicleRequested,
      OFFER_STATUSES.dealershipSelected
    ].includes(offerStatus) ||
      ([OFFER_STATUSES.buildAbilityConfirmed].includes(offerStatus) && isKonfigurationenDetailsPage))
      ? item.lowestLeasingprice_value
      : isUseReferenceLeasingOffer
      ? referenceLeasingOffer?.leasingprice_value ||
        referenceLeasingOffer?.uds_gesamtkosten_monatlich_vertragsabschluss_value
      : leasingOffer?.leasingprice_value || leasingOffer?.uds_gesamtkosten_monatlich_vertragsabschluss_value;
  const estimatedPriceWithoutFuelCost =
    leasingOffer?.leasingprice_value || leasingOffer?.uds_gesamtkosten_monatlich_vertragsabschluss_value;

  const { budgetData, fuelCost } = useBudgetDataAndFuelCost(
    isGlobalReferenceVehicle || isBasedOnGlobalReferenceVehicle,
    item,
    (isUseReferenceLeasingOffer ? referenceLeasingOffer : leasingOffer) || ({} as LeasingOffer),
    driverLevel,
    isMitarbeiterkonfigurationenDetailsPage
      ? isBasedOnGlobalReferenceVehicle
        ? vehicleConfigurationBasedOnGRVCreatedEvent?.uds_erstelltfurid.attributeValue?.id || null
        : configurationCreatedEvent?.uds_erstelltfurid.attributeValue?.id || null
      : driverId
  );

  const price = (priceWithoutFuelCost || 0) + fuelCost;
  const supplement = budgetData
    ? budgetData.isDriverSupplementMandatory
      ? price >= budgetData.surcharge
        ? budgetData.surcharge
        : price
      : price - budgetData.budget
    : null;

  const [switcherDurationValue, setSwitcherDurationValue] = useState<SwitcherOption | null>(null);
  const [switcherDistanceValue, setSwitcherDistanceValue] = useState<SwitcherOption | null>(null);
  const [switcherError, setSwitcherError] = useState(false);

  const GrvLeasingOffersSwitcherData = useMemo(() => {
    return {
      [GrvLeasingOffersSwitcherTypes.Distance]: {
        icon: <OrderWidgetDistanceIcon />,
        units: 'Km',
        options: getUniqueValues(leasingOffers, 'uds_laufleistungpa').map(value => ({
          label: value.toLocaleString('de-DE'),
          value: value
        })) as SwitcherOption[]
      },
      [GrvLeasingOffersSwitcherTypes.Duration]: {
        icon: <OrderWidgetPeriodIcon />,
        units: 'Monate',
        options: getUniqueValues(leasingOffers, 'uds_laufzeit').map(value => ({
          label: String(value),
          value: value
        })) as SwitcherOption[]
      }
    };
  }, [leasingOffers.length]);

  const [deliveryDate, setDeliveryDate] = useState<DeliveryDateData>({
    name: 'uds_wunschlieferdatum',
    crmFieldType: crmDataTypes.DateTime,
    value: null
  });

  const BUDGET_STATUSES = {
    success: {
      icon: <CircleCheckMark width={16} height={16} color={theme.colors.green} />,
      color: 'green',
      tooltip: 'Deine Mobilitätskosten sind im Rahmen Deines Mobiltätsbudgets.'
    },
    warning: {
      icon: <CircleCheckMark width={16} height={16} color={theme.colors.green} />,
      color: 'green',
      tooltip: 'Deine Mobilitätskosten übersteigen das Mobilitätsbudget und Du bist im Bereich der Eigenzuzahlung.'
    },
    error: {
      icon: <CircleErrorMark width={16} height={16} />,
      color: 'pink',
      tooltip: 'Deine Mobilitätskosten übersteigen das Mobilitätsbudget und ggf. eine mögliche Eigenzuzahlung.'
    }
  };

  const budgetStatus = useMemo(() => {
    if (!budgetData) {
      return 'success';
    }
    if (
      budgetData.isDriverSupplementMandatory && budgetData.surcharge
        ? price <= budgetData.budget + budgetData.surcharge
        : price > budgetData.budget && price <= budgetData.budget + budgetData.surcharge
    ) {
      return 'warning';
    }
    if (price <= budgetData.budget) {
      return 'success';
    }
    return 'error';
  }, [budgetData, priceWithoutFuelCost, fuelCost, price]);

  useEffect(() => {
    if (
      isBasedOnGlobalReferenceVehicle &&
      offerStatus === OFFER_STATUSES.buildAbilityConfirmed &&
      isMitarbeiterkonfigurationenDetailsPage &&
      switcherDurationValue &&
      switcherDistanceValue
    ) {
      const newFilteredGRVLeasingOffers = leasingOffers.filter(
        leasingOffer =>
          leasingOffer.uds_laufzeit === switcherDurationValue.value &&
          leasingOffer.uds_laufleistungpa === switcherDistanceValue.value
      );
      if (switcherError) {
        setSwitcherError(false);
      }
      if (!newFilteredGRVLeasingOffers.length) {
        const currentSwitcherDurationValue = GrvLeasingOffersSwitcherData[
          GrvLeasingOffersSwitcherTypes.Duration
        ].options.find(option => option.value === filteredGRVLeasingOffers[0]?.uds_laufzeit) as SwitcherOption;
        const isDurationWasChanged = currentSwitcherDurationValue?.value !== switcherDurationValue?.value;
        const firstValidLeasingOffer = leasingOffers.find(leasingOffer =>
          isDurationWasChanged
            ? leasingOffer.uds_laufzeit === switcherDurationValue.value
            : leasingOffer.uds_laufleistungpa === switcherDistanceValue.value
        );

        if (isDurationWasChanged) {
          setSwitcherDistanceValue(
            GrvLeasingOffersSwitcherData[GrvLeasingOffersSwitcherTypes.Distance].options.find(
              option => option.value === firstValidLeasingOffer?.uds_laufleistungpa
            ) as SwitcherOption
          );
        } else {
          setSwitcherDurationValue(
            GrvLeasingOffersSwitcherData[GrvLeasingOffersSwitcherTypes.Duration].options.find(
              option => option.value === firstValidLeasingOffer?.uds_laufzeit
            ) as SwitcherOption
          );
        }
        return;
      }
      setFilteredGRVLeasingOffers(newFilteredGRVLeasingOffers);
      const lowestLeasingFactorLeasingOffer = getLowestLeasingFactorLeasingOffer(newFilteredGRVLeasingOffers);
      if (lowestLeasingFactorLeasingOffer && !newFilteredGRVLeasingOffers.some(leasingOffer => leasingOffer.active)) {
        changeActiveLeasingOffer(lowestLeasingFactorLeasingOffer.uds_leasingangeboteid);
      }
    }
  }, [switcherDurationValue, switcherDistanceValue, isBasedOnGlobalReferenceVehicle, leasingOffers]);

  useEffect(() => {
    if (
      isBasedOnGlobalReferenceVehicle &&
      offerStatus === OFFER_STATUSES.buildAbilityConfirmed &&
      isMitarbeiterkonfigurationenDetailsPage &&
      leasingOffer
    ) {
      if (!switcherDurationValue) {
        setSwitcherDurationValue(
          GrvLeasingOffersSwitcherData[GrvLeasingOffersSwitcherTypes.Duration].options.find(
            option => option.value === leasingOffer.uds_laufzeit
          ) as SwitcherOption
        );
      }
      if (!switcherDistanceValue) {
        setSwitcherDistanceValue(
          GrvLeasingOffersSwitcherData[GrvLeasingOffersSwitcherTypes.Distance].options.find(
            option => option.value === leasingOffer.uds_laufleistungpa
          ) as SwitcherOption
        );
      }
    }
  }, [leasingOffer]);

  useEffect(() => {
    if (isBulkOrderVehicle) {
      return;
    }

    const descriptions = ['Hersteller-Konfiguration'];
    if (budgetCheckPositive) {
      descriptions.push('Angebot vom Händler');
    }

    if (item.uds_fahrzeugangeboteid) {
      const requests = [
        sharepointService.getFilesByDescriptions({ entityId: item.uds_fahrzeugangeboteid, descriptions })
      ];
      if (leasingOffer?.uds_leasingangeboteid) {
        requests.push(
          sharepointService.getFilesByDescriptions({
            entityId: leasingOffer.uds_leasingangeboteid,
            descriptions: [
              'Leasingangebot an Kunde',
              orderApprovedEvent ? 'Signierte Verbindliche Bestellung' : 'Verbindliche Bestellung'
            ]
          })
        );
      }

      setLoading(true);
      Promise.allSettled(requests)
        .then(response => {
          let files: FileModel[] = [];
          response.forEach((item: AnyObject) => {
            if (item.value) {
              files = [...files, ...(item.value.data.data || [])];
            }
          });
          files = files.sort((a, b) => (new Date(b.created) as any) - (new Date(a.created) as any));
          setFiles(files);
        })
        .catch(err => console.log(err))
        .finally(() => setLoading(false));
    }
  }, [item, leasingOffer?.uds_leasingangeboteid]);

  useEffect(() => {
    if (isGlobalReferenceVehicle && [OFFER_STATUSES.configurationCreated].includes(offerStatus)) {
      const cheapestLeasingOffer = getCheapestLeasingOffer(
        leasingOffers.filter(leasingOffer => leasingOffer.uds_laufleistungpa === distance.value)
      );
      if (cheapestLeasingOffer) {
        changeActiveLeasingOffer(cheapestLeasingOffer.uds_leasingangeboteid);
      }
    }
  }, [distance.value]);

  const validationFunc = (distance: number, deliveryDate: string | null) => {
    if (distance < MIN_DISTANCE || distance > MAX_DISTANCE || !deliveryDate) {
      setFormErrors(true);
      return false;
    }
    return true;
  };

  const getDealerDeliveryDate = (dealerDeliveryDateString: string): ReactElement => {
    if (!dealerDeliveryDateString) {
      return (
        <Text size='12' color='grey600'>
          Nicht angegeben
        </Text>
      );
    }

    return (
      <Text size='12' color='grey900'>
        {new Date(dealerDeliveryDateString).toLocaleDateString('de-DE', {
          month: 'long',
          year: 'numeric'
        })}
      </Text>
    );
  };

  const confirmRequest = async () => {
    showOverlay();
    dispatch(toggleModal(MODALS.alert, null));
    try {
      const res = await sendNotification(item.uds_fahrzeugangeboteid, user.id);
      if (res.data?.level === RequestStatuses.Success) {
        hideOverlay();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const download = (fileId: string) => {
    const downloadedFile = files.find(file => file.id === fileId);
    if (downloadedFile) {
      showOverlay();
      sharepointService
        .downloadFileById(fileId)
        .then(({ data }: { data: Blob }) => {
          const a = document.createElement('a');
          a.href = URL.createObjectURL(new Blob([data]));
          a.download = downloadedFile.name;
          a.click();
        })
        .catch(err => console.log(err))
        .finally(hideOverlay);
    }
  };

  const alertData = {
    title: 'Fahrzeug anfragen',
    children: <RequestCar buttonText={'Anfragen'} />,
    buttons: [
      {
        type: 'cancel',
        title: 'Abbrechen',
        action: () => dispatch(toggleModal(MODALS.alert, null))
      },
      {
        type: 'submit',
        title: 'Anfragen',
        action: confirmRequest
      }
    ]
  };

  // if (!leasingOffer.active) {
  //   return <StyledOrderWidget>
  //     {user.id && item.uds_internalid
  //       ? <Configurator
  //         internalId={item.uds_internalid}
  //         enginetypecode={item.uds_enginetypecode}
  //         leasingfaktor={leasingfaktor} />
  //       : null}
  //   </StyledOrderWidget>;
  // }

  const createNewEvent = (
    eventDescription: VehicleOfferEvent,
    vehicleOfferId: string,
    additionalFields: { name: string; value: any; attributeTypeCode: number }[] = []
  ) => {
    const createEventData: AnyObject = {};
    const createEventDataFields = [
      { name: 'uds_fahrzeugangebotid', value: vehicleOfferId, attributeTypeCode: crmDataTypes.Lookup },
      { name: 'uds_eventdescription', value: eventDescription.id, attributeTypeCode: crmDataTypes.Lookup },
      { name: 'uds_erstelltvonportaluser', value: user.id, attributeTypeCode: crmDataTypes.Lookup },
      ...additionalFields
    ];
    createEventDataFields.forEach(field => {
      createEventData[field.name] = getRequestItem(field.value, field.attributeTypeCode);
    });

    return createEvent(createEventData);
  };

  const submitBulkOrder = async () => {
    let response: AnyObject[] | null = null;

    switch (offerStatus) {
      case OFFER_STATUSES.offerPublishedForUsers:
        try {
          showOverlay();
          response = await Promise.allSettled([
            copyFaToPersonalConfig(item.uds_fahrzeugangeboteid, driverId || user.id)
          ]);
        } catch (e) {
          hideOverlay();
          console.log(e);
        }

        if (response?.every(item => item.value.data.level === RequestStatuses.Success)) {
          history.push(
            `${
              isKonfigurationenDetailsPage ? PAGES.CONFIGURATION_DETAILS : PAGES.EMPLOYEE_CONFIGURATION_DETAILS
            }?id=${encodeBase64(response[0].value.data.data.newFahrzeugangeboteId)}`
          );
          setSnackbarText({ type: 'success', text: 'Änderungen erfolgreich gespeichert.' });
        }
        break;

      case OFFER_STATUSES.vehicleBasedOnBulkOrderCreated:
        getFormValues(
          forms,
          (form: AnyObject) => dispatch(setForm(form)),
          async (values: AnyObject) => {
            const approvers = Object.entries(values).map(([key, value]) => value);
            if (hasDuplicates(approvers)) {
              setSnackbarText({ type: 'warning', text: 'Bitte wähle unterschiedliche Personen aus.' });
              return;
            }

            const requestArray = [createApproveRecords(item.uds_fahrzeugangeboteid, driverId || user.id, approvers)];

            try {
              showOverlay();
              response = await Promise.allSettled(requestArray);
            } catch (e) {
              hideOverlay();
              console.log(e);
            }

            if (response?.every(item => item.value.data.level === RequestStatuses.Success)) {
              await refreshData();
              setSnackbarText({ type: 'success', text: 'Änderungen erfolgreich gespeichert.' });
            }
          }
        );
        break;

      default:
        break;
    }
  };

  const submitGRV = async (isAutomaticType: boolean = false) => {
    let response: AnyObject[] | null = null;
    const updateVehicleOfferData: AnyObject = {};
    let updateVehicleOfferDataFields: { name: string; value: string | number; attributeTypeCode: number }[] = [
      {
        name: 'uds_fahrzeugangeboteid',
        value: item.uds_fahrzeugangeboteid,
        attributeTypeCode: crmDataTypes.Uniqueidentifier
      }
    ];

    switch (offerStatus) {
      case OFFER_STATUSES.vehicleConfigurationBasedOnGRVCreated:
        try {
          showOverlay();
          response = await Promise.allSettled([
            createNewEvent(VEHICLE_OFFER_EVENT_DESCRIPTIONS.vehicleRequested, item.uds_fahrzeugangeboteid)
          ]);
        } catch (e) {
          hideOverlay();
          console.log(e);
        }

        if (response?.every(item => item.value.data.level === RequestStatuses.Success)) {
          await refreshData();
          setSnackbarText({ type: 'success', text: 'Änderungen erfolgreich gespeichert.' });
        }
        break;

      case OFFER_STATUSES.vehicleRequested:
        handleSubmit(async data => {
          updateVehicleOfferDataFields = [
            ...updateVehicleOfferDataFields,
            {
              name: 'uds_anbietenterhandlerid',
              value: data.uds_anbietenterhandlerid,
              attributeTypeCode: crmDataTypes.Lookup
            },
            {
              name: 'uds_haendlerkontakt',
              value: data.uds_haendlerkontakt,
              attributeTypeCode: crmDataTypes.Lookup
            },
            {
              name: 'uds_angebotsstatuscode',
              value: VEHICLE_OFFER_STATUSES.priceRequested,
              attributeTypeCode: crmDataTypes.Picklist
            }
          ];
          updateVehicleOfferDataFields.forEach(field => {
            updateVehicleOfferData[field.name] = getRequestItem(field.value, field.attributeTypeCode);
          });

          try {
            showOverlay();
            response = await Promise.allSettled([
              updateVehicleOffer(updateVehicleOfferData),
              createNewEvent(VEHICLE_OFFER_EVENT_DESCRIPTIONS.dealershipSelected, item.uds_fahrzeugangeboteid)
            ]);
          } catch (e) {
            hideOverlay();
            console.log(e);
          }

          if (response?.every(item => item.value.data.level === RequestStatuses.Success)) {
            await refreshData();
            setSnackbarText({ type: 'success', text: 'Änderungen erfolgreich gespeichert.' });
          }
        })();
        break;

      case OFFER_STATUSES.dealershipSelected:
        try {
          showOverlay();
          response = await Promise.allSettled([
            createNewEvent(VEHICLE_OFFER_EVENT_DESCRIPTIONS.orderVehicleFromDealership, item.uds_fahrzeugangeboteid)
          ]);
        } catch (e) {
          hideOverlay();
          console.log(e);
        }

        if (response?.every(item => item.value.data.level === RequestStatuses.Success)) {
          await refreshData();
          setSnackbarText({ type: 'success', text: 'Änderungen erfolgreich gespeichert.' });
        }
        break;

      case OFFER_STATUSES.buildAbilityConfirmed:
        if (!deliveryDate.value && isMitarbeiterkonfigurationenDetailsPage && !item.uds_wunschlieferdatum) {
          setFormErrors(true);
          return;
        }
        if (showApproversInfo || isAutomaticType) {
          getFormValues(
            formsLatest.current,
            (form: AnyObject) => dispatch(setForm(form)),
            async (values: AnyObject) => {
              let copyLeasingOfferResponse: AxiosResponse | null = null;

              const approvers = Object.entries(values).map(([key, value]) => value);
              if (hasDuplicates(approvers)) {
                setSnackbarText({ type: 'warning', text: 'Bitte wähle unterschiedliche Personen aus.' });
                return;
              }
              if (!leasingOffer) {
                return;
              }

              try {
                showOverlay();
                copyLeasingOfferResponse = await copyToPersonalConfig(
                  leasingOffer.uds_leasingangeboteid,
                  item.uds_fahrzeugangeboteid,
                  budgetStatus === 'warning' ? supplement : undefined
                );
              } catch (e) {
                hideOverlay();
                console.log(e);
              }

              if (copyLeasingOfferResponse?.data.level !== RequestStatuses.Success) {
                setSnackbarText({ type: 'error', text: 'Änderungen wurden nicht übernommen.' });
                return;
              }

              let vehicleOfferId = item.uds_fahrzeugangeboteid;

              const requestArray = [fleetManagerReleaseOrder(approvers, vehicleOfferId, true)];

              try {
                showOverlay();
                response = await Promise.allSettled(requestArray);
              } catch (e) {
                hideOverlay();
                console.log(e);
              }

              if (response?.every(item => item.value.data.level === RequestStatuses.Success)) {
                setShowApproversInfo(false);
                await refreshData();
                refetchApprovalRecords();
                dispatch(
                  setSnackbarComponent({
                    type: SnackbarComponentTypes.Success,
                    open: true,
                    component: <SuccessSnackbar />
                  })
                );
              }
            }
          );
        } else {
          !switcherDurationValue || !switcherDistanceValue ? setSwitcherError(true) : setShowApproversInfo(true);
        }
        break;

      case OFFER_STATUSES.approvedByFleetManager:
        console.log('approvedByFleetManager');
        break;

      case OFFER_STATUSES.orderApproved:
        try {
          showOverlay();
          response = await Promise.allSettled([sendRequestToCarDealer(item.uds_fahrzeugangeboteid)]);
        } catch (e) {
          hideOverlay();
          console.log(e);
        }

        if (response?.every(item => item.value.data.level === RequestStatuses.Success)) {
          await refreshData();
          setSnackbarText({ type: 'success', text: 'Änderungen erfolgreich gespeichert.' });
        }
        break;

      default:
        break;
    }
  };

  const submit = async () => {
    let response: AnyObject[] | null = null;
    const updateVehicleOfferData: AnyObject = {};
    let updateVehicleOfferDataFields = [
      {
        name: 'uds_fahrzeugangeboteid',
        value: item.uds_fahrzeugangeboteid,
        attributeTypeCode: crmDataTypes.Uniqueidentifier
      },
      { name: 'uds_laufleistungkm', value: distance.value, attributeTypeCode: crmDataTypes.Integer },
      { name: 'uds_wunschlaufzeitcode', value: months.value, attributeTypeCode: crmDataTypes.Picklist },
      { name: 'uds_wunschlieferdatum', value: deliveryDate.value, attributeTypeCode: crmDataTypes.DateTime }
    ];

    const alertData = {
      children: <LoadingMessage />,
      className: classes.loading_message,
      buttons: []
    };

    switch (offerStatus) {
      case OFFER_STATUSES.purchaseTriggered:
        if (!deliveryDate.value && isMitarbeiterkonfigurationenDetailsPage && !item.uds_wunschlieferdatum) {
          setFormErrors(true);
          return;
        }
        if (showApproversInfo) {
          getFormValues(
            forms,
            (form: AnyObject) => dispatch(setForm(form)),
            async (values: AnyObject) => {
              const requestData: AnyObject = {};

              let vehicleOfferId = item.uds_fahrzeugangeboteid;
              requestData.uds_leasingangeboteid = getRequestItem(
                leasingOffer?.uds_leasingangeboteid,
                crmDataTypes.Uniqueidentifier
              );
              requestData.uds_bezugid = getRequestItem(leasingOffer?.uds_bezugid_id, crmDataTypes.Lookup);
              requestData.uds_isausgewahlt = getRequestItem(true, crmDataTypes.Boolean);

              if (isReferenzfahrzeugeDetailsPage) {
                let copyVehicleOfferResponse: AnyObject = {};
                try {
                  dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, alertData));
                  copyVehicleOfferResponse = await duplicateFahrzeugangebote(
                    item.uds_fahrzeugangeboteid,
                    driverId || user.id,
                    responsiblePerson,
                    oldCar,
                    vehicleOfferAssignment,
                    basketTypeCode
                  );
                  const newVehicleOfferId = copyVehicleOfferResponse.data.data?.fahrzeugangeboteId;

                  if (!newVehicleOfferId) {
                    setSnackbarText({ type: 'error', text: 'Änderungen wurden nicht übernommen.' });
                    hideOverlay();
                    return;
                  }
                  vehicleOfferId = newVehicleOfferId;
                  const leasingOffersResponse = await getLeasingangebotes(newVehicleOfferId);
                  const leasingOffers = leasingOffersResponse.data?.data
                    ? getLeasingOffersData(
                        leasingOffersResponse.data?.data,
                        null,
                        null,
                        isGlobalReferenceVehicle,
                        isBasedOnGlobalReferenceVehicle,
                        approvedByFleetManagerEvent
                      )
                    : [];
                  const chosenLeasingOffer = leasingOffers.find(
                    (offer: LeasingOffer) =>
                      offer.new_mobexoangebotgesamtrate_value === leasingOffer?.new_mobexoangebotgesamtrate_value &&
                      offer.new_leasingbank_id === leasingOffer?.new_leasingbank_id
                  );

                  if (chosenLeasingOffer) {
                    requestData.uds_leasingangeboteid = getRequestItem(
                      chosenLeasingOffer.uds_leasingangeboteid,
                      crmDataTypes.Uniqueidentifier
                    );
                    requestData.uds_bezugid = getRequestItem(chosenLeasingOffer.uds_bezugid_id, crmDataTypes.Lookup);
                    requestData.uds_isausgewahlt = getRequestItem(true, crmDataTypes.Boolean);
                  }
                } catch (e) {
                  console.log(e);
                  setSnackbarText({ type: 'error', text: 'Änderungen wurden nicht übernommen.' });
                  hideOverlay();
                  return;
                } finally {
                  dispatch(toggleModal(MODALS.alert, null));
                }
              }

              const fieldNames = [
                'uds_fahrzeugbestellungerstfreigabeid',
                'uds_fahrzeugbestellungzweitfreigabeid',
                'uds_fahrzeugbestellungdrittfreigabe'
              ];
              const approvers = Object.entries(values).map(([key, value]) => value);
              if (hasDuplicates(approvers)) {
                setSnackbarText({ type: 'warning', text: 'Bitte wähle unterschiedliche Personen aus.' });
                return;
              }

              const additionalFields = approvers.map((approver, i) => ({
                name: fieldNames[i],
                value: approver,
                attributeTypeCode: crmDataTypes.Lookup
              }));
              const requestArray = [
                updateLeasingOffer(requestData),
                createNewEvent(
                  VEHICLE_OFFER_EVENT_DESCRIPTIONS.approvedByFleetManager,
                  vehicleOfferId,
                  additionalFields
                )
              ];

              if (
                isReferenzfahrzeugeDetailsPage ||
                (isMitarbeiterkonfigurationenDetailsPage && !item.uds_wunschlieferdatum)
              ) {
                const vehicleOfferData: AnyObject = {};
                vehicleOfferData['uds_fahrzeugangeboteid'] = getRequestItem(
                  vehicleOfferId,
                  crmDataTypes.Uniqueidentifier
                );
                vehicleOfferData['uds_wunschlieferdatum'] = getRequestItem(deliveryDate.value, crmDataTypes.DateTime);
                requestArray.push(updateVehicleOffer(vehicleOfferData));
                if (isReferenzfahrzeugeDetailsPage) {
                  requestArray.push(createNewEvent(VEHICLE_OFFER_EVENT_DESCRIPTIONS.vehicleRequested, vehicleOfferId));
                }
              }

              try {
                showOverlay();
                response = await Promise.allSettled(requestArray);
              } catch (e) {
                hideOverlay();
                console.log(e);
              }

              if (response?.every(item => item.value.data.level === RequestStatuses.Success)) {
                setShowApproversInfo(false);
                if (isReferenzfahrzeugeDetailsPage) {
                  setTimeout(
                    () => history.push(`${PAGES.EMPLOYEE_CONFIGURATION_DETAILS}?id=${encodeBase64(vehicleOfferId)}`),
                    3000
                  );
                } else {
                  await refreshData();
                }
                dispatch(
                  setSnackbarComponent({
                    type: SnackbarComponentTypes.Success,
                    open: true,
                    component: <SuccessSnackbar />
                  })
                );
              }
            }
          );
        } else {
          setShowApproversInfo(true);
        }
        break;

      case OFFER_STATUSES.configurationCreated:
        if (distance.value < MIN_DISTANCE || distance.value > MAX_DISTANCE || !deliveryDate.value) {
          setFormErrors(true);
          return;
        }

        updateVehicleOfferDataFields.forEach(field => {
          updateVehicleOfferData[field.name] = getRequestItem(field.value, field.attributeTypeCode);
        });

        try {
          showOverlay();
          response = await Promise.allSettled([
            updateVehicleOffer(updateVehicleOfferData),
            createNewEvent(VEHICLE_OFFER_EVENT_DESCRIPTIONS.offerRequested, item.uds_fahrzeugangeboteid)
          ]);
        } catch (e) {
          hideOverlay();
          console.log(e);
        }

        if (response?.every(item => item.value.data.level === RequestStatuses.Success)) {
          await refreshData();
          setSnackbarText({ type: 'success', text: 'Änderungen erfolgreich gespeichert.' });
        }
        break;

      case OFFER_STATUSES.offerRequested:
        handleSubmit(async data => {
          updateVehicleOfferDataFields = [
            ...updateVehicleOfferDataFields.filter(field => field.name === 'uds_fahrzeugangeboteid'),
            {
              name: 'uds_anbietenterhandlerid',
              value: data.uds_anbietenterhandlerid,
              attributeTypeCode: crmDataTypes.Lookup
            },
            {
              name: 'uds_haendlerkontakt',
              value: data.uds_haendlerkontakt,
              attributeTypeCode: crmDataTypes.Lookup
            },
            {
              name: 'uds_angebotsstatuscode',
              value: VEHICLE_OFFER_STATUSES.priceRequested,
              attributeTypeCode: crmDataTypes.Picklist
            }
          ];
          updateVehicleOfferDataFields.forEach(field => {
            updateVehicleOfferData[field.name] = getRequestItem(field.value, field.attributeTypeCode);
          });

          const eventAddidtionalFields = [
            { name: 'uds_handlerid', value: data.uds_anbietenterhandlerid, attributeTypeCode: crmDataTypes.Lookup }
          ];
          try {
            showOverlay();
            response = await Promise.allSettled([
              updateVehicleOffer(updateVehicleOfferData),
              createNewEvent(
                VEHICLE_OFFER_EVENT_DESCRIPTIONS.dealershipSelected,
                item.uds_fahrzeugangeboteid,
                eventAddidtionalFields
              ),
              createNewEvent(
                VEHICLE_OFFER_EVENT_DESCRIPTIONS.priceRequested,
                item.uds_fahrzeugangeboteid,
                eventAddidtionalFields
              )
            ]);
          } catch (e) {
            hideOverlay();
            console.log(e);
          }

          if (response?.every(item => item.value.data.level === RequestStatuses.Success)) {
            await refreshData();
            setSnackbarText({ type: 'success', text: 'Änderungen erfolgreich gespeichert.' });
          }
        })();
        break;

      case OFFER_STATUSES.requirementApproved:
        if (!deliveryDate.value && !item.uds_wunschlieferdatum) {
          setFormErrors(true);
          return;
        }
        if (isDriverReferenzfahrzeugeDetailsPage) {
          let copyVehicleOfferResponse: AnyObject = {};
          const vehicleOfferData: AnyObject = {};

          try {
            dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, alertData));
            copyVehicleOfferResponse = await duplicateFahrzeugangebote(
              item.uds_fahrzeugangeboteid,
              user.id,
              responsiblePerson,
              oldCar,
              vehicleOfferAssignment,
              BASKET_TYPES.personal.value
            );

            if (!copyVehicleOfferResponse.data.data?.fahrzeugangeboteId) {
              setSnackbarText({ type: 'error', text: 'Änderungen wurden nicht übernommen.' });
              hideOverlay();
              return;
            }
            vehicleOfferData['uds_fahrzeugangeboteid'] = getRequestItem(
              copyVehicleOfferResponse.data.data.fahrzeugangeboteId,
              crmDataTypes.Uniqueidentifier
            );
            vehicleOfferData['uds_wunschlieferdatum'] = getRequestItem(deliveryDate.value, crmDataTypes.DateTime);
            response = await Promise.allSettled([
              updateVehicleOffer(vehicleOfferData),
              createNewEvent(
                VEHICLE_OFFER_EVENT_DESCRIPTIONS.vehicleRequested,
                copyVehicleOfferResponse.data.data.fahrzeugangeboteId
              )
            ]);
          } catch (e) {
            console.log(e);
            setSnackbarText({ type: 'error', text: 'Änderungen wurden nicht übernommen.' });
            hideOverlay();
            return;
          } finally {
            dispatch(toggleModal(MODALS.alert, null));
          }

          if (response?.every(item => item.value.data.level === RequestStatuses.Success)) {
            history.push(
              `${PAGES.CONFIGURATION_DETAILS}?id=${encodeBase64(copyVehicleOfferResponse.data.data.fahrzeugangeboteId)}`
            );
            setSnackbarText({ type: 'success', text: 'Änderungen erfolgreich gespeichert.' });
          }
        } else {
          try {
            showOverlay();
            await createNewEvent(VEHICLE_OFFER_EVENT_DESCRIPTIONS.vehicleRequested, item.uds_fahrzeugangeboteid);
            response = await Promise.allSettled([
              createNewEvent(VEHICLE_OFFER_EVENT_DESCRIPTIONS.purchaseTriggered, item.uds_fahrzeugangeboteid)
            ]);
          } catch (e) {
            hideOverlay();
            console.log(e);
          }

          if (response?.every(item => item.value.data.level === RequestStatuses.Success)) {
            await refreshData();
            setSnackbarText({ type: 'success', text: 'Änderungen erfolgreich gespeichert.' });
          }
        }
        break;
    }

    const isNeedRefetchOnPage = isMitarbeiterkonfigurationenDetailsPage || isKonfigurationenDetailsPage;

    if (!isEmpty(vehicleListQueryKey.tableParams) && isNeedRefetchOnPage) {
      refetchVehicleList();
    }
  };

  const decline = () => {
    getFormValues(
      forms,
      (form: AnyObject) => dispatch(setForm(form)),
      async (values: AnyObject) => {
        let response: AxiosResponse<ResponseModel> | null = null;
        try {
          showOverlay();
          response = await createNewEvent(
            VEHICLE_OFFER_EVENT_DESCRIPTIONS.approvalRejected,
            item.uds_fahrzeugangeboteid,
            [{ name: 'uds_notiz', value: values.resonToDecline, attributeTypeCode: crmDataTypes.String }]
          );
        } catch (e) {
          hideOverlay();
          console.log(e);
        }

        if (response?.data.level === RequestStatuses.Success) {
          setSnackbarText({ type: 'success', text: 'Änderungen erfolgreich gespeichert.' });
        } else {
          setSnackbarText({ type: 'error', text: 'Änderungen wurden nicht übernommen.' });
        }

        setShowDeclineDialog(false);
        await refreshData();
      }
    );
  };

  const toggleDealerInfoSection = () => {
    const newDealerInfoSection = cloneDeep(dealerInfoSection);
    newDealerInfoSection.expand = !newDealerInfoSection.expand;

    setDealerInfoSection(newDealerInfoSection);
  };

  if (isBulkOrderVehicle || isBasedOnBulkOrderVehicle) {
    return (
      <>
        <StyledOrderWidget>
          {isBulkOrderVehicle && (
            <>
              <Flex align='center' bottom='30'>
                <ThreeStateCheckbox
                  checked={acceptConditions}
                  onChange={toggleAcceptConditions}
                  indeterminate={false}
                />
                <Text pointer onClick={toggleAcceptConditions} left='10' color='grey900'>
                  Hiermit akzeptiere ich die untenstehenden Bedingungen.
                </Text>
              </Flex>
              <Flex justify='flex-end'>
                <Button disabled={!acceptConditions} onClick={submitBulkOrder}>
                  Fahrzeug anfordern
                </Button>
              </Flex>
            </>
          )}

          {[OFFER_STATUSES.approvalFlowStarted].includes(offerStatus) && (
            <Text bottom='20' color='grey900'>
              Diese Fahrzeuganfrage befindet sich derzeit im Freigabeprozess. Sobald alle Freigaben erteilt wurden, wird
              dies hier angezeigt und wir informieren dich. In der Zwischenzeit kannst du keine weiteren
              Fahrzeuganfragen erstellen.
            </Text>
          )}

          {isBasedOnBulkOrderVehicle && (
            <Flex direction='column' align='flex-end'>
              <DateInfo
                icon={<UsersIcon />}
                title={'Fahrer:'}
                value={vehicleBasedOnBulkOrderCreatedEvent?.uds_erstelltfurid.attributeValue?.name || '-'}
              />

              {![OFFER_STATUSES.approvalRejected].includes(offerStatus) && (
                <DateInfo
                  icon={<OrderIcon width='12' height='14' />}
                  title='Angefordert am:'
                  value={getDateString(vehicleBasedOnBulkOrderCreatedEvent?.createdon.attributeValue)}
                />
              )}

              {[OFFER_STATUSES.approvalRejected].includes(offerStatus) && (
                <DateInfo
                  icon={<CalendarIcon width='12' height='14' />}
                  title='Abgelehnt am:'
                  value={getDateString(approvalRejectedEvent?.createdon.attributeValue)}
                />
              )}
            </Flex>
          )}

          {offerStatus === OFFER_STATUSES.approvalRejected && approvalRejectedEvent?.uds_notiz?.attributeValue && (
            <div>
              <Text margin='20px 0' color='grey600'>
                Ablehnungsgrund:
              </Text>
              <StyledOneTimeCostsContainer>
                <Text color='grey600'>{approvalRejectedEvent.uds_notiz.attributeValue || ''}</Text>
              </StyledOneTimeCostsContainer>
            </div>
          )}

          {isMitarbeiterkonfigurationenDetailsPage &&
            [OFFER_STATUSES.vehicleBasedOnBulkOrderCreated].includes(offerStatus) &&
            isBasedOnBulkOrderVehicle &&
            !showDeclineDialog && (
              <ApproversInfo
                submit={submitBulkOrder}
                fileId={files.find(file => file.description === 'Verbindliche Bestellung')?.id || null}
                loading={loading}
                offerStatus={offerStatus}
                setShowApproversInfo={setShowApproversInfo}
                isBasedOnBulkOrderVehicle={isBasedOnBulkOrderVehicle}
                driverId={vehicleBasedOnBulkOrderCreatedEvent?.uds_erstelltfurid.attributeValue?.id}
              />
            )}

          {[OFFER_STATUSES.vehicleBasedOnBulkOrderCreated].includes(offerStatus) &&
            isBasedOnBulkOrderVehicle &&
            !showDeclineDialog && (
              <StyledButtonsContainer justify='flex-end' align='flex-start' top='20'>
                <StyledButton onClick={() => setShowDeclineDialog(true)} secondary>
                  {isKonfigurationenDetailsPage ? 'Archivieren' : 'Ablehnen'}
                </StyledButton>

                {isMitarbeiterkonfigurationenDetailsPage && (
                  <StyledSubmitButtonContainer align='center' direction='column'>
                    <StyledButton onClick={submitBulkOrder} disabled={!isValid}>
                      Freigabe anfordern
                    </StyledButton>
                  </StyledSubmitButtonContainer>
                )}
              </StyledButtonsContainer>
            )}

          {showDeclineDialog && <DeclineDialog decline={decline} setShowDeclineDialog={setShowDeclineDialog} />}
        </StyledOrderWidget>

        {!!approvalRecords?.length && (
          <StyledOrderWidget>
            <ApproveStatus approvalRecords={approvalRecords} />
          </StyledOrderWidget>
        )}

        {isBulkOrderVehicle && (
          <VehicleContainer
            img={<ClipIcon color='#335566' />}
            title='Dokumente'
            description={undefined}
            className={undefined}
          >
            <DocumentClassicComponent
              loading={bulkOrderFilesLoading}
              files={(bulkOrderFiles?.data || []) as unknown as FileModel[]}
            />
          </VehicleContainer>
        )}

        <Snackbar
          open={!!snackbarText.text}
          type={snackbarText.type}
          onClose={() => setSnackbarText({ type: snackbarText.type, text: '' })}
          message={snackbarText.text}
        />
      </>
    );
  }

  if (isGlobalReferenceVehicle || isBasedOnGlobalReferenceVehicle) {
    return (
      <>
        <StyledOrderWidget>
          {offerStatus === OFFER_STATUSES.globalReferenceVehicleGeneratedViaAPI && (
            <>
              <Text color='blue' bold bottom='10' top='20'>
                Jährliche Fahrleistung (in km/Jahr)
              </Text>
              <DistanceComponent
                distance={distance}
                setDistance={setDistance}
                minDistance={MIN_DISTANCE}
                maxDistance={MAX_DISTANCE}
                formErrors={formErrors}
              />

              <Text color='blue' bold bottom='10' top='20'>
                Wunschlieferdatum
              </Text>
              <DeliveryDateComponent
                deliveryDate={deliveryDate}
                setDeliveryDate={setDeliveryDate}
                minDate={firstDayCurrentMonth}
                maxDate={twoYearsFromNow}
                formErrors={formErrors}
              />
            </>
          )}

          {![OFFER_STATUSES.globalReferenceVehicleGeneratedViaAPI].includes(offerStatus) && (
            <>
              {referenceDistance &&
                isBasedOnGlobalReferenceVehicle &&
                referenceDuration &&
                isMitarbeiterkonfigurationenDetailsPage &&
                ![OFFER_STATUSES.orderCompleted, OFFER_STATUSES.orderVehicleFromDealership].includes(offerStatus) && (
                  <StyledInfoCardsContainer bottom='10' justify='flex-start'>
                    <InfoCard
                      value={String(referenceDuration)}
                      units='Monate (Ref. Laufzeit)'
                      icon={<OrderWidgetPeriodIcon color={theme.colors.grey200} />}
                      reference={true}
                    />
                    <StyledDistance />
                    <InfoCard
                      value={referenceDistance.toLocaleString('de-DE')}
                      units='KM (Ref. Fahrleistung)'
                      icon={<OrderWidgetDistanceIcon color={theme.colors.grey200} />}
                      reference={true}
                    />
                  </StyledInfoCardsContainer>
                )}

              {offerStatus === OFFER_STATUSES.buildAbilityConfirmed && isMitarbeiterkonfigurationenDetailsPage ? (
                <>
                  <StyledInfoCardsContainer bottom='30' justify='flex-start'>
                    <GrvLeasingOffersSwitcher
                      data={GrvLeasingOffersSwitcherData[GrvLeasingOffersSwitcherTypes.Duration]}
                      type={GrvLeasingOffersSwitcherTypes.Duration}
                      value={switcherDurationValue}
                      setValue={setSwitcherDurationValue}
                      hasError={switcherError && !switcherDurationValue}
                    />
                    <StyledDistance />
                    <GrvLeasingOffersSwitcher
                      data={GrvLeasingOffersSwitcherData[GrvLeasingOffersSwitcherTypes.Distance]}
                      type={GrvLeasingOffersSwitcherTypes.Distance}
                      value={switcherDistanceValue}
                      setValue={setSwitcherDistanceValue}
                      hasError={switcherError && !switcherDistanceValue}
                    />
                  </StyledInfoCardsContainer>
                </>
              ) : (
                <StyledInfoCardsContainer>
                  {((offerStatus === OFFER_STATUSES.approvedByFleetManager && isApprovalDetails) ||
                    [OFFER_STATUSES.orderCompleted, OFFER_STATUSES.orderVehicleFromDealership].includes(
                      offerStatus
                    )) && (
                    <>
                      <InfoCard
                        value={
                          leasingOffer
                            ? leasingOffer.uds_laufzeit.toLocaleString('de-DE')
                            : item.uds_wunschlaufzeitcode_value
                        }
                        units='Monate'
                        icon={<OrderWidgetPeriodIcon />}
                      />
                      <StyledDistance />
                    </>
                  )}
                  {item.uds_laufleistungkm && leasingOffer && (
                    <InfoCard
                      value={
                        leasingOffer
                          ? leasingOffer.uds_laufleistungpa.toLocaleString('de-DE')
                          : item.uds_laufleistungkm.toLocaleString('de-DE')
                      }
                      units='Km'
                      icon={<OrderWidgetDistanceIcon />}
                    />
                  )}
                </StyledInfoCardsContainer>
              )}

              {item.uds_laufleistungkm &&
                [
                  OFFER_STATUSES.buildAbilityConfirmed,
                  OFFER_STATUSES.approvedByFleetManager,
                  OFFER_STATUSES.orderCompleted,
                  OFFER_STATUSES.orderVehicleFromDealership
                ].includes(offerStatus) &&
                (isApprovalDetails || isMitarbeiterkonfigurationenDetailsPage) && (
                  <>
                    <Distance top='20' />
                    <InfoBlock
                      title={
                        <Text bold color='blue'>
                          Wunschlaufleistung
                        </Text>
                      }
                      value={
                        <Text size='12' color='grey900'>
                          {item.uds_laufleistungkm.toLocaleString('de-DE')} km
                        </Text>
                      }
                    />
                  </>
                )}

              <Distance top='20' />

              {item.uds_wunschlieferdatum && (
                <InfoBlock
                  title={
                    <Text bold color='blue'>
                      Wunschlieferdatum
                    </Text>
                  }
                  value={
                    <Text id={'wunschlieferdatumValue'} size='12' color='grey900'>
                      {new Date(item.uds_wunschlieferdatum).toLocaleDateString('de-DE', {
                        month: 'long',
                        year: 'numeric'
                      })}
                    </Text>
                  }
                />
              )}
            </>
          )}

          {[
            OFFER_STATUSES.buildAbilityConfirmed,
            OFFER_STATUSES.approvedByFleetManager,
            OFFER_STATUSES.orderCompleted,
            OFFER_STATUSES.orderVehicleFromDealership
          ].includes(offerStatus) &&
            (isMitarbeiterkonfigurationenDetailsPage || isApprovalDetails) && (
              <>
                <Distance top='15' />
                <InfoBlock
                  title={
                    <Text size='12' color='grey900'>
                      Vsl. Lieferdatum lt. Händler
                    </Text>
                  }
                  value={getDealerDeliveryDate(item.uds_vsl_liefertermin_laut_handler_main)}
                />
                <Divider margin='20px 0' />

                {item.uds_barpreis_netto_value > 0 && (
                  <InfoBlock
                    title={<Text color='grey600'>Barpreis Netto</Text>}
                    value={<Text color='black'>{getMoneyValue(item.uds_barpreis_netto_value)}</Text>}
                  />
                )}
                <StyledOneTimeCostsContainer top='30' bottom='15' direction='column'>
                  <Flex justify='space-between'>
                    <Text bold color='grey600'>
                      Einmalige Kosten
                    </Text>
                    <Text bold color='grey600'>
                      {getMoneyValue(oneTimeCost)}
                    </Text>
                  </Flex>

                  <Divider margin='15px 0' />
                  {item.uds_einmaligekosten_uberfuhrungskosten_value >= 0 && (
                    <InfoBlock
                      title={
                        <Text bottom='5' color='grey600'>
                          Überführung
                        </Text>
                      }
                      value={
                        <Text bottom='5' color='grey600'>
                          {getMoneyValue(item.uds_einmaligekosten_uberfuhrungskosten_value || 0)}
                        </Text>
                      }
                    />
                  )}
                  {item.uds_einmaligekosten_zulassungskosten_value >= 0 && (
                    <InfoBlock
                      title={
                        <Text bottom='5' color='grey600'>
                          Zulassung
                        </Text>
                      }
                      value={
                        <Text bottom='5' color='grey600'>
                          {getMoneyValue(item.uds_einmaligekosten_zulassungskosten_value || 0)}
                        </Text>
                      }
                    />
                  )}
                  {item.uds_einmaligekosten_anzahlung_value >= 0 && (
                    <InfoBlock
                      title={<Text color='grey600'>Anzahlung</Text>}
                      value={
                        <Text color='grey600'>{getMoneyValue(item.uds_einmaligekosten_anzahlung_value || 0)}</Text>
                      }
                    />
                  )}
                </StyledOneTimeCostsContainer>
              </>
            )}

          {[OFFER_STATUSES.orderApproved].includes(offerStatus) && isMitarbeiterkonfigurationenDetailsPage && (
            <>
              <Divider margin='10px 0' />

              {item.uds_anbietenterhandlerid_id && (
                <InfoBlock
                  title={
                    <Text bold color='blue'>
                      Autohaus
                    </Text>
                  }
                  value={<DealerCompanyDetails dealerCompanyId={item.uds_anbietenterhandlerid_id} />}
                  align='flex-start'
                />
              )}

              <Divider margin='10px 0' />
            </>
          )}

          {[
            OFFER_STATUSES.buildAbilityConfirmed,
            OFFER_STATUSES.orderCompleted,
            OFFER_STATUSES.orderVehicleFromDealership
          ].includes(offerStatus) &&
            isMitarbeiterkonfigurationenDetailsPage &&
            item.uds_kommentar_des_haendlers && (
              <>
                <Text bottom='10' color='grey600' size='12'>
                  Autohaus Notiz
                </Text>
                <Text color='grey600' size='12'>
                  {item.uds_kommentar_des_haendlers}
                </Text>
              </>
            )}

          {!!(priceWithoutFuelCost && budgetData) && (
            <>
              <BudgetScale
                top='50'
                budget={budgetData.budget}
                fuelCost={fuelCost}
                leasingPrice={priceWithoutFuelCost}
                surcharge={budgetData.surcharge}
              />
              <InfoBlock
                title={
                  <Text size='12' color='grey800'>
                    {[OFFER_STATUSES.orderCompleted, OFFER_STATUSES.orderVehicleFromDealership].includes(offerStatus)
                      ? 'Leasing-Rate inkl. Dienstleistungen'
                      : 'Referenz-Rate inkl. Dienstleistungen'}
                  </Text>
                }
                value={
                  <Text opacity={0.87} bold size='14' color='blue'>
                    {getMoneyValue(priceWithoutFuelCost)}
                  </Text>
                }
              />
              {fuelCost ? (
                <>
                  <Distance top='10' />
                  <InfoBlock
                    title={
                      <Text size='12' color='grey800'>
                        Kalkulatorische Kraftstoffkosten
                      </Text>
                    }
                    value={
                      <Text opacity={0.87} bold size='14' color='blue'>
                        {getMoneyValue(fuelCost)}
                      </Text>
                    }
                  />
                </>
              ) : null}
              <Divider margin='20px 0' />

              {![OFFER_STATUSES.orderCompleted, OFFER_STATUSES.orderVehicleFromDealership].includes(offerStatus) && (
                <>
                  <StyledCostContainer bottom='10' align='center' justify='space-between'>
                    <Text color='blue' bottom='10' bold>
                      Referenzkosten
                    </Text>
                    <Tooltip title={BUDGET_STATUSES[budgetStatus].tooltip}>
                      <Flex align='center'>
                        {BUDGET_STATUSES[budgetStatus].icon}
                        <Text
                          left='10'
                          style={{ whiteSpace: 'pre' }}
                          color={BUDGET_STATUSES[budgetStatus].color}
                          size='18'
                          bold
                        >
                          {`${
                            isKonfigurationenDetailsPage && offerStatus !== OFFER_STATUSES.orderApproved ? 'ab ' : ''
                          }${price.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })} / Monat`}
                        </Text>
                      </Flex>
                    </Tooltip>
                  </StyledCostContainer>
                  <Flex justify='flex-end'>
                    <Text color='grey600' size='12'>
                      {`${isKonfigurationenDetailsPage && offerStatus !== OFFER_STATUSES.orderApproved ? 'ab ' : ''}${(
                        price * TAXES_COEFFICIENT
                      ).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })} (inkl. MwSt.)`}
                    </Text>
                  </Flex>
                  {budgetStatus === 'warning' && (
                    <Flex top='10' justify='flex-end'>
                      <Text color='gold' size='12'>
                        {`inkl. ${supplement?.toLocaleString('de-DE', {
                          style: 'currency',
                          currency: 'EUR'
                        })} Eigenzuzahlung`}
                      </Text>
                    </Flex>
                  )}
                </>
              )}

              {[
                OFFER_STATUSES.buildAbilityConfirmed,
                OFFER_STATUSES.orderCompleted,
                OFFER_STATUSES.orderVehicleFromDealership
              ].includes(offerStatus) &&
                isMitarbeiterkonfigurationenDetailsPage &&
                estimatedPriceWithoutFuelCost && (
                  <>
                    <Distance top='20' />
                    <StyledCostContainer bottom='10' align='center' justify='space-between'>
                      <Text color='blue' bottom='10' bold>
                        {[OFFER_STATUSES.orderCompleted, OFFER_STATUSES.orderVehicleFromDealership].includes(
                          offerStatus
                        )
                          ? 'Leasingkosten'
                          : 'Voraussichtliche Rate'}
                      </Text>
                      <Flex align='center'>
                        <Text left='10' style={{ whiteSpace: 'pre' }} color='blue' size='18' bold>
                          {`${(estimatedPriceWithoutFuelCost + fuelCost).toLocaleString('de-DE', {
                            style: 'currency',
                            currency: 'EUR'
                          })} / Monat`}
                        </Text>
                      </Flex>
                    </StyledCostContainer>
                    <Flex justify='flex-end'>
                      <Text color='grey600' size='12'>
                        {`${((estimatedPriceWithoutFuelCost + fuelCost) * TAXES_COEFFICIENT).toLocaleString('de-DE', {
                          style: 'currency',
                          currency: 'EUR'
                        })} (inkl. MwSt.)`}
                      </Text>
                    </Flex>
                  </>
                )}
            </>
          )}

          {[
            OFFER_STATUSES.buildAbilityConfirmed,
            OFFER_STATUSES.approvedByFleetManager,
            OFFER_STATUSES.orderCompleted,
            OFFER_STATUSES.orderVehicleFromDealership
          ].includes(offerStatus) &&
            (isMitarbeiterkonfigurationenDetailsPage || isApprovalDetails) && (
              <>
                {leasingOffer && (
                  <>
                    <StyledFilesContainer direction='column' top='20'>
                      {loading ? (
                        <Spinner
                          style={{
                            width: '50px',
                            position: 'absolute',
                            left: '50%',
                            top: '50%',
                            transform: 'translate(-50%, -50%)'
                          }}
                        />
                      ) : (
                        files.length > 0 &&
                        files
                          .filter(file => file.description === 'Verbindliche Bestellung')
                          .map(file => <StyledFileComponent key={file.id} file={file} download={download} />)
                      )}
                    </StyledFilesContainer>

                    <InfoHint type='success'>
                      Weitere Dokumente findest Du in der Box <b>Dokumente</b> weiter unten.
                    </InfoHint>
                  </>
                )}
                <Text color='grey500' top='20' size='10'>
                  Alle Werte zzgl. der jeweiligen Umsatzsteuer, die jeweils gültige Umsatzsteuer auf steuerbare
                  Dienstleistungen wird gesondert berechntet.
                </Text>
                <Divider margin='30px 0 20px' />
              </>
            )}

          {(isMitarbeiterkonfigurationenDetailsPage || isKonfigurationenDetailsPage) && (
            <VehicleOrderRedirect type={VehicleOrderRedirectTypes.InfoBox} />
          )}

          {[
            OFFER_STATUSES.vehicleConfigurationBasedOnGRVCreated,
            OFFER_STATUSES.approvalRejected,
            OFFER_STATUSES.vehicleRequested,
            OFFER_STATUSES.buildAbilityConfirmed,
            OFFER_STATUSES.dealershipSelected,
            OFFER_STATUSES.orderApproved,
            OFFER_STATUSES.approvedByFleetManager,
            OFFER_STATUSES.orderCompleted,
            OFFER_STATUSES.orderVehicleFromDealership
          ].includes(offerStatus) && (
            <Flex top='50' direction='column' align='flex-end'>
              {/*<OfferStatusSection status={offerStatus} />*/}
              {offerStatus === OFFER_STATUSES.vehicleConfigurationBasedOnGRVCreated &&
                isMitarbeiterkonfigurationenDetailsPage && (
                  <>
                    <InfoHint type='info'>
                      Wenn der Fahrer sich für diese Konfiguration entschieden hat, klicke auf den Button{' '}
                      <Text bold as='span'>
                        Zur Autohausauswahl
                      </Text>
                      , um mit dem Bestellprozess fortzufahren.
                    </InfoHint>
                    <Distance top='15' />
                  </>
                )}

              {[
                OFFER_STATUSES.vehicleConfigurationBasedOnGRVCreated,
                OFFER_STATUSES.vehicleRequested,
                OFFER_STATUSES.approvalRejected,
                OFFER_STATUSES.purchaseTriggered,
                OFFER_STATUSES.dealershipSelected,
                OFFER_STATUSES.orderApproved,
                OFFER_STATUSES.buildAbilityConfirmed,
                OFFER_STATUSES.approvedByFleetManager
              ].includes(offerStatus) &&
                !(
                  [
                    OFFER_STATUSES.vehicleRequested,
                    OFFER_STATUSES.orderApproved,
                    OFFER_STATUSES.dealershipSelected,
                    OFFER_STATUSES.buildAbilityConfirmed,
                    OFFER_STATUSES.approvedByFleetManager
                  ].includes(offerStatus) && isKonfigurationenDetailsPage
                ) && (
                  <DateInfo
                    icon={<OrderIcon width='12' height='14' />}
                    title='Konfiguration erstellt am:'
                    value={getDateString(vehicleConfigurationBasedOnGRVCreatedEvent?.createdon.attributeValue)}
                  />
                )}
              {[
                OFFER_STATUSES.vehicleRequested,
                OFFER_STATUSES.orderApproved,
                OFFER_STATUSES.dealershipSelected,
                OFFER_STATUSES.buildAbilityConfirmed
              ].includes(offerStatus) &&
                !isMitarbeiterkonfigurationenDetailsPage && (
                  <DateInfo
                    icon={<OrderIcon width='12' height='14' />}
                    title='Freigabe angefordert am:'
                    value={getDateString(vehicleRequestedEvent?.createdon.attributeValue)}
                  />
                )}
              {[
                OFFER_STATUSES.vehicleRequested,
                OFFER_STATUSES.purchaseTriggered,
                OFFER_STATUSES.dealershipSelected,
                OFFER_STATUSES.orderApproved,
                OFFER_STATUSES.buildAbilityConfirmed,
                OFFER_STATUSES.approvedByFleetManager
              ].includes(offerStatus) && (
                <DateInfo
                  icon={<SettingsIconOutlined width='14' height='14' color={theme.colors.grey600} />}
                  title='Status zuletzt geändert am:'
                  value={getDateString(latestEvent?.createdon.attributeValue)}
                />
              )}
              {[OFFER_STATUSES.orderCompleted, OFFER_STATUSES.orderVehicleFromDealership].includes(offerStatus) &&
                latestEvent && (
                  <DateInfo
                    icon={<OrderIcon width='12' height='14' />}
                    title={latestEvent.uds_eventdescription.attributeValue.name}
                    value={getDateString(latestEvent.createdon.attributeValue)}
                  />
                )}
              {[
                OFFER_STATUSES.vehicleConfigurationBasedOnGRVCreated,
                OFFER_STATUSES.vehicleRequested,
                OFFER_STATUSES.purchaseTriggered,
                OFFER_STATUSES.dealershipSelected,
                OFFER_STATUSES.orderApproved,
                OFFER_STATUSES.buildAbilityConfirmed,
                OFFER_STATUSES.approvedByFleetManager,
                OFFER_STATUSES.orderCompleted,
                OFFER_STATUSES.orderVehicleFromDealership
              ].includes(offerStatus) &&
                (isMitarbeiterkonfigurationenDetailsPage || isApprovalDetails) && (
                  <DateInfo
                    icon={<UsersIcon />}
                    title={isPoolcar ? '' : 'Fahrer:'}
                    value={
                      isPoolcar
                        ? 'Poolcar'
                        : vehicleConfigurationBasedOnGRVCreatedEvent?.uds_erstelltfurid.attributeValue?.name || ''
                    }
                  />
                )}
            </Flex>
          )}

          {[OFFER_STATUSES.vehicleRequested].includes(offerStatus) &&
            isMitarbeiterkonfigurationenDetailsPage &&
            !showDeclineDialog && (
              <>
                <CarDealershipSelection reactHookFormData={reactHookFormData} />
              </>
            )}

          {offerStatus === OFFER_STATUSES.globalReferenceVehicleGeneratedViaAPI && <Distance top='15' />}

          {([
            OFFER_STATUSES.globalReferenceVehicleGeneratedViaAPI,
            OFFER_STATUSES.vehicleConfigurationBasedOnGRVCreated
          ].includes(offerStatus) ||
            ([
              OFFER_STATUSES.vehicleRequested,
              OFFER_STATUSES.orderApproved,
              OFFER_STATUSES.buildAbilityConfirmed
            ].includes(offerStatus) &&
              isMitarbeiterkonfigurationenDetailsPage) ||
            ([OFFER_STATUSES.approvedByFleetManager].includes(offerStatus) && isApprovalDetails)) &&
            !showApproversInfo &&
            !showDeclineDialog && (
              <StyledButtonsContainer justify='flex-end' align='flex-start'>
                {offerStatus === OFFER_STATUSES.globalReferenceVehicleGeneratedViaAPI && (
                  <StyledConfiguratorContainer>
                    {user.id ? (
                      <Configurator
                        isGlobalReferenceVehicle={isGlobalReferenceVehicle}
                        leasingOfferId={leasingOffer?.uds_leasingangeboteid || ''}
                        vehicleOfferId={item.uds_fahrzeugangeboteid}
                        deliveryDate={deliveryDate.value ? convertUTCDateToLocalDate(deliveryDate.value) : null}
                        distance={distance.value}
                        validationFunc={validationFunc}
                        referenceDistance={referenceDistance}
                        referenceDuration={referenceDuration}
                      />
                    ) : null}
                  </StyledConfiguratorContainer>
                )}

                {[
                  OFFER_STATUSES.vehicleConfigurationBasedOnGRVCreated,
                  OFFER_STATUSES.vehicleRequested,
                  OFFER_STATUSES.buildAbilityConfirmed,
                  OFFER_STATUSES.approvedByFleetManager
                ].includes(offerStatus) && (
                  <>
                    {[OFFER_STATUSES.buildAbilityConfirmed, OFFER_STATUSES.approvedByFleetManager].includes(
                      offerStatus
                    ) && (
                      <StyledButton onClick={() => setShowDeclineDialog(true)} secondary>
                        Ablehnen
                      </StyledButton>
                    )}
                    <StyledSubmitButtonContainer align='center' direction='column'>
                      <StyledButton onClick={() => submitGRV()} disabled={!isValid || switcherError}>
                        {isKonfigurationenDetailsPage
                          ? SUBMIT_GRV_BUTTON_TEXT_DRIVER[offerStatus]
                          : SUBMIT_GRV_BUTTON_TEXT_FLEET_MANAGER[offerStatus]}
                      </StyledButton>
                      {offerStatus === OFFER_STATUSES.buildAbilityConfirmed && (
                        <Text style={{ order: '2' }} as='span' size='10' textAlign='center' color='grey600'>
                          Du wirst zum nächsten Schritt
                          <br /> weitergeleitet.
                        </Text>
                      )}
                    </StyledSubmitButtonContainer>
                  </>
                )}

                {[OFFER_STATUSES.orderApproved].includes(offerStatus) && item.uds_anbietenterhandlerid_id && (
                  <StyledButton onClick={() => submitGRV()}>
                    Beim Autohaus{' '}
                    <Text as='span' bold>
                      {item.uds_anbietenterhandlerid}
                    </Text>{' '}
                    bestellen
                  </StyledButton>
                )}
              </StyledButtonsContainer>
            )}

          {showApproversInfo && (
            <ApproversInfo
              submit={submitGRV}
              fileId={files.find(file => file.description === 'Verbindliche Bestellung')?.id || null}
              loading={loading}
              offerStatus={offerStatus}
              setShowApproversInfo={setShowApproversInfo}
              driverId={vehicleConfigurationBasedOnGRVCreatedEvent?.uds_erstelltfurid.attributeValue?.id}
            />
          )}

          {showDeclineDialog && <DeclineDialog decline={decline} setShowDeclineDialog={setShowDeclineDialog} />}

          {switcherError && (
            <InfoHint className='mt-15' type={InfoHintType.Danger}>
              Bitte wähle eine Laufzeit, Fahrleistung und Leasingangebot aus.
            </InfoHint>
          )}
        </StyledOrderWidget>

        {!!approvalRecords?.length && (
          <StyledOrderWidget>
            <ApproveStatus approvalRecords={approvalRecords} />
          </StyledOrderWidget>
        )}

        <Snackbar
          open={!!snackbarText.text}
          type={snackbarText.type}
          onClose={() => setSnackbarText({ type: snackbarText.type, text: '' })}
          message={snackbarText.text}
        />
      </>
    );
  }

  return (
    <>
      <StyledOrderWidget>
        {offerStatus === OFFER_STATUSES.configurationCreated ? (
          <>
            <Title as='h4' color='blue' bottom='30'>
              Leasingangebot anfragen
            </Title>

            <Text color='blue' bold bottom='10'>
              Laufzeit (Monate)
            </Text>
            <MonthsComponent months={months} setMonths={setMonths} />

            <Text color='blue' bold bottom='10' top='20'>
              Jährliche Fahrleistung (in km/Jahr)
            </Text>
            <DistanceComponent
              distance={distance}
              setDistance={setDistance}
              minDistance={MIN_DISTANCE}
              maxDistance={MAX_DISTANCE}
              formErrors={formErrors}
            />

            <Text color='blue' bold bottom='10' top='20'>
              Wunschlieferdatum
            </Text>
            <DeliveryDateComponent
              deliveryDate={deliveryDate}
              setDeliveryDate={setDeliveryDate}
              minDate={firstDayCurrentMonth}
              maxDate={twoYearsFromNow}
              formErrors={formErrors}
            />
          </>
        ) : (
          <>
            {item.uds_wunschlaufzeitcode_value && item.uds_laufleistungkm && (
              <StyledInfoCardsContainer bottom='30' justify='flex-start'>
                <InfoCard value={item.uds_wunschlaufzeitcode_value} units='Monate' icon={<OrderWidgetPeriodIcon />} />
                <StyledDistance />
                <InfoCard
                  value={item.uds_laufleistungkm.toLocaleString('de-DE')}
                  units='Km'
                  icon={<OrderWidgetDistanceIcon />}
                />
              </StyledInfoCardsContainer>
            )}
            {isDriverReferenzfahrzeugeDetailsPage ||
            isReferenzfahrzeugeDetailsPage ||
            (isMitarbeiterkonfigurationenDetailsPage && !item.uds_wunschlieferdatum) ? (
              <>
                <Text color='blue' bold bottom='10' top='20'>
                  Wunschlieferdatum
                </Text>
                <DeliveryDateComponent
                  deliveryDate={deliveryDate}
                  setDeliveryDate={setDeliveryDate}
                  minDate={firstDayCurrentMonth}
                  maxDate={twoYearsFromNow}
                  formErrors={formErrors}
                />
              </>
            ) : (
              <>
                {item.uds_wunschlieferdatum && (
                  <InfoBlock
                    title={
                      <Text bold color='blue'>
                        Wunschlieferdatum
                      </Text>
                    }
                    value={
                      <Text id={'wunschlieferdatumValue'} size='12' color='grey900'>
                        {new Date(item.uds_wunschlieferdatum).toLocaleDateString('de-DE', {
                          month: 'long',
                          year: 'numeric'
                        })}
                      </Text>
                    }
                  />
                )}
                <Distance top='15' />
                <InfoBlock
                  title={
                    <Text size='12' color='grey900'>
                      Vsl. Lieferdatum lt. Händler
                    </Text>
                  }
                  value={getDealerDeliveryDate(item.uds_vsl_liefertermin_laut_handler_main)}
                />
              </>
            )}
          </>
        )}

        {![
          OFFER_STATUSES.configurationCreated,
          OFFER_STATUSES.offerRequested,
          OFFER_STATUSES.dealershipSelected
        ].includes(offerStatus) && (
          <>
            <Distance top='10' />
            <AdditionalDataPanel
              additionalDataPanel={dealerInfoSection}
              onChange={() => {}}
              onTagClick={() => {}}
              onToggle={toggleDealerInfoSection}
              setNewSteps={() => {}}
              step={{}}
              bodyComponent={
                <DealerContactInfo
                  dealerCompanyId={item.uds_anbietenterhandlerid_id || null}
                  dealerContactId={item.uds_haendlerkontakt_id || null}
                />
              }
            />
          </>
        )}

        {offerStatus === OFFER_STATUSES.purchaseTriggered ? (
          <>
            <Divider margin='30px 0 20px' />
            {item.uds_barpreis_netto_value > 0 && (
              <InfoBlock
                title={<Text color='grey600'>Barpreis Netto</Text>}
                value={<Text color='black'>{getMoneyValue(item.uds_barpreis_netto_value)}</Text>}
              />
            )}
            <StyledOneTimeCostsContainer top='30' bottom='20' direction='column'>
              <Text bold size='12' color='grey600'>
                Einmalige Kosten
              </Text>
              <Divider margin='15px 0' />
              {item.uds_einmaligekosten_uberfuhrungskosten_value >= 0 && (
                <InfoBlock
                  title={
                    <Text bottom='5' color='grey600'>
                      Überführung
                    </Text>
                  }
                  value={
                    <Text bottom='5' color='grey600'>
                      {getMoneyValue(item.uds_einmaligekosten_uberfuhrungskosten_value || 0)}
                    </Text>
                  }
                />
              )}
              {item.uds_einmaligekosten_zulassungskosten_value >= 0 && (
                <InfoBlock
                  title={
                    <Text bottom='5' color='grey600'>
                      Zulassung
                    </Text>
                  }
                  value={
                    <Text bottom='5' color='grey600'>
                      {getMoneyValue(item.uds_einmaligekosten_zulassungskosten_value || 0)}
                    </Text>
                  }
                />
              )}
              {item.uds_einmaligekosten_anzahlung_value >= 0 && (
                <InfoBlock
                  title={<Text color='grey600'>Anzahlung</Text>}
                  value={<Text color='grey600'>{getMoneyValue(item.uds_einmaligekosten_anzahlung_value || 0)}</Text>}
                />
              )}
            </StyledOneTimeCostsContainer>
          </>
        ) : (
          <Distance top='160' />
        )}

        {[
          OFFER_STATUSES.purchaseTriggered,
          OFFER_STATUSES.orderCompleted,
          OFFER_STATUSES.orderVehicleFromDealership
        ].includes(offerStatus) &&
          isMitarbeiterkonfigurationenDetailsPage &&
          item.uds_kommentar_des_haendlers && (
            <>
              <Text bottom='10' color='grey600' size='12'>
                Autohaus Notiz
              </Text>
              <Text color='grey600' size='12'>
                {item.uds_kommentar_des_haendlers}
              </Text>
            </>
          )}

        {![OFFER_STATUSES.configurationCreated, OFFER_STATUSES.offerRequested, OFFER_STATUSES.priceRequested].includes(
          offerStatus
        ) &&
          priceWithoutFuelCost &&
          budgetData && (
            <>
              <BudgetScale
                budget={budgetData.budget}
                fuelCost={fuelCost}
                leasingPrice={priceWithoutFuelCost}
                surcharge={budgetData.surcharge}
              />
              <InfoBlock
                title={
                  <Text size='12' color='grey800'>
                    Leasing-Rate inkl. Dienstleistungen
                  </Text>
                }
                value={
                  <Text bold size='18' color='blue'>
                    {getMoneyValue(priceWithoutFuelCost)}
                  </Text>
                }
              />
              {fuelCost ? (
                <>
                  <Distance top='10' />
                  <InfoBlock
                    title={
                      <Text size='12' color='grey800'>
                        Kalkulatorische Kraftstoffkosten
                      </Text>
                    }
                    value={
                      <Text bold size='18' color='blue'>
                        {getMoneyValue(fuelCost)}
                      </Text>
                    }
                  />
                </>
              ) : null}
              <Divider margin='20px 0' />

              <StyledCostContainer bottom='10' align='center' justify='space-between'>
                <Text color='blue' bottom='10' bold>
                  Mobilitätskosten
                </Text>
                <Tooltip title={BUDGET_STATUSES[budgetStatus].tooltip}>
                  <Flex align='center'>
                    {BUDGET_STATUSES[budgetStatus].icon}
                    <Text
                      left='10'
                      style={{ whiteSpace: 'pre' }}
                      color={BUDGET_STATUSES[budgetStatus].color}
                      size='18'
                      bold
                    >
                      {`${
                        isKonfigurationenDetailsPage && offerStatus !== OFFER_STATUSES.orderApproved ? 'ab ' : ''
                      }${price.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })} / Monat`}
                    </Text>
                  </Flex>
                </Tooltip>
              </StyledCostContainer>
              <Flex justify='flex-end'>
                <Text color='grey600' size='12'>
                  {`${isKonfigurationenDetailsPage && offerStatus !== OFFER_STATUSES.orderApproved ? 'ab ' : ''}${(
                    price * TAXES_COEFFICIENT
                  ).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })} (inkl. MwSt.)`}
                </Text>
              </Flex>
              {budgetStatus === 'warning' && (
                <Flex top='10' justify='flex-end'>
                  <Text color='gold' size='12'>
                    {`inkl. ${supplement?.toLocaleString('de-DE', {
                      style: 'currency',
                      currency: 'EUR'
                    })} Eigenzuzahlung`}
                  </Text>
                </Flex>
              )}
              <Distance top='50' />
            </>
          )}

        {offerStatus === OFFER_STATUSES.purchaseTriggered && (
          <>
            <StyledFilesContainer direction='column' top='20'>
              {loading ? (
                <Spinner
                  style={{
                    width: '50px',
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)'
                  }}
                />
              ) : (
                files.length > 0 &&
                files
                  .filter(file => file.description === 'Verbindliche Bestellung')
                  .map(file => <StyledFileComponent key={file.id} file={file} download={download} />)
              )}
            </StyledFilesContainer>

            <InfoHint type='success'>
              Weitere Dokumente findest Du in der Box <b>Dokumente</b> weiter unten.
            </InfoHint>
            <Text color='grey500' top='20' size='10'>
              Alle Werte zzgl. der jeweiligen Umsatzsteuer, die jeweils gültige Umsatzsteuer auf steuerbare
              Dienstleistungen wird gesondert berechntet.
            </Text>
            <Divider margin='30px 0 20px' />
          </>
        )}

        {(isMitarbeiterkonfigurationenDetailsPage || isKonfigurationenDetailsPage) && (
          <VehicleOrderRedirect type={VehicleOrderRedirectTypes.InfoBox} />
        )}

        {!(isReferenzfahrzeugeDetailsPage || isDriverReferenzfahrzeugeDetailsPage) && (
          <Flex direction='column' align='flex-end'>
            {/*<OfferStatusSection status={offerStatus} />*/}
            {!isKonfigurationenDetailsPage && (
              <DateInfo
                icon={<UsersIcon />}
                title={isPoolcar ? '' : 'Fahrer:'}
                value={isPoolcar ? 'Poolcar' : configurationCreatedEvent?.uds_erstelltfurid.attributeValue?.name || ''}
              />
            )}
            {[
              OFFER_STATUSES.offerRequested,
              OFFER_STATUSES.priceRequested,
              OFFER_STATUSES.dealershipSelected,
              OFFER_STATUSES.requirementApproved
            ].includes(offerStatus) && (
              <DateInfo
                icon={<OrderIcon width='12' height='14' />}
                title='Leasingangebot angefragt am:'
                value={getDateString(offerRequestedEvent?.createdon.attributeValue)}
              />
            )}
            {[
              OFFER_STATUSES.vehicleRequested,
              OFFER_STATUSES.purchaseTriggered,
              OFFER_STATUSES.approvedByFleetManager,
              OFFER_STATUSES.orderApproved
            ].includes(offerStatus) && (
              <DateInfo
                icon={<OrderIcon width='12' height='14' />}
                title='Angefordert am:'
                value={getDateString(vehicleRequestedEvent?.createdon.attributeValue)}
              />
            )}
            {[OFFER_STATUSES.approvedByFleetManager].includes(offerStatus) && (
              <DateInfo
                icon={<OrderIcon width='12' height='14' />}
                title='Freigabe Fuhrparkleiter am:'
                value={getDateString(approvedByFleetManagerEvent?.createdon.attributeValue)}
              />
            )}
            {[OFFER_STATUSES.orderApproved].includes(offerStatus) && (
              <DateInfo
                icon={<CalendarIcon width='12' height='14' />}
                title='Vollständig freigegeben am:'
                value={getDateString(orderApprovedEvent?.createdon.attributeValue)}
              />
            )}
            {[OFFER_STATUSES.approvalRejected].includes(offerStatus) && (
              <DateInfo
                icon={<CalendarIcon width='12' height='14' />}
                title='Abgelehnt am:'
                value={getDateString(approvalRejectedEvent?.createdon.attributeValue)}
              />
            )}
          </Flex>
        )}

        {offerStatus === OFFER_STATUSES.approvalRejected && approvalRejectedEvent?.uds_notiz?.attributeValue && (
          <div>
            <Text margin='20px 0' color='grey600'>
              Ablehnungsgrund:
            </Text>
            <StyledOneTimeCostsContainer>
              <Text color='grey600'>{approvalRejectedEvent.uds_notiz.attributeValue || ''}</Text>
            </StyledOneTimeCostsContainer>
          </div>
        )}

        {[OFFER_STATUSES.offerRequested].includes(offerStatus) && isMitarbeiterkonfigurationenDetailsPage && (
          <>
            <CarDealershipSelection reactHookFormData={reactHookFormData} />
            <Distance top='40' />
          </>
        )}

        {[
          OFFER_STATUSES.configurationCreated,
          OFFER_STATUSES.offerRequested,
          OFFER_STATUSES.requirementApproved,
          OFFER_STATUSES.purchaseTriggered
        ].includes(offerStatus) &&
          !showApproversInfo &&
          !showDeclineDialog && (
            <StyledButtonsContainer justify='flex-end' align='flex-start' top='20'>
              {isReferenzfahrzeugeDetailsPage || isDriverReferenzfahrzeugeDetailsPage ? (
                <StyledConfiguratorContainer>
                  {user.id && item.uds_internalid ? (
                    <Configurator
                      isGlobalReferenceVehicle={isGlobalReferenceVehicle}
                      leasingOfferId={leasingOffer?.uds_leasingangeboteid || ''}
                      vehicleOfferId={item.uds_fahrzeugangeboteid}
                      deliveryDate={null}
                      distance={null}
                      referenceDistance={referenceDistance}
                      referenceDuration={referenceDuration}
                    />
                  ) : null}
                </StyledConfiguratorContainer>
              ) : ![OFFER_STATUSES.offerRequested].includes(offerStatus) && isShowArchiveButton ? (
                <StyledButton onClick={() => setShowDeclineDialog(true)} secondary>
                  {isKonfigurationenDetailsPage ? 'Archivieren' : 'Ablehnen'}
                </StyledButton>
              ) : null}
              {!([OFFER_STATUSES.offerRequested].includes(offerStatus) && isKonfigurationenDetailsPage) && (
                <StyledSubmitButtonContainer align='center' direction='column'>
                  <StyledButton onClick={submit} bottom='5' disabled={!isValid}>
                    {SUBMIT_BUTTON_TEXT[offerStatus]}
                  </StyledButton>
                  {offerStatus === OFFER_STATUSES.purchaseTriggered && (
                    <Text style={{ order: '2' }} as='span' size='10' textAlign='center' color='grey600'>
                      Du wirst zum nächsten Schritt
                      <br /> weitergeleitet.
                    </Text>
                  )}
                </StyledSubmitButtonContainer>
              )}
            </StyledButtonsContainer>
          )}

        {showApproversInfo && (
          <ApproversInfo
            submit={submit}
            fileId={files.find(file => file.description === 'Verbindliche Bestellung')?.id || null}
            loading={loading}
            offerStatus={offerStatus}
            setShowApproversInfo={setShowApproversInfo}
            driverId={configurationCreatedEvent?.uds_erstelltfurid.attributeValue?.id}
          />
        )}

        {showDeclineDialog && <DeclineDialog decline={decline} setShowDeclineDialog={setShowDeclineDialog} />}
      </StyledOrderWidget>

      {!isKonfigurationenDetailsPage &&
        item.uds_anbietenterhandlerid_id &&
        budgetCheckPositive &&
        configurationCreatedEvent?.uds_eventdescription.attributeValue.id ===
          VEHICLE_OFFER_EVENT_DESCRIPTIONS.configurationUploaded.id && (
          <StyledOrderWidget>
            <Title as='h4' color='blue' bottom='30'>
              Fahrzeugangebot von:
            </Title>
            <LeasingbankInfo companyId={item.uds_anbietenterhandlerid_id} />
          </StyledOrderWidget>
        )}

      {!isKonfigurationenDetailsPage &&
        leasingOffer?.new_leasingbank_id &&
        budgetCheckPositive &&
        configurationCreatedEvent?.uds_eventdescription.attributeValue.id ===
          VEHICLE_OFFER_EVENT_DESCRIPTIONS.configurationUploaded.id && (
          <StyledOrderWidget>
            <Title as='h4' color='blue' bottom='30'>
              Leasingangebot von:
            </Title>
            <LeasingbankInfo companyId={leasingOffer.new_leasingbank_id} />
          </StyledOrderWidget>
        )}

      <VehicleContainer
        img={<ClipIcon color='#335566' />}
        title='Dokumente'
        description={undefined}
        className={undefined}
      >
        <DocumentClassicComponent
          loading={loading}
          files={
            isKonfigurationenDetailsPage
              ? files.filter(file => file.description === 'Hersteller-Konfiguration')
              : files.filter(file =>
                  offerStatus === OFFER_STATUSES.purchaseTriggered
                    ? file.description !== 'Verbindliche Bestellung'
                    : true
                )
          }
        />
      </VehicleContainer>

      {/*<StyledOrderWidget id='dein-servicepaket'>*/}
      {/*  <Title as='h4' color='blue' bottom='30'>Dein Servicepaket</Title>*/}

      {/*  <FlatrateSection item={{}} />*/}
      {/*  /!*<Flex justify='space-between'>*!/*/}
      {/*  /!*  <Text bold color='grey600'>Vollkasko-Versicherung</Text>*!/*/}
      {/*  /!*  <ThreeStateCheckbox disabled checked indeterminate={false} />*!/*/}
      {/*  /!*</Flex>*!/*/}
      {/*</StyledOrderWidget>*/}

      <Snackbar
        open={!!snackbarText.text}
        type={snackbarText.type}
        onClose={() => setSnackbarText({ type: snackbarText.type, text: '' })}
        message={snackbarText.text}
      />
    </>
  );
};

export default OrderWidget;
