import { AutocompleteFilterFromState, SessionFilter, SetSessionFilter } from 'hooks/use-session-filter';
import { AnyObject } from 'common/interfaces';
import { FileProps } from 'pages/vehicle-return-page/vehicle-return-page.props';
import { SpecificPhaseInfoProps } from 'components/aufgaben-component/task.prop';

export enum VehicleStatusesLabel {
  Ordered = 'Bestellt',
  InOperation = 'In Betrieb',
  ControlRequested = 'Aussteuerung angefordert',
  InControl = 'In Aussteuerung',
  Controlled = 'Ausgesteuert'
}

export enum VehicleStatusesValue {
  Ordered = 100_000_000,
  InOperation = 100_000_001,
  Controlled = 100_000_002,
  ControlRequested = 100_000_003,
  InControl = 100_000_004
}

export enum VehicleStateCodeValue {
  Inactive = 1,
  Active = 0
}

export enum VehicleStateCodeLabel {
  Inactive = 'Inaktiv',
  Active = 'Aktiv'
}

export type Pagination = {
  start: number;
  number: number;
};

export interface TableParams {
  pagination: Pagination;
  sort: AnyObject;
}

export interface DownloadExelResponse {
  items: string[];
  numberOfPages: 0;
  totalRecord: 0;
}

export interface VehicleItemData {
  imgSrc?: string;
  new_name: string;
  statecode: VehicleStateCodeLabel;
  statecode_value: VehicleStateCodeValue;
  new_interne_fahrzeugnummer: string;
  uds_interne_fahrzeugnummer_kunde: string;
  new_hersteller: string;
  uds_consumption_estimate_value: ConsumptionLabelEnum;
  uds_consumption_estimate_value_value: ConsumptionValueEnum;
  new_model: string;
  new_ezl: string;
  new_fin: string;
  new_kauf_leasing: string;
  new_sparteid: string;
  new_sparteid_id: string;
  new_kostenstelleid: string;
  new_kostenstelleid_id: string;
  new_versichererid: string;
  new_versichererid_id: string;
  new_fuhrparkid: string;
  modifiedon: string;
  new_fahrerid: string;
  new_fahrerid_id: string;
  new_leasingende: string;
  portalStatus: VehicleStatusesLabel;
  uds_modexocarinternalid: string;
  new_status_carcode: VehicleStatusesLabel;
  new_status_carcode_value: VehicleStatusesValue;
  uds_lease_return_date: string;
  uds_silverdatvehicletypeoption: string;
  uds_silverdatvehicletypeoption_value: number;
  kmStand: string;
  fahrerStatus: string;
  leasingruckgabeproEnable: boolean;
  new_standortid: string;
  new_standortid_id: string;
  leaseEndInfo: {
    lva: {
      lva_state: number;
      lva_phaseinfo: {
        fileList: {
          Verlängerungangebot: FileProps[];
        };
        fuhrparkId: string;
        lvaId: string;
        specificPhaseInfo: SpecificPhaseInfoProps;
      };
    };
  };
}

export interface MobileMyVehiclesItemProps {
  data: VehicleItemData;
}

export type DataWithIdType = {
  id: string;
  label: string;
};

export type DataWithIdCapitalizedType = {
  Id: string;
  Label: string;
};

export interface TableData {
  fuhrparks: VehicleItemData[];
  totalCount: number;
}

export enum MileageRecommendationValueEnum {
  Increase = 100_000_000,
  Reduce = 100_000_001
}

export enum MileageRecommendationLabelEnum {
  Increase = 'Muss die Laufleistung erhöhen',
  Reduce = 'Muss die Laufleistung verringern'
}

export enum ConsumptionLabelEnum {
  Increased = 'erhöhter Verbrauch',
  Normal = 'normaler Verbrauch'
}

export enum ConsumptionValueEnum {
  Increased = 100_000_001,
  Normal = 100_000_000
}

export interface MyVehiclesFiltersProps {
  sessionFilter: SessionFilter;
  setSessionFilter: SetSessionFilter;
  goToFirstPage: () => void;
}

export type MyVehicleLocation = Partial<AutocompleteFilterFromState> | undefined;

export enum DataQualityLabel {
  Good = 'Datenqualität ist gut',
  Bad = 'Datenqualität ist schlecht',
  Average = 'Datenqualität ist mittel'
}

export enum DataQualityValue {
  Good = 100_000_001,
  Bad = 100_000_002,
  Average = 100_000_003
}
