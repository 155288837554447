import useUserInfo from 'hooks/react-query/use-user-info';
import { ComponentsIdType } from 'hooks/react-query/use-user-info/use-user-info.types';
import { GetIsDisplayComponentOptionsType } from './use-component.props';

export const useComponent = () => {
  const { userInfo } = useUserInfo();

  const getIsDisplayComponent = (
    id: ComponentsIdType,
    { showByDefault = true }: GetIsDisplayComponentOptionsType = {}
  ) => {
    if (userInfo?.appPages.components === null) {
      return showByDefault;
    }
    return userInfo?.appPages.components?.some(item => item.attributes.uds_name === id);
  };

  return { getIsDisplayComponent };
};
