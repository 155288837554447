import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classes from './leasing-comparison-section.module.scss';
import useEllipsisTooltip from '../../../hooks/use-ellipsis-tooltip';
import Switch from 'react-switch';
import { DoneIcon } from 'components/icons/offer-statuses/done-icon';
import toMoney from '../../../utils/toMoney';

const ComparisonSectionTitle = ({ title, part }) => {
  const { isShowTip, style, content, onMouseEnter, onMouseLeave } = useEllipsisTooltip(5);

  return (
    <>
      <h5 onMouseEnter={onMouseEnter} ref={content} onMouseLeave={onMouseLeave} className={classes.section_title}>
        {part}
      </h5>
      {isShowTip && <span style={style}>{title}</span>}
    </>
  );
};

ComparisonSectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  part: PropTypes.string.isRequired
};

const ComparisonSectionTitles = ({ titleObj }) => {
  return (
    <>
      <div>
        {titleObj.parts.map(part => (
          <ComparisonSectionTitle key={part} part={part} title={titleObj.title} />
        ))}
      </div>
    </>
  );
};

ComparisonSectionTitles.propTypes = {
  titleObj: PropTypes.shape({
    title: PropTypes.string.isRequired,
    parts: PropTypes.arrayOf(PropTypes.string).isRequired
  }).isRequired
};

const LeasingTitle = ({ leasingOffer }) => {
  const { isShowTip, style, content, onMouseEnter, onMouseLeave } = useEllipsisTooltip(5);
  const {
    isShowTip: isShowTipTitle,
    style: styleTitle,
    content: contentTitle,
    onMouseEnter: onMouseEnterTitle,
    onMouseLeave: onMouseLeaveTitle
  } = useEllipsisTooltip(5);

  const leasingConditions = `${leasingOffer.uds_laufzeit} Monate • ${leasingOffer.uds_laufleistungpa.toLocaleString(
    'de-DE'
  )} km / Jahr`;

  return (
    <>
      <h5
        onMouseEnter={onMouseEnterTitle}
        onMouseLeave={onMouseLeaveTitle}
        ref={contentTitle}
        className={classes.section_title}
      >
        {leasingOffer.new_leasingbank}
        {isShowTipTitle && <span style={styleTitle}>{leasingOffer.new_leasingbank}</span>}
      </h5>
      <span
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        ref={content}
        className={`${classes.standard_text} ${classes.large}`}
      >
        {leasingConditions}
        {isShowTip && <span style={style}>{leasingConditions}</span>}
      </span>
      <span className={`${classes.standard_text} ${classes.small}`}>{leasingOffer.uds_laufzeit} Monate</span>
      <span className={`${classes.standard_text} ${classes.small}`}>
        {leasingOffer.uds_laufleistungpa.toLocaleString('de-DE')} km / Jahr
      </span>
    </>
  );
};

LeasingTitle.propTypes = {
  leasingOffer: PropTypes.object.isRequired
};

const LeasingButtons = ({ leasingOffer }) => {
  return leasingOffer.active ? (
    <button
      onClick={() => {
        document.querySelector('.scroll-container').scrollTo({ top: 0, behavior: 'smooth' });
      }}
      style={{ width: '80%', maxWidth: '180px' }}
      className='button-mobexo button-mobexo__main'
    >
      Zum Angebot
    </button>
  ) : (
    <button
      onClick={() => {}}
      style={{ width: '80%', maxWidth: '180px' }}
      className='button-mobexo button-mobexo__secondary'
    >
      Auswählen
    </button>
  );
};

LeasingButtons.propTypes = {
  leasingOffer: PropTypes.object.isRequired
};

const CheckedField = ({ value }) => {
  return (
    <>
      {value ? (
        <DoneIcon width={24} height={24} />
      ) : (
        <img style={{ width: '20px' }} src={'/assets/images/red_cross.svg'} alt='services_icon' />
      )}
    </>
  );
};

CheckedField.propTypes = {
  value: PropTypes.bool.isRequired
};

const LeasingRate = ({ leasingRate }) => {
  return (
    <span className='d-flex f-d-column'>
      <span className={classes.leasingrate}>{toMoney(leasingRate)}</span>
      <span>im Monat</span>
    </span>
  );
};

LeasingRate.propTypes = {
  leasingRate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

const LeasingOffer = ({ leasingOffer, changeActiveLeasingOffer, minWidth = 33.33, showAll, isShowButtons }) => {
  const getCents = value => {
    return (parseFloat(value.replace(',', '.')) * 100).toLocaleString('de-DE');
  };

  const fields = useMemo(() => {
    return [
      {
        greyBackground: false,
        hidden: false,
        content: leasingOffer.uds_laufleistungpa ? `${leasingOffer.uds_laufleistungpa.toLocaleString('de-DE')} KM` : '-'
      },
      {
        greyBackground: true,
        hidden: false,
        content: leasingOffer.uds_laufzeit ? `${leasingOffer.uds_laufzeit} Monate` : '-'
      },
      {
        greyBackground: false,
        hidden: false,
        content: leasingOffer.uds_leasingfaktor ? leasingOffer.uds_leasingfaktor.toLocaleString('de-DE') : '-'
      },
      {
        greyBackground: true,
        hidden: false,
        content: leasingOffer.new_finanzratemobexo ? leasingOffer.new_finanzratemobexo : '-'
      },
      {
        greyBackground: false,
        hidden: false,
        content:
          leasingOffer.leasingprice_value || leasingOffer.uds_gesamtkosten_monatlich_vertragsabschluss_value ? (
            <LeasingRate
              leasingRate={
                leasingOffer.leasingprice_value || leasingOffer.uds_gesamtkosten_monatlich_vertragsabschluss_value
              }
            />
          ) : (
            '-'
          )
      },
      {
        greyBackground: false,
        type: 'buttons',
        hidden: false,
        className: 'd-flex j-c-center',
        content: <LeasingButtons leasingOffer={leasingOffer} />
      },
      {
        greyBackground: false,
        hidden: true,
        content: null
      },
      {
        greyBackground: false,
        hidden: true,
        leasingTitle: true,
        content: <LeasingTitle leasingOffer={leasingOffer} />
      },
      {
        greyBackground: true,
        hidden: true,
        content: leasingOffer.new_wartungundverschlei ? (
          leasingOffer.new_wartung_und_verschless_imobexo || '-'
        ) : (
          <CheckedField value={leasingOffer.new_wartungundverschlei} />
        )
      },
      {
        greyBackground: false,
        hidden: true,
        content: leasingOffer.new_versicherung_gebucht ? (
          leasingOffer.new_versicherung_mobexo || '-'
        ) : (
          <CheckedField value={leasingOffer.new_versicherung_gebucht} />
        )
      },
      {
        greyBackground: true,
        hidden: true,
        content: leasingOffer.new_kfzsteuer_gebucht ? (
          leasingOffer.new_kfz_steuer_mobexo || '-'
        ) : (
          <CheckedField value={leasingOffer.new_kfzsteuer_gebucht} />
        )
      },
      {
        greyBackground: false,
        hidden: true,
        content: leasingOffer.new_gez_gebucht ? (
          leasingOffer.new_gez_mobexo || '-'
        ) : (
          <CheckedField value={leasingOffer.new_gez_gebucht} />
        )
      },
      {
        greyBackground: true,
        hidden: true,
        content: leasingOffer.new_winterreifen_gebucht ? (
          leasingOffer.new_winterreifen_mobexo || '-'
        ) : (
          <CheckedField value={leasingOffer.new_winterreifen_gebucht} />
        )
      },
      {
        greyBackground: false,
        hidden: true,
        content: leasingOffer.new_rdereinlagerungundwechsel_gebucht ? (
          leasingOffer.uds_radereinlagerungundwechsel || '-'
        ) : (
          <CheckedField value={leasingOffer.new_rdereinlagerungundwechsel_gebucht} />
        )
      },
      {
        greyBackground: true,
        hidden: true,
        content: leasingOffer.new_reifenersatz_gebucht ? (
          leasingOffer.new_sommerreifen_mobexo || '-'
        ) : (
          <CheckedField value={leasingOffer.new_reifenersatz_gebucht} />
        )
      },
      {
        greyBackground: false,
        hidden: true,
        content: leasingOffer.new_gap_gebucht ? (
          leasingOffer.uds_gap || '-'
        ) : (
          <CheckedField value={leasingOffer.new_gap_gebucht} />
        )
      },
      {
        divider: true,
        hidden: true
      },
      {
        greyBackground: false,
        hidden: true,
        content: null
      },
      {
        greyBackground: false,
        hidden: true,
        leasingTitle: true,
        content: <LeasingTitle leasingOffer={leasingOffer} />
      },
      {
        greyBackground: true,
        hidden: true,
        content: leasingOffer.uds_mehrkilometer ? `${getCents(leasingOffer.uds_mehrkilometer)} cent` : '-'
      },
      {
        greyBackground: false,
        hidden: true,
        content: leasingOffer.uds_toleranzgrenzekm
          ? `${leasingOffer.uds_toleranzgrenzekm.toLocaleString('de-DE')} KM`
          : '-'
      },
      {
        greyBackground: true,
        hidden: true,
        content: leasingOffer.uds_minderkilometer ? `${getCents(leasingOffer.uds_minderkilometer)} cent` : '-'
      },
      {
        greyBackground: false,
        hidden: true,
        content: leasingOffer.uds_berechnungsfreie_minderkilometer_leasing
          ? `${leasingOffer.uds_berechnungsfreie_minderkilometer_leasing.toLocaleString('de-DE')} KM`
          : '-'
      },
      {
        divider: true,
        hidden: true
      },
      {
        greyBackground: false,
        hidden: true,
        type: 'buttons',
        className: 'd-flex j-c-center',
        content: <LeasingButtons leasingOffer={leasingOffer} />
      },
      {
        greyBackground: false,
        hidden: true,
        content: null
      },
      {
        greyBackground: false,
        hidden: true,
        leasingTitle: true,
        content: <LeasingTitle leasingOffer={leasingOffer} />
      },
      {
        greyBackground: true,
        hidden: true,
        content: leasingOffer.uds_je_mehrkilometer_wv ? `${getCents(leasingOffer.uds_je_mehrkilometer_wv)} cent` : '-'
      },
      {
        greyBackground: false,
        hidden: true,
        content: leasingOffer.uds_berechnungsfreie_mehrkilometer_wv
          ? `${leasingOffer.uds_berechnungsfreie_mehrkilometer_wv.toLocaleString('de-DE')} KM`
          : '-'
      },
      {
        greyBackground: true,
        hidden: true,
        content: leasingOffer.uds_je_minderkilometer_wv
          ? `${getCents(leasingOffer.uds_je_minderkilometer_wv)} cent`
          : '-'
      },
      {
        greyBackground: false,
        hidden: true,
        content: leasingOffer.uds_berechnungsfreie_minderkilometer_wv
          ? `${leasingOffer.uds_berechnungsfreie_minderkilometer_wv.toLocaleString('de-DE')} KM`
          : '-'
      },
      {
        divider: true,
        hidden: true
      },
      {
        greyBackground: false,
        hidden: true,
        type: 'buttons',
        className: 'd-flex j-c-center',
        content: <LeasingButtons leasingOffer={leasingOffer} />
      }
    ].filter(item => (isShowButtons ? item.type !== 'buttons' : true));
  }, [leasingOffer, isShowButtons]);

  const getOfferTitleClasses = leasingOffer => {
    const backgroundClass = leasingOffer.active ? classes.active : classes.inactive;
    const borderClass = leasingOffer.active
      ? leasingOffer.bestOffer
        ? classes.active_border_left_right
        : classes.active_border_radius
      : null;
    return !leasingOffer.bestOffer
      ? `${classes.standard_cell} ${backgroundClass} ${borderClass} ${classes.border_radius}`
      : `${classes.standard_cell} ${backgroundClass} ${borderClass}`;
  };

  return (
    <div
      style={{ minWidth: `${minWidth}%` }}
      onClick={() => changeActiveLeasingOffer(leasingOffer.uds_leasingangeboteid)}
      className={classes.leasing_offer}
    >
      <div
        className={
          !leasingOffer.bestOffer ? `${classes.leasing_offer_container} mt-30` : classes.leasing_offer_container
        }
      >
        {leasingOffer.bestOffer && (
          <div
            className={
              leasingOffer.active
                ? `${classes.best_offer} ${classes.border_radius} ${classes.active_border_left_right}`
                : `${classes.best_offer} ${classes.border_radius}`
            }
          >
            {leasingOffer.uds_isausgewahlt ? 'Deine Auswahl' : '★ Unsere Empfehlung'}
          </div>
        )}
        <div className={getOfferTitleClasses(leasingOffer)}>
          <LeasingTitle leasingOffer={leasingOffer} />
        </div>
        <div className={leasingOffer.active ? classes.active_border_left_right_bottom : null}>
          {fields.map(field => {
            if (field.hidden && !showAll) {
              return null;
            }
            if (field.divider) {
              return <div className='mobexo-divider' />;
            }
            return (
              <div
                key={field.name}
                className={
                  field.greyBackground ? `${classes.standard_cell} ${classes.grey_background}` : classes.standard_cell
                }
              >
                {field.leasingTitle ? (
                  field.content
                ) : (
                  <span
                    className={field.className ? `${classes.standard_text} ${field.className}` : classes.standard_text}
                  >
                    {field.content}
                  </span>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

LeasingOffer.propTypes = {
  leasingOffer: PropTypes.object.isRequired,
  changeActiveLeasingOffer: PropTypes.func.isRequired,
  minWidth: PropTypes.number.isRequired,
  showAll: PropTypes.bool.isRequired,
  isShowButtons: PropTypes.bool.isRequired
};

const LeasingComparisonSection = ({ leasingOffers, changeActiveLeasingOffer, isShowButtons }) => {
  const [showAll, setShowAll] = useState(false);
  const minWidth = useMemo(() => {
    return leasingOffers.length < 3 ? 100 / leasingOffers.length : 33.33;
  }, [leasingOffers]);

  const sections = [
    {
      name: 'Leasingangebote',
      title: (
        <ComparisonSectionTitles
          titleObj={{
            title: 'Leasingangebote',
            parts: ['Leasingangebote']
          }}
        />
      ),
      hidden: false,
      className: 'mt-30',
      fields: [
        {
          name: 'Jährliche Fahrleistung',
          greyBackground: false
        },
        {
          name: 'Laufzeit',
          greyBackground: true
        },
        {
          name: 'Leasingfaktor',
          greyBackground: false
        },
        {
          name: 'Finanzrate',
          greyBackground: true
        },
        {
          name: 'Leasingrate',
          greyBackground: false
        }
      ]
    },
    {
      name: 'Dienstleistungen',
      title: (
        <ComparisonSectionTitles
          titleObj={{
            title: 'Dienstleistungen',
            parts: ['Dienstleistungen']
          }}
        />
      ),
      hidden: true,
      fields: [
        {
          name: 'Wartung und Verschleiß',
          greyBackground: true
        },
        {
          name: 'Versicherung',
          greyBackground: false
        },
        {
          name: 'KFZ-Steuer',
          greyBackground: true
        },
        {
          name: 'Rundfunkbeiträge',
          greyBackground: false
        },
        {
          name: 'Winterreifen mit Felge',
          greyBackground: true
        },
        {
          name: 'Rädereinlagerung und Wechsel',
          greyBackground: false
        },
        {
          name: 'Sommerreifen extra',
          greyBackground: true
        },
        {
          name: 'GAP-Versicherung',
          greyBackground: false
        }
      ]
    },
    {
      name: 'Kilometerabrechnung Leasing',
      title: (
        <ComparisonSectionTitles
          titleObj={{
            title: 'Kilometerabrechnung Leasing',
            parts: ['Kilometerabrechnung', 'Leasing']
          }}
        />
      ),
      hidden: true,
      fields: [
        {
          name: 'Je Mehrkilometer (cent)',
          greyBackground: true
        },
        {
          name: 'Berechnungsfreie Mehrkilometer',
          greyBackground: false
        },
        {
          name: 'Je Minderkilometer (cent)',
          greyBackground: true
        },
        {
          name: 'Berechnungsfreie Minderkilometer',
          greyBackground: false
        }
      ]
    },
    {
      name: 'Kilometerabrechnung Wartung und Verschleiß',
      title: (
        <ComparisonSectionTitles
          titleObj={{
            title: 'Kilometerabrechnung Wartung und Verschleiß',
            parts: ['Kilometerabrechnung', 'Wartung und Verschleiß']
          }}
        />
      ),
      hidden: true,
      fields: [
        {
          name: 'Je Mehrkilometer (cent)',
          greyBackground: true
        },
        {
          name: 'Berechnungsfreie Mehrkilometer',
          greyBackground: false
        },
        {
          name: 'Je Minderkilometer (cent)',
          greyBackground: true
        },
        {
          name: 'Berechnungsfreie Minderkilometer',
          greyBackground: false
        }
      ]
    }
  ];

  const toggleComparisonSection = () => {
    if (showAll) {
      const top = document.querySelector('#leasing-comparison-section')?.offsetTop || 0;
      document.querySelector('.scroll-container').scrollTo({ top });
    }
    setShowAll(!showAll);
  };

  return (
    <>
      <section className={classes.leasing_comparison_container}>
        <div className={classes.titles}>
          <div className={classes.titles_container}>
            {sections.map(section => {
              if (section.hidden && !showAll) {
                return null;
              }
              return (
                <section key={section.name}>
                  <div
                    className={
                      section.className ? `${classes.standard_cell} ${section.className}` : classes.standard_cell
                    }
                  >
                    {typeof section.title === 'string' ? (
                      <h5 className={classes.section_title}>{section.title}</h5>
                    ) : (
                      section.title
                    )}
                  </div>
                  {section.fields.map(field => (
                    <div key={field.name}>
                      <div
                        className={
                          field.greyBackground
                            ? `${classes.standard_cell} ${classes.grey_background}`
                            : classes.standard_cell
                        }
                      >
                        <span className={classes.standard_text}>{field.name}</span>
                      </div>
                    </div>
                  ))}
                  {section.name === 'Leasingangebote' ? (
                    isShowButtons ? null : (
                      <div className={classes.standard_cell} />
                    )
                  ) : (
                    <div className='mobexo-divider' />
                  )}
                  {section.name === 'Kilometerabrechnung Leasing' && !isShowButtons && (
                    <div className={classes.standard_cell} />
                  )}
                  {showAll && <div className={classes.standard_cell} />}
                </section>
              );
            })}
          </div>
        </div>
        <div className={classes.leasing_offers}>
          {leasingOffers.map(leasingOffer => (
            <LeasingOffer
              minWidth={minWidth}
              showAll={showAll}
              isShowButtons={isShowButtons}
              changeActiveLeasingOffer={changeActiveLeasingOffer}
              key={leasingOffer.id}
              leasingOffer={leasingOffer}
            />
          ))}
        </div>
      </section>
      <div className={classes.toggle}>
        <span>{showAll ? 'Details ausblenden' : 'Mehr Details vergleichen'}</span>
        <div className='mr-1' />
        <Switch
          offColor={'#bfbfbf'}
          onColor={'#335566'}
          height={18}
          width={35}
          uncheckedIcon={false}
          checkedIcon={false}
          onChange={toggleComparisonSection}
          checked={showAll}
        />
      </div>
    </>
  );
};

LeasingComparisonSection.propTypes = {
  leasingOffers: PropTypes.array.isRequired,
  isShowButtons: PropTypes.bool.isRequired,
  changeActiveLeasingOffer: PropTypes.func.isRequired
};

export default LeasingComparisonSection;
