import { AutocompleteFilterFromState, SetSessionFilter } from 'hooks/use-session-filter';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { AnyObject } from 'common/interfaces';

export const useSetFilterFromLocationState = (
  setSessionFilter: SetSessionFilter,
  emptyFilterForServer: AnyObject,
  emptyFilterForClient: AnyObject
) => {
  const location = useLocation<Partial<AutocompleteFilterFromState> | undefined>();
  const { state = {} } = location;

  useEffect(() => {
    if (state.filterForServer && state.filterForClient) {
      setSessionFilter(
        { ...emptyFilterForServer, ...state.filterForServer },
        { ...emptyFilterForClient, ...state.filterForClient },
        state.bigActiveFilter
      );
    }
  }, [JSON.stringify(location.state)]);
};
