import React from 'react';
import { StyledCarGeneralInfo, StyledGalleryWrapper, StyledSection, StyledSectionContent } from '../sections.styled';
import { Flex, Text } from 'common/common-components.styled';
import { VehicleGallery } from 'components/gallery/vehicle-gallery/vehicle-gallery';
import { CarSectionProps } from './car-section.props';
import useWindowSize from 'hooks/use-window-size';

export const CarSection = ({
  title,
  description,
  asideComponent,
  headerRightComponent,
  isShowWidgetContainer = false,
  containerProps,
  galleryContainerProps,
  asideContainerProps,
  sectionContentProps,
  ...galleryProps
}: CarSectionProps) => {
  const { windowWidth } = useWindowSize(500);

  const getTitle = () => {
    if (typeof title === 'string') {
      return (
        <Text color='darkBlue' size='18' bold>
          {title}
        </Text>
      );
    }

    return title;
  };

  return (
    <StyledSection {...containerProps}>
      <Flex width='100%' justify='space-between' align='flex-start' bottom='20'>
        <Flex direction='column' maxWidth='97%'>
          {getTitle()}
          <Text color='grey500' size={windowWidth <= 550 ? '14' : '16'} top={windowWidth <= 550 ? '5' : '10'}>
            {description}
          </Text>
        </Flex>

        {headerRightComponent}
      </Flex>

      <StyledSectionContent {...sectionContentProps}>
        <StyledGalleryWrapper
          right={windowWidth <= 550 ? '0' : '20'}
          isShowWidgetContainer={isShowWidgetContainer}
          {...galleryContainerProps}
        >
          <VehicleGallery {...galleryProps} />
        </StyledGalleryWrapper>
        <StyledCarGeneralInfo isShowWidgetContainer={isShowWidgetContainer} {...asideContainerProps}>
          {asideComponent}
        </StyledCarGeneralInfo>
      </StyledSectionContent>
    </StyledSection>
  );
};
