import { ResponseModel } from 'common/interfaces';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import { useQuery } from 'react-query';
import { ReactQueryKeys } from 'common/enums';
import { GetDriverWithCarQueryDataType, GetDriverWithCarQueryOptionsType } from './use-get-drivers-with-car.props';
import { driverRequestConfig } from 'request-config/driver/driver.request-config';
import { useTypedSelector } from 'hooks/use-typed-selector';

export const useGetDriverWithCarQuery = (
  companyIdProps?: string,
  activeOnly = true,
  options: GetDriverWithCarQueryOptionsType = {}
) => {
  const user = useTypedSelector(state => state.app.user);
  const companyId = companyIdProps || user.companyId;

  const { fetch } = useFetch<ResponseModel<GetDriverWithCarQueryDataType>>({
    loadingKey: 'getDriverWithCarQuery',
    ...driverRequestConfig.getDriversWithCar(companyId, activeOnly),
    ...options.fetch
  });

  const { data, ...restQueryProps } = useQuery(
    [ReactQueryKeys.GetDriverWithCar, companyId, activeOnly],
    () => fetch(),
    options.options
  );

  return { getDriverWithCarQuery: data?.data?.data || [], ...restQueryProps };
};
