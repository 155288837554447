import { AxiosRequestConfig } from 'axios';
import { ConditionOperator, CrmDataTypes } from 'common/enums';

class CrmEntityRequestConfig {
  getLocationsByCompany = (companyId: string): AxiosRequestConfig => ({
    method: 'POST',
    url: 'crmEntity/getEntityByFieldQuery',
    data: {
      entityName: 'new_standort',
      columns: ['new_name', 'new_standortid'],
      conditions: [
        {
          attributeTypeCode: CrmDataTypes.Lookup,
          attributeName: 'new_firma',
          conditionOperator: ConditionOperator.Equal,
          value: [{ id: companyId }]
        },
        {
          attributeTypeCode: CrmDataTypes.State,
          attributeName: 'statecode',
          conditionOperator: ConditionOperator.Equal,
          value: [{ value: '0' }]
        }
      ],
      order: { fieldName: 'new_name', reverse: false }
    }
  });
}

export const crmEntityRequestConfig = new CrmEntityRequestConfig();
