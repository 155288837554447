import React, { useContext } from 'react';
import { Flex, Text } from 'common/common-components.styled';
import { formFieldProvider } from '../../../form-field';
import { FormLabelProps } from './label.props';
import { Tooltip } from '@mui/material';
import CircleInfoIcon from 'components/icons/warning-icon';
import { useHighlightedFormFields } from 'hooks/use-highlighted-form-fields';
import { useTheme } from 'styled-components';

export const FormLabel = ({ isRequired }: FormLabelProps) => {
  const {
    reactHookFormData,
    field: { name, label, labelStyle, validation, alwaysShowAsRequiredField, tooltipText }
  } = useContext(formFieldProvider);
  const { getValues } = reactHookFormData;

  const theme = useTheme();
  const { highlightedColorString } = useHighlightedFormFields(name, getValues(name));

  const isShowRequiredMark = isRequired || validation.required || alwaysShowAsRequiredField;

  if (!label) {
    return <></>;
  }

  return (
    <Flex width='100%' bottom='5'>
      <Flex width='100%' align='center' justify='space-between'>
        <Flex>
          <Text as='span' color={highlightedColorString || 'darkBlue'} {...labelStyle}>
            {label}
          </Text>
          {isShowRequiredMark && (
            <Text left='5' as='span' {...labelStyle} color='pink'>
              *
            </Text>
          )}
        </Flex>
      </Flex>

      {tooltipText && (
        <Tooltip title={tooltipText}>
          <Flex left='10' align='center'>
            <CircleInfoIcon color={theme.colors.grey400} />
          </Flex>
        </Tooltip>
      )}
    </Flex>
  );
};
