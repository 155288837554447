import { AxiosRequestConfig } from 'axios';

class DriverRequestConfig {
  getDriversWithCar = (sparteId: string, activeOnly: boolean = true): AxiosRequestConfig => ({
    method: 'post',
    url: 'driver/getDriversWithCar',
    data: { sparteId, activeOnly }
  });
}

export const driverRequestConfig = new DriverRequestConfig();
