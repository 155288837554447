import { useTheme } from 'styled-components';
import { useTypedSelector } from './use-typed-selector';
import { useCallback } from 'react';

export const useHighlightedFormFields = (fieldNameProps: string = '', valueProps?: unknown) => {
  const highlightedFormFields = useTypedSelector(state => state.app.highlightedForm.fields) as string[];
  const theme = useTheme();

  const getHighlightedColorStringMemorization = useCallback(() => {
    let initialValue: unknown;
    let isSetInitialValue = true;

    return (fieldName: string, value: unknown) => {
      const isFieldHighlighted = highlightedFormFields.some(field =>
        fieldName.toLowerCase().includes(field.toLowerCase())
      );

      if (isSetInitialValue) {
        initialValue = value;
        isSetInitialValue = false;
      }

      if (isFieldHighlighted && !initialValue && typeof initialValue !== 'boolean') {
        return 'pink';
      }
    };
  }, [highlightedFormFields.length]);

  const highlightedColorString = getHighlightedColorStringMemorization()(fieldNameProps, valueProps);

  return {
    getHighlightedColorStringMemorization,
    highlightedColorString,
    highlightedColorTheme: highlightedColorString && theme.colors[highlightedColorString]
  };
};
