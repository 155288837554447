import React, { useState, FormEvent } from 'react';
import { AnyObject } from 'common/interfaces';
import { CircleCheckMark } from 'components/icons/circle-check-mark';
import { StyledButton, StyledForm } from 'pages/tasks-page/task-handler/task-handler.styled';
import { Button, Flex, Text } from 'common/common-components.styled';

import { dienstleisteraufgabenFormFields } from 'components/aufgaben-component/components/unfolded-task/formFields';
import { getFormValues } from 'utils/get-form-values';
import { setForm } from 'actions/app_action';
import { useDispatch, useSelector } from 'react-redux';
import useLatestValue from 'hooks/use-latest-value';
import { uploadDate } from 'components/aufgaben-component/utils';
import { convertUTCDateToLocalDate } from 'utils/convert-UTC-date-to-local-date';
import { useOverlay } from 'hooks';
import { SnackbarState } from 'pages/tasks-page/tasks-page';
import { crmDataTypes, STATECODE } from '../../../constants';
import { useQueryClient } from 'react-query';
import { PAGES } from 'common/enums';

interface TaskHandlerProps {
  row: AnyObject;
  setSnackbarState(data: SnackbarState): void;
}

const TaskHandler = ({ row, setSnackbarState }: TaskHandlerProps) => {
  const forms = useSelector((state: AnyObject): AnyObject => state['app'].forms);
  const [showOverlay, hideOverlay] = useOverlay();
  const formsLatest = useLatestValue(forms);
  const dispatch = useDispatch();
  const [isConfirmationButtonsOpen, setIsConfirmationButtonsOpen] = useState(false);

  const queryClient = useQueryClient();

  const submitTask = (e: FormEvent) => {
    e.stopPropagation();
    getFormValues(
      formsLatest.current,
      (form: AnyObject) => {
        dispatch(setForm(form));
      },
      async (values: AnyObject) => {
        if (values.scheduledend) {
          showOverlay();

          uploadDate(convertUTCDateToLocalDate(values.scheduledend), {
            regardingobjectid: {
              attributeTypeCode: crmDataTypes.Lookup,
              attributeValue: { id: row.regardingobjectid_id }
            },
            uds_aufgabentyp_fuhrpark: {
              attributeTypeCode: crmDataTypes.Picklist,
              attributeValue: { value: row.uds_aufgabentyp_fuhrpark_value }
            },
            activityid: {
              attributeTypeCode: crmDataTypes.Uniqueidentifier,
              attributeValue: row.activityid
            },
            statuscode: 2
          })
            .then(() => {
              setSnackbarState({ message: 'Änderungen wurden übernommen.', open: true, type: 'success' });
            })
            .catch(err => {
              console.log(err);
              setSnackbarState({ message: 'Änderungen wurden nicht übernommen.', open: true, type: 'error' });
            })
            .finally(() => {
              hideOverlay();
              setIsConfirmationButtonsOpen(false);
              queryClient.resetQueries([PAGES.SERVICE_PROVIDER_TASKS]);
            });
        }
      }
    );
  };

  const toggleConfirmation = (e: FormEvent) => {
    e.stopPropagation();
    setIsConfirmationButtonsOpen(!isConfirmationButtonsOpen);
  };

  return row.statecode_value === STATECODE.open ? (
    <div className='w-100'>
      {isConfirmationButtonsOpen ? (
        <Flex align='flex-end' direction='column' id={`${row.activityid.slice(2, 10)}_3333`}>
          {' '}
          {/* ID for Usetiful*/}
          <StyledForm name={`task${row.activityid}`} formFields={dienstleisteraufgabenFormFields} />
          <Flex top='20' justify='flex-end'>
            <Button onClick={toggleConfirmation} secondary>
              Abbrechen
            </Button>
            <Button onClick={submitTask} left='10' id={`${row.activityid.slice(6, 12)}_1111`}>
              {' '}
              {/* ID for Usetiful*/}
              Speichern
            </Button>
          </Flex>
        </Flex>
      ) : (
        <Flex justify='flex-end'>
          <StyledButton onClick={toggleConfirmation} id={`${row.activityid.slice(2, 10)}_2222`}>
            {' '}
            {/* ID for Usetiful*/}
            <Flex align='center'>
              <CircleCheckMark width={15} height={15} />
              <Text as='span' color='white' left='10'>
                Erledigen
              </Text>
            </Flex>
          </StyledButton>
        </Flex>
      )}
    </div>
  ) : null;
};

export default TaskHandler;
