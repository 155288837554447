import { AnyObject } from 'common/interfaces';
import { QueryHookOptions } from 'hooks/react-query/react-query.props';

export enum DamageServiceId {
  Breakdown = 'cc41aa2f-9842-e611-80ea-5065f38be311',
  AccidentDamage = 'd1cbb93b-9842-e611-80ea-5065f38be311',
  DamageReportBasic = '1f935de1-ba32-ee11-bdf4-000d3adf72e3', //Schadenmelden Basic
  DamageReport = 'f5034000-4d90-e611-80e3-c4346bad60a8' //Schadenmelden
}

export interface IDamageRecordingModel {
  uds_isreparaturnotwendigkeit: boolean;
  uds_versicherernum: string;
  uds_versicherername: string;
  uds_versichererid_id: string;
  uds_versichererid: string;
  uds_accident_mileage: number;
  uds_unfallverursacher_value: number;
  uds_anzeige_erstattet_option_value: number;
  uds_wert_drogentestoption_value: number;
  uds_wert_drogentestoption2_value: number;
  uds_schadenort_postalcode: string;
  uds_schadenort_city: string;
  uds_schadenort_land: string;
  uds_schadenort_street: string;
  uds_teile1: string;
  uds_unfallverursacher: string;
  uds_artdesschadens: string;
  uds_artdesschadens_value: number;
  uds_typfahrt: boolean;
  uds_uz1_schaden_unfallzeugen: boolean;
  uds_unfallskizzevorhanden: boolean;
  uds_isschadengroser: boolean;
  uds_schadenuhrzeit: string;
  uds_ass_besichtigungsort_unfallfahrzeug_firma: string;
  uds_ass_besichtigungsort_strasse: string;
  uds_ass_besichtigungsort_telefon_ansprechpart: string;
  uds_fahrer_des_kundenfahrzeugs_option: string;
  uds_fahrer_des_kundenfahrzeugs_option_value: number;
  uds_description: string;
  uds_driving_license_was_valid: boolean;
  uds_driver_name: string;
  uds_driving_license_place_of_issue: string;
  uds_driver_mobile: string;
  uds_driving_license_number: string;
  uds_driver_emailaddress: string;
  uds_driving_license_date: string;
  uds_driving_license_class: string;
  uds_tierschaden_meldung_behoerde: boolean;
  uds_tierschaden_meldung_behoerde_bescheinigun: boolean;
  uds_tierart: string;
  uds_was_wurde_gestohlen: string;
  uds_liegen_alle_schluessel_vor: boolean;
  uds_absicherung_diebstahl: string;
  uds_wo_sind_die_fahrzeugschluessel: string;
  uds_gibt_hinweise_auf_taeter_option: boolean;
  uds_gibt_hinweise_auf_taeter_option_value: number;
  uds_hinweise_auf_taeter: string;
  uds_polizauf: boolean;
  uds_polizeidienststelle: string;
  uds_alkoholtest_bei_wem: string;
  uds_dienststelle_adresse: string;
  uds_wert_alkoholtest: string;
  uds_polizeilichesaktenzeichen: string;
  uds_polizeibericht_vorhanden: boolean;
  uds_alkoholtest_bei_wem2: string;
  uds_wert_alkoholtest2: string;
  uds_verwarung: boolean;
  uds_drogentest: boolean;
  uds_verwarnungan: string;
  uds_drogentest_bei_wem: string;
  uds_anzeige_erstattet_option: boolean;
  uds_wert_drogentestoption: boolean;
  uds_drogentest_bei_wem2: string;
  uds_wert_drogentestoption2: string;
  uds_alkoholtest: boolean;
  uds_ubkennzeichen2: string;
  uds_ubkennzeichen4: string;
  uds_ubkennzeichen5: string;
  uds_kennzeichenunbekannt: boolean;
  uds_bp2_kennzeichenunbekannt: boolean;
  uds_bp3_kennzeichenunbekannt: boolean;
  uds_bp1_wurde_fahrzeug_beschadigt: boolean;
  uds_bp2_wurde_fahrzeug_beschadigt: boolean;
  uds_bp3_wurde_fahrzeug_beschadigt: boolean;
  uds_ub1_sachschaden: boolean;
  uds_ub3_sachschaden: boolean;
  uds_bp3_sachschaden: boolean;
  uds_ub1_was_beschaedigt_sachschaden: string;
  uds_ub3_was_beschaedigt_sachschaden: string;
  uds_bp3_was_beschaedigt_sachschaden: string;
  uds_bp1_fahrzeug_hersteller: string;
  uds_bp2_fahrzeug_hersteller: string;
  uds_bp3_fahrzeug_hersteller: string;
  uds_bp1_fahrzeug_typ: string;
  uds_bp2_fahrzeug_typ: string;
  uds_bp3_fahrzeug_typ: string;
  uds_ubversichennum1: string;
  uds_ubversichennum3: string;
  uds_bp3_ubversichennum: string;
  uds_ubtelefon2: string;
  uds_ubtelefon4: string;
  uds_bp3_telefon: string;
  uds_schaden_personenschaeden: boolean;
  uds_bp2_schaden_personenschaeden: boolean;
  uds_bp3_schaden_personenschaeden: boolean;
  uds_bp1_adresse: string;
  uds_bp2_adresse: string;
  uds_bp3_adresse: string;
  uds_ubversicherung1: string;
  uds_bp3_ubversicherung: string;
  uds_ubversicherung3: string;
  uds_ubversicherung1_unbekannt: boolean;
  uds_bp3_ubversicherung_unbekannt: boolean;
  uds_ubversicherung3_unbekannt: boolean;
  uds_bp1_name: string;
  uds_bp2_name: string;
  uds_bp3_name: string;
  uds_unfallgegner1_personliche_daten: boolean;
  uds_unfallgegner2_personliche_daten: boolean;
  uds_bp3_unfallgegner_personliche_daten: boolean;
  uds_ubtelefon1: string;
  uds_ubtelefon3: string;
  uds_fh_telefon3: string;
  uds_ubname1_schaediger: boolean;
  uds_ubname3_schaediger: boolean;
  uds_is_ubname4_schaediger: boolean;
  uds_fh_name1: string;
  uds_fh_name2: string;
  uds_fh_name3: string;
  uds_fh_anschrift1: string;
  uds_fh_anschrift2: string;
  uds_fh_anschrift3: string;
  uds_fahrbereit: boolean;
  uds_driver_street: string;
  uds_driver_postcode: string;
  uds_driver_city: string;
  uds_warning_saidcode: string;
  uds_warning_saidcode_value: number;
}

export interface IIncidentModel {
  new_ainame1: string;
  incidentid: string;
  title: string;
  uds_serviceid: string;
  uds_serviceid_id: DamageServiceId;
  uds_schadenaufnahmeid: string;
  uds_schadenaufnahmeid_id: string;
  new_casetype: string;
  new_casetype_value: number;
  new_kennzeichen: string;
  new_kennzeichen_id: string;
  new_schadenuhrzeit: string;
  subjectid: string;
  new_schadennummer: string;
  new_schaden_personenschaeden: string;
  uds_needrepair: string;
  uds_needrepair_value: number;
  new_fahrzeuglenker_unfallverursacher: string;
  new_fahrzeuglenker_unfallverursacher_value: string;
  new_schaden_unfallzeugen: string;
  new_typfahrt: string;
  description: string;
  new_kmstand: string;
  new_unfallort: string;
  new_fahrerid: string;
  new_fahrerid_id: string;
  new_aistrase: string;
  new_aistrase2: string;
  new_aitelefon2: string;
  new_aiort2: string;
  new_sparteid: string;
  new_sparteid_id: string;
  new_aistrase1: string;
  new_eigentmerid: string;
  new_eigentmerid_id: string;
  new_aiort1: string;
  new_halter2_name: string;
  new_aitelefon1: string;
  new_insuarancecompanyid: string;
  new_insuarancecompanyid_id: string;
  new_versichermakler: string;
  new_versichermakler_id: string;
  new_vsn: string;
  new_schadennummerkh: string;
  new_sb_vollkasko: string;
  new_sb_teilkasko: string;
  new_sb_haftpflicht: string;
  new_polizeigerufen: string;
  new_polizauf: string;
  new_verwarung: string;
  new_verwarnungan: string;
  new_verwarnungbugeld: string;
  new_anzeigeerstattet: string;
  new_polizeilichesaktenzeichen: string;
  new_polizst: string;
  new_alkoholtest: string;
  new_alkoholtest_value: string;
  new_alkoholtest_bei_wem: string;
  new_wertalkoholtest: string;
  new_drogentest: string;
  new_repairpartnerid: string;
  new_repairpartnerid_id: string;
  new_unfallschaden_steuern_vorgabetermin: string;
  new_teile: string;
  new_reparaturpartner_mietwagen: string;
  new_reparaturpartner_mietwagen_value: string;
  new_reparaturpartner_mietwagen_textfeld: string;
  new_reparaturpartner_navi_mietwagen: string;
  new_reparaturpartner_mietwagen_automatik: string;
  new_reparaturpartner_mietwagen_fse: string;
  uds_ansprechpartner_contactid: string;
  new_abholzeitfenster: string;
  new_abholort: string;
  new_hinweis_zum_abholort: string;
  uds_ansprechpartnerphone: string;
  new_ansprechpartnerabholung: string;
  ownerid: string;
  ownerid_id: string;
  new_schaden_unfallbeteiligte: string;
  new_entstand1: string;
  new_ubversichennum1: string;
  new_ubname1_schaediger: string;
  new_teile1: string;
  new_ubname1: string;
  new_ubtelefon1: string;
  new_ubversicherung1: string;
  new_ubsrtase1: string;
  new_ubort1: string;
  new_ubname2: string;
  new_ubkennzeichen2: string;
  new_ubmodell2: string;
  new_ubstrase2: string;
  new_ubort2: string;
  new_ubtelefon2: string;
  new_entstand2: string;
  new_ubversichernum3: string;
  new_ubname3_schaediger: string;
  new_teile2: string;
  new_ubname3: string;
  new_ubtelefon3: string;
  new_ubversicherung3: string;
  new_ubstrase3: string;
  new_ubort3: string;
  new_ubname4: string;
  new_ubstrase4: string;
  new_ubort4: string;
  new_ubkennzeichen4: string;
  new_ubmodell4: string;
  new_ubtelefon4: string;
  new_psname1: string;
  new_pstelefon1: string;
  new_psstrase1: string;
  new_psort1: string;
  new_psname2: string;
  new_pstelefon2: string;
  new_psstrase2: string;
  new_psort2: string;
  new_uzname1: string;
  new_uztelefon1: string;
  new_uzstrasenr1: string;
  new_uzort1: string;
  new_uzname2: string;
  new_uztelefon2: string;
  new_uzstrasenr2: string;
  new_uzort2: string;
  new_panne_pannegemeldetdurch: string;
  new_telefonnummer_textfeld: string;
  new_emailadresse_textfeld: string;
  new_panne_grundderpanne: string;
  new_fahrbereit: string;
  uds_geplanter_fertigstellungstermin_werkstatt: string;
  uds_pickupcardatetime: string;
  uds_besichtigungstermin: string;
  new_panne_getriebe: string;
  uds_panne_treibstoff: string;
  new_panne_waswurdevereinbart: string;
  new_panne_zielort: string;
  new_panne_nameansprechpartner: string;
  uds_assistanceid: string;
  uds_assistanceid_id: string;
  new_assistanceansprechpartner: string;
  uds_assistancephonenumber: string;
  uds_assistancevorgangsnummer: string;
  'new_repairpartner.address1_composite': string;
  'new_repairpartner.emailaddress1': string;
  'new_repairpartner.telephone1': string;
  uds_uzdatenunbekannt1: boolean;
  uds_uzdatenunbekannt2: boolean;
  uds_uzemail1: string;
  uds_uzemail2: string;
  uds_uzname1: string;
  uds_uzname2: string;
  uds_uzanschrift1: string;
  uds_uzanschrift2: string;
  uds_uztelefon1: string;
  uds_uztelefon2: string;
  uds_schaden_unfallbeteiligte: boolean;
  uds_schaden_unfallbeteiligte2: boolean;
  uds_schaden_unfallbeteiligte3: boolean;
  uds_repairpartner_name: string;
  uds_repairpartner_email: string;
  uds_repairpartner_address: string;
  uds_repairpartner_telephone: string;
}

export interface IDamagePartsModelItem {
  uds_damagedpartid: string;
  uds_damagetypecode: string;
  uds_damagetypecode_value: number;
  uds_parttypecode: string;
  uds_parttypecode_value: number;
}

interface ICoodrinates {
  latitude: string;
  longitude: string;
}

export interface IFleetModel {
  new_leasinggeber: string;
  new_leasinggeber_id: string;
  new_lvn: string;
  statecode: string;
  statecode_value: number;
}

export interface IGetByIdResponse {
  isSelfManagedClaimsProduct: boolean;
  fuhrparkModel: AnyObject;
  incidentModel: AnyObject;
  schadenAufnahmeModel: AnyObject;
  coodrinates: ICoodrinates;
  damagePartsModel: AnyObject[];
}

export type IGetByIdData = Partial<
  {
    isDamageRecordingIsExist: boolean;
  } & IIncidentModel &
    IDamageRecordingModel & {
      fleetModel: IFleetModel;
      damagePartsModel: IDamagePartsModelItem[];
      coodrinates: ICoodrinates;
    }
>;

export type GetDamageOptionsType = QueryHookOptions<IGetByIdData, IGetByIdResponse>;
