import { ReactElement } from 'react';

export interface SnackbarComponentProps {
  type: SnackbarComponentTypes;
  open: boolean;
  component: ReactElement | null;
}

export interface SnackbarState {
  backgroundColor: string;
  bottomBorderColor: string;
  image: ReactElement;
}

export enum SnackbarComponentTypes {
  Success = 'success'
}
