import { API_ENDPOINT } from 'endpoints';
import authService from './auth-service';
import axios from 'axios';

type FuelReportFiltersPayload = {
  searchValue: string;
  columnName: string;
};

export const getFuelReportService = async (data: FuelReportFiltersPayload) => {
  const token = await authService.acquireTokenAsync();

  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/reports/GetFuelReportFilterData`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data
  });
};
