import { PAGES, ReactQueryKeys } from 'common/enums';
import { ResponseModel } from 'common/interfaces';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import { useQuery } from 'react-query';
import { documentTypeRequestConfig } from 'request-config/document-type/document-type.request-config';
import { AxiosResponse } from 'axios';
import { GetDocumentTypeByApPageResponse } from './document-type-by-app.props';
import { QueryHookOptions } from 'hooks/react-query/react-query.props';

export const useDocumentTypeByAppQuery = (page: PAGES, options: QueryHookOptions = {}, enabled: boolean = true) => {
  const { fetch } = useFetch({
    loadingKey: 'documentTypeByApp',
    ...options.fetch,
    ...documentTypeRequestConfig.getDocumentTypeByApPage(page)
  });

  const { data: queryData, ...restQueries } = useQuery<AxiosResponse<ResponseModel<GetDocumentTypeByApPageResponse>>>(
    [ReactQueryKeys.DocumentTypeByApp, page],
    () => fetch(),
    { enabled: !!page && enabled }
  );

  return { documentTypeByAppData: queryData?.data, ...restQueries };
};
