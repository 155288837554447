import React, { useState } from 'react';
import classes from './meine-fahrer.module.scss';
import { ClearAllFiltersButton, Filter, FilterButton, FilterContainer, FilterInput } from '../../../filter-items';
import { FahrerFiltersProps, FilterItemProps } from 'components/meine-fahrer-component/meine-fahrer-component.props';
import { Location } from 'components/meine-fahrer-component/meine-fahrer-component-view.props';

export const DriverFilters = ({ sessionFilter, setSessionFilter, filterData, goToFirstPage }: FahrerFiltersProps) => {
  const [isOpen, setOpen] = useState<string | null>('');

  const filterCompanies = filterData?.companies?.map((company: FilterItemProps) => company.value) || [];
  const filterCarPolicy = filterData?.carpolicies?.map((policy: FilterItemProps) => policy.value) || [];
  const filterMobilityBudget = filterData?.mobilitasbudgets?.map((budgets: FilterItemProps) => budgets.value) || [];
  const filterCostCenter = filterData?.costcenters?.map((costCenter: FilterItemProps) => costCenter.value) || [];
  const filterPoolCarClass = filterData?.classes?.map((poolCarClass: FilterItemProps) => poolCarClass.value) || [];

  const saveFilter = (title: string, serverValue: string | null, clientValue: string | null) => {
    setSessionFilter(
      { [convertHeaderToCrmName(title)]: serverValue },
      { [convertHeaderToCrmName(title)]: clientValue }
    );
  };

  const companyLabel = sessionFilter?.filterForClient[CRM_VALUE.company];
  const carPolicyLabel = sessionFilter?.filterForClient[CRM_VALUE.carPolicy];
  const mobilityBudgetLabel = sessionFilter?.filterForClient[CRM_VALUE.mobilityBudget];
  const fullNameLabel = sessionFilter?.filterForClient[CRM_VALUE.name];
  const carLabel = sessionFilter?.filterForClient[CRM_VALUE.car];
  const statusLabel = sessionFilter?.filterForClient[CRM_VALUE.status];
  const locationLabel = sessionFilter?.filterForClient[CRM_VALUE.location];
  const costCenterLabel = sessionFilter?.filterForClient[CRM_VALUE.costCenter];
  const poolCarClassLabel = sessionFilter?.filterForClient[CRM_VALUE.poolCarClass];

  const toggleFilterIsOpen = (title = null) => {
    setOpen(title);
  };

  const clearFilter = (title = '') => {
    toggleFilterIsOpen();
    goToFirstPage();

    if (!title) {
      setSessionFilter(EMPTY_FILTER_STATE, EMPTY_FILTER_STATE);
      return;
    }

    saveFilter(title, null, null);
  };

  const convertHeaderToCrmName = (title = '') => {
    switch (title.toLowerCase()) {
      case 'name':
        return CRM_VALUE.name;

      case 'unternehmen':
        return CRM_VALUE.company;

      case 'car-policy':
        return CRM_VALUE.carPolicy;

      case 'mobilitätsbudget':
        return CRM_VALUE.mobilityBudget;

      case 'fahrzeug':
        return CRM_VALUE.car;

      case 'status':
        return CRM_VALUE.status;

      case 'standort':
        return CRM_VALUE.location;

      case 'kostenstelle':
        return CRM_VALUE.costCenter;

      case 'pool-car klasse':
        return CRM_VALUE.poolCarClass;

      default:
        return '';
    }
  };

  const handleInput = (title: string, value: string) => {
    toggleFilterIsOpen();
    goToFirstPage();

    if (convertHeaderToCrmName(title) === CRM_VALUE.name) {
      const fullName = filterData?.fullnames.find((name: string) => name.toLowerCase() === value.toLowerCase());
      if (fullName) {
        saveFilter(title, fullName, fullName);
      }
      return;
    }

    if (convertHeaderToCrmName(title) === CRM_VALUE.company) {
      const company = filterData?.companies?.find((companyItem: FilterItemProps) => companyItem.value === value);
      if (company) {
        saveFilter(title, company.id, company.value);
      }
      return;
    }

    if (convertHeaderToCrmName(title) === CRM_VALUE.location) {
      //@ts-ignore
      const location = filterData?.standorts?.find((location: Location) => location.name === value.name);
      if (location) {
        saveFilter(title, location.id, location.name);
      }
      return;
    }

    if (convertHeaderToCrmName(title) === CRM_VALUE.carPolicy) {
      const carPolicy = filterData?.carpolicies?.find((carItem: FilterItemProps) => carItem.value === value);
      if (carPolicy) {
        saveFilter(title, carPolicy.id, carPolicy.value);
      }
      return;
    }

    if (convertHeaderToCrmName(title) === CRM_VALUE.mobilityBudget) {
      const mobilityBudget = filterData?.mobilitasbudgets?.find(
        (budgetItem: FilterItemProps) => budgetItem.value === value
      );
      if (mobilityBudget) {
        saveFilter(title, mobilityBudget.id, mobilityBudget.value);
      }
      return;
    }

    if (convertHeaderToCrmName(title) === CRM_VALUE.car) {
      const car = filterData?.maincars?.find((car: string) => car.toLowerCase() === value.toLowerCase());
      if (car) {
        saveFilter(title, car, car);
      }
      return;
    }

    if (convertHeaderToCrmName(title) === CRM_VALUE.costCenter) {
      const costCenter = filterData?.costcenters?.find((costCenter: FilterItemProps) => costCenter.value === value);

      if (costCenter) {
        saveFilter(title, costCenter.id, costCenter.value);
      }
      return;
    }

    if (convertHeaderToCrmName(title) === CRM_VALUE.poolCarClass) {
      const poolCarClass = filterData?.classes?.find((poolCarClass: FilterItemProps) => poolCarClass.value === value);

      if (poolCarClass) {
        saveFilter(title, poolCarClass.id, poolCarClass.value);
      }
      return;
    }

    if (convertHeaderToCrmName(title) === CRM_VALUE.status) {
      const status = filterData?.contactstatus.find((status: string) => status.toLowerCase() === value.toLowerCase());

      if (status) {
        saveFilter(title, value, value);
      }
      return;
    }
    saveFilter(title, value, value);
  };

  const companyWithAdress = (item: Location) => {
    return (
      <div className={classes.location_container}>
        <p className={classes.location_name}>{item.name}</p>
        <div>
          <span className={classes.location_info}>{`${item.postalcode ? item.postalcode + ',' : ''} ${
            item.city
          }`}</span>
        </div>
      </div>
    );
  };

  return (
    <section className={classes.filters}>
      <Filter
        title='Name'
        isOpen={isOpen}
        value={fullNameLabel}
        toggleFilterIsOpen={toggleFilterIsOpen}
        clearFilter={clearFilter}
      >
        <FilterContainer header='Name' onClose={toggleFilterIsOpen}>
          <FilterInput
            title='Name'
            handleInput={handleInput}
            dropListData={filterData?.fullnames || []}
            withoutSearchButton
          />
        </FilterContainer>
      </Filter>
      <Filter
        title='Unternehmen'
        isOpen={isOpen}
        value={companyLabel}
        toggleFilterIsOpen={toggleFilterIsOpen}
        clearFilter={clearFilter}
      >
        <FilterContainer header='Unternehmen' onClose={toggleFilterIsOpen}>
          <FilterInput
            title='Unternehmen'
            handleInput={handleInput}
            dropListData={filterCompanies}
            withoutSearchButton
          />
        </FilterContainer>
      </Filter>
      <Filter
        title={'Standort'}
        isOpen={isOpen}
        value={locationLabel}
        toggleFilterIsOpen={toggleFilterIsOpen}
        clearFilter={clearFilter}
      >
        <FilterContainer
          header='Standort'
          onClose={() => {
            toggleFilterIsOpen();
          }}
        >
          <FilterInput
            title='Standort'
            handleInput={handleInput}
            dropListData={filterData.standorts}
            dropListDataWithId
            withoutSearchButton
            //@ts-ignore
            customDroplistItem={companyWithAdress}
          />
        </FilterContainer>
      </Filter>
      <Filter
        title='Car-Policy'
        isOpen={isOpen}
        value={carPolicyLabel}
        toggleFilterIsOpen={toggleFilterIsOpen}
        clearFilter={clearFilter}
      >
        <FilterContainer header='Car-Policy' onClose={toggleFilterIsOpen}>
          {filterCarPolicy.map((policy: string, indx: number) => {
            if (indx === 0) {
              return (
                <FilterButton handleInput={handleInput} title='Car-Policy' label={policy} value={policy} key={indx} />
              );
            }
            if (indx === 1) {
              return (
                <FilterButton handleInput={handleInput} title='Car-Policy' label={policy} value={policy} key={indx} />
              );
            }
          })}
          <FilterInput
            title='Car-Policy'
            handleInput={handleInput}
            dropListData={filterCarPolicy}
            withoutSearchButton
          />
        </FilterContainer>
      </Filter>
      <Filter
        title='Mobilitätsbudget'
        isOpen={isOpen}
        value={mobilityBudgetLabel}
        toggleFilterIsOpen={toggleFilterIsOpen}
        clearFilter={clearFilter}
      >
        <FilterContainer header='Mobilitätsbudget' onClose={toggleFilterIsOpen}>
          {filterMobilityBudget.map((budget: string, indx: number) => {
            if (indx === 0) {
              return (
                <FilterButton
                  handleInput={handleInput}
                  title='Mobilitätsbudget'
                  label={budget}
                  value={budget}
                  key={indx}
                />
              );
            }
            if (indx === 1) {
              return (
                <FilterButton
                  handleInput={handleInput}
                  title='Mobilitätsbudget'
                  label={budget}
                  value={budget}
                  key={indx}
                />
              );
            }
          })}
          <FilterInput
            handleInput={handleInput}
            title='Mobilitätsbudget'
            dropListData={filterMobilityBudget}
            withoutSearchButton
          />
        </FilterContainer>
      </Filter>

      <Filter
        value={costCenterLabel}
        title='Kostenstelle'
        isOpen={isOpen}
        toggleFilterIsOpen={setOpen}
        clearFilter={clearFilter}
      >
        <FilterContainer header='Kostenstelle' onClose={() => setOpen('')}>
          <FilterInput
            title='Kostenstelle'
            handleInput={handleInput}
            dropListData={filterCostCenter}
            withoutSearchButton
          />
        </FilterContainer>
      </Filter>

      <Filter
        value={poolCarClassLabel}
        title='Pool-Car Klasse'
        isOpen={isOpen}
        toggleFilterIsOpen={setOpen}
        clearFilter={clearFilter}
      >
        <FilterContainer header='Pool-Car Klasse' onClose={() => setOpen('')}>
          <FilterInput
            title='Pool-Car Klasse'
            handleInput={handleInput}
            dropListData={filterPoolCarClass}
            withoutSearchButton
          />
        </FilterContainer>
      </Filter>

      <Filter
        title='Fahrzeug'
        isOpen={isOpen}
        value={carLabel}
        toggleFilterIsOpen={toggleFilterIsOpen}
        clearFilter={clearFilter}
      >
        <FilterContainer header='Fahrzeug' onClose={toggleFilterIsOpen}>
          <FilterInput
            handleInput={handleInput}
            title='Fahrzeug'
            dropListData={filterData?.maincars || []}
            withoutSearchButton
          />
        </FilterContainer>
      </Filter>
      <Filter
        title='Status'
        isOpen={isOpen}
        value={statusLabel}
        toggleFilterIsOpen={toggleFilterIsOpen}
        clearFilter={clearFilter}
      >
        <FilterContainer
          header='Status'
          onClose={toggleFilterIsOpen}
          isNothingFound={!(filterData.contactstatus && filterData.contactstatus.length)}
        >
          {filterData.contactstatus &&
            filterData.contactstatus.map((statecode: string) => {
              return (
                <FilterButton
                  key={statecode}
                  handleInput={handleInput}
                  title='Status'
                  label={statecode}
                  value={statecode}
                />
              );
            })}
        </FilterContainer>
      </Filter>
      <ClearAllFiltersButton filterState={sessionFilter.filterForClient || {}} clearFilter={clearFilter} />
    </section>
  );
};

export const CRM_VALUE = {
  name: 'name',
  company: 'parentcustomerid',
  carPolicy: 'carpolicyid',
  mobilityBudget: 'carpolicynutzerid',
  car: 'maincar',
  status: 'contactstatus',
  location: 'standortId',
  costCenter: 'costCenter',
  poolCarClass: 'classId'
};

export const EMPTY_FILTER_STATE = {
  [CRM_VALUE.name]: null,
  [CRM_VALUE.company]: null,
  [CRM_VALUE.carPolicy]: null,
  [CRM_VALUE.mobilityBudget]: null,
  [CRM_VALUE.car]: null,
  [CRM_VALUE.status]: null,
  [CRM_VALUE.location]: null,
  [CRM_VALUE.costCenter]: null,
  [CRM_VALUE.poolCarClass]: null
};
