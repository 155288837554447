import { useFleetDataQualityQuery } from 'hooks/react-query/fleet/get-data-quality/get-data-quality.query';
import { QualitySectionProps } from '../components/quality-section/quality-section.props';
import { FleetDataQualityAttributesResponse } from 'hooks/react-query/fleet/get-data-quality/get-data-quality.props';
import { useDispatch } from 'react-redux';
import { setVehicleDetailsLeasingTab, setVehicleDetailsMainTab } from 'actions/pages/vehicle-details.action';
import { useVehicleDetailsMainTabsData } from 'pages/existing-vehicle-page/components/layout/main-tabs/hooks/use-main-tabs/use-main-tabs';
import { ContractTypeValue } from 'pages/existing-vehicle-page/components/layout/main-tabs/components/tab-panels/leasing/leasing-section/leasing-section.props';
import { VEHICLE_DETAIL_ELEMENTS_ID } from 'pages/existing-vehicle-page/utils';
import { setHighlightedForm } from 'actions/app_action';
import { useFleetByIdQuery } from 'hooks/react-query/fleet/use-get-by-id/use-get-by-id';
import { LeasingPurchaseValue } from 'hooks/react-query/fleet/use-get-by-id/use-get-by-id.props';
import { ORGANIZATION_FORM_FIELDS } from 'pages/existing-vehicle-page/components/layout/main-tabs/components/tab-panels/organization/hooks/use-form-info-list-data-organization';
import { useIsShowDataQualityWidget } from './use-is-show-data-quality-widget';

export const useQualitySectionList = () => {
  const { isShowDataQualityWidget } = useIsShowDataQualityWidget();

  const { fleetDataQualityQuery } = useFleetDataQualityQuery(undefined, {
    options: { enabled: isShowDataQualityWidget }
  });
  const { fleetByIdQuery } = useFleetByIdQuery();
  const { fuhrpark } = fleetByIdQuery;

  const { getTabIndex } = useVehicleDetailsMainTabsData();

  const dispatch = useDispatch();

  const getTooltip = (qualityKey: keyof FleetDataQualityAttributesResponse, favor: string, buttonName: string) => {
    if (fleetDataQualityQuery?.[qualityKey]) {
      return;
    }
    return `Es ist keine Leistung “${favor}” zugewiesen. Bitte füge es dem Fahrzeug hinzu. Drücke dazu den Button “${buttonName}”. Diese findest du auch unter dem Abschnitt Leasing & Leistungen.`;
  };

  const openLeasingContract = (
    qualityKey: keyof FleetDataQualityAttributesResponse,
    contract: ContractTypeValue,
    highlightedFormFields: string[]
  ) => {
    dispatch(setVehicleDetailsMainTab({ activeIndex: getTabIndex('leasing') }));
    dispatch(setVehicleDetailsLeasingTab({ openedContract: contract }));

    const leasingElement = document.getElementById(VEHICLE_DETAIL_ELEMENTS_ID.tab.mainTab);
    const sectionNode = document.querySelector(`[data-contract-type-id="${contract}"]`);

    leasingElement?.scrollIntoView();
    setTimeout(() => sectionNode?.scrollIntoView(), 10);

    if (typeof fleetDataQualityQuery[qualityKey] === 'number') {
      dispatch(setHighlightedForm({ fields: highlightedFormFields }));
    }
  };

  const openHighlightVehicleOverviewForm = () => {
    dispatch(setHighlightedForm({ fields: HIGHLIGHTED_FIELDS.vehicleOverview }));

    if (!fuhrpark?.[ORGANIZATION_FORM_FIELDS.company]) {
      dispatch(setVehicleDetailsMainTab({ activeIndex: getTabIndex('organization') }));
    }
  };

  const qualitySectionList: QualitySectionProps[] = [
    {
      label: 'Fahrzeugübersicht',
      filled: fleetDataQualityQuery?.uds_filledfields_vehicle_overview_count,
      amount: fleetDataQualityQuery?.uds_allfields_vehicle_overview_count,
      onClick: openHighlightVehicleOverviewForm
    },
    {
      label: 'Leistung Versicherung',
      filled: fleetDataQualityQuery?.uds_filledfields_insurance_count,
      amount: fleetDataQualityQuery?.uds_allfields_insurance_count,
      tooltip: getTooltip('uds_filledfields_insurance_count', 'Versicherung', 'Leistung Versicherung'),
      onClick: () =>
        openLeasingContract(
          'uds_filledfields_insurance_count',
          ContractTypeValue.Insurance,
          HIGHLIGHTED_FIELDS.leasing.insurance
        )
    },
    {
      label: 'Leistung Winterräder',
      filled: fleetDataQualityQuery?.uds_filledfields_winter_tires_count,
      amount: fleetDataQualityQuery?.uds_allfields_winter_tires_count,
      tooltip: getTooltip('uds_filledfields_winter_tires_count', 'Winterräder', 'Leistung Winterräder'),
      onClick: () =>
        openLeasingContract(
          'uds_filledfields_winter_tires_count',
          ContractTypeValue.WinterWheels,
          HIGHLIGHTED_FIELDS.leasing.winterWheels
        )
    },
    {
      label: 'Leistung Reifenersatz',
      filled: fleetDataQualityQuery?.uds_filledfields_tire_replacement_count,
      amount: fleetDataQualityQuery?.uds_allfields_tire_replacement_count,
      tooltip: getTooltip('uds_filledfields_tire_replacement_count', 'Reifenersatz', 'Leistung Reifenersatz'),
      onClick: () =>
        openLeasingContract(
          'uds_filledfields_tire_replacement_count',
          ContractTypeValue.TireReplacement,
          HIGHLIGHTED_FIELDS.leasing.tireReplacement
        )
    },
    {
      label: 'Leistung Finanzrate',
      isHide: fuhrpark?.new_kauf_leasing_value !== LeasingPurchaseValue.Leasing,
      filled: fleetDataQualityQuery?.uds_filledfields_finanzrate_count,
      amount: fleetDataQualityQuery?.uds_allfields_finanzrate_count,
      tooltip: getTooltip('uds_filledfields_finanzrate_count', 'Finanzrate', 'Leistung Finanzrate'),
      onClick: () =>
        openLeasingContract(
          'uds_filledfields_finanzrate_count',
          ContractTypeValue.FinancialRate,
          HIGHLIGHTED_FIELDS.leasing.financialRate
        )
    }
  ];

  const displayNumberOfQualitySectionList = qualitySectionList.filter(item => !item.isHide).length;

  return { qualitySectionList, displayNumberOfQualitySectionList };
};

const HIGHLIGHTED_FIELDS = {
  vehicleOverview: ['unternehmen', 'kennzeichen', 'fahrgestellnummer', 'erstzulassung', 'kauf/leasing', 'eigentümer'],
  leasing: {
    insurance: [
      'uds_leasingbank',
      'uds_versicherungsartcode',
      'selbstbeteiligungvollkasko',
      'selbstbeteiligungteilkasko',
      'werkstättenbindung',
      'uds_vertragnr'
    ],
    winterWheels: ['uds_leasingbank', 'inkludiert'],
    tireReplacement: ['uds_leasingbank', 'inkludiert'],
    financialRate: [
      'uds_leasingbank',
      'leasingstart',
      'leasingende',
      'leasingdauer',
      'laufleistung',
      'laufleistungpa',
      'uds_vertragnr'
    ]
  }
};
