import authService from 'services/auth-service';
import axios, { AxiosResponse } from 'axios';
import { API_ENDPOINT } from '../constants';
import { ResponseModel } from 'common/interfaces';

export const getFahrzeugkostenTable = async (
  tableParams: any,
  filterForServer: any
): Promise<AxiosResponse<ResponseModel>> => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/fahrzuegkosten/GetFahrzeugkostenTable`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      ResultType: 1,
      ...tableParams,
      search: filterForServer
    }
  });
};

export const getFahrzeugkostenFilters = async (): Promise<AxiosResponse<ResponseModel>> => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/fahrzuegkosten/GetFahrzeugkostenTable`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      ResultType: 2
    }
  });
};

export const getFahrzuegkostenFile = async (invoiceid: string) => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/Fahrzuegkosten/GetFahrzuegkostenFile`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      invoiceid
    }
  });
};

export const getFileForExcel = async (params: any) => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/fahrzuegkosten/generateexcel`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: params
  });
};
