import React, { useState, useCallback, useEffect } from 'react';
import { Text, Flex, Button } from 'common/common-components.styled';
import { StyledSettingsWrapper, StyledItemsContainer, StyledDivider } from './columns-settings.styled';
import ColumnItem from './column-item/column-item';
import { RefreshArrow } from 'components/icons/refresh-arrow';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { AnyObject } from 'common/interfaces';
import PerfectScrollbar from 'react-perfect-scrollbar';

interface Item {
  title: string;
  propName: string;
  isChecked: boolean;
  columnId: string;
}

interface TempItems {
  defaultColumns: Item[];
  customColumns: Item[];
  closeAlert: () => void;
  saveSettings: (newColumns: Item[]) => void;
}

const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const ColumnsSettings = ({ defaultColumns, customColumns, closeAlert, saveSettings }: TempItems) => {
  const [columns, setColumns] = useState(customColumns);
  const [checkboxDisabled, setCheckboxDisabled] = useState(false);

  const toggleItem = useCallback(
    (item: Item) => {
      const newColumns = columns.map(column => {
        if (column.columnId === item.columnId) {
          return {
            ...item,
            isChecked: !column.isChecked
          };
        }

        return column;
      });

      setColumns(newColumns);
    },
    [columns]
  );

  const onDragEnd = useCallback(
    (result: AnyObject) => {
      if (!result.destination) {
        return;
      }

      if (result.destination.index === result.source.index) {
        return;
      }

      const newColumns = reorder(columns, result.source.index, result.destination.index);

      setColumns(newColumns);
    },
    [columns]
  );

  const reset = useCallback(() => {
    setColumns(defaultColumns);
  }, [defaultColumns]);

  useEffect(() => {
    const newColumns = columns.filter(column => column.isChecked);
    newColumns.length < 2 ? setCheckboxDisabled(true) : setCheckboxDisabled(false);
  }, [columns]);

  return (
    <StyledSettingsWrapper>
      <Text color='black' bottom='20'>
        Wähle aus, welche Spalten du angezeigt bekommen möchtest. Ziehe die Spalten, um die Reihenfolge zu ändern.
      </Text>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='list'>
          {provided => (
            <StyledItemsContainer ref={provided.innerRef} {...provided.droppableProps}>
              <PerfectScrollbar id='columnSettings'>
                {columns.map((column, index) => (
                  <ColumnItem
                    key={column.title}
                    title={column.title}
                    isChecked={column.isChecked}
                    onChange={() => toggleItem(column)}
                    index={index}
                    disableCheckbox={checkboxDisabled}
                  />
                ))}
                {provided.placeholder}
              </PerfectScrollbar>
            </StyledItemsContainer>
          )}
        </Droppable>
      </DragDropContext>
      <StyledDivider />
      <Flex justify='space-between' align='center' top='20'>
        <Flex onClick={reset} style={{ cursor: 'pointer' }}>
          <RefreshArrow />
          <Text color='blue' fontWeight={500} left='10'>
            Zurücksetzen
          </Text>
        </Flex>
        <Flex justify='flex-end'>
          <Button secondary right='10' onClick={closeAlert}>
            Abbrechen
          </Button>
          <Button onClick={() => saveSettings(columns)}>Speichern</Button>
        </Flex>
      </Flex>
    </StyledSettingsWrapper>
  );
};

export default ColumnsSettings;
