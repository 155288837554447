import Axios, { AxiosResponse } from 'axios';
import { API_ENDPOINT } from 'endpoints';
import authService from 'services/auth-service';
import { GetDamageRecordFormData, GetDamageRecordFormDataResponseItem } from './damage-service.props';
import { ResponseModel } from 'common/interfaces';

class DamageService {
  private RESOURCE = 'schaden';

  getDamageRecordFormData = async (
    data: GetDamageRecordFormData
  ): Promise<AxiosResponse<ResponseModel<GetDamageRecordFormDataResponseItem[]>>> => {
    const token = await authService.acquireTokenAsync();
    return Axios({
      method: 'POST',
      url: `${API_ENDPOINT}/api/${this.RESOURCE}/getSchadenAufnahmeFormData`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data
    });
  };
}

export const damageService = new DamageService();
