import authService from './auth-service';
import axios, { AxiosResponse } from 'axios';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export async function saveContactPreferences(
  ContactId: string,
  SettingsName: string,
  SettingsValue: string
): Promise<AxiosResponse> {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/PortalCustomizationService/SaveContactPreferences`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      ContactId,
      SettingsName,
      SettingsValue
    }
  });
}

export async function loadContactPreferences(
  ContactId: string,
  SettingsName: string
): Promise<AxiosResponse> {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/PortalCustomizationService/LoadContactPreferences`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      ContactId,
      SettingsName
    }
  });
}