import React from 'react';
import { VehicleStateCodeLabel, VehicleStatusesLabel } from '../../my-vehicles.props';
import {
  getLeaseEndHandleClick,
  getLeaseEndHiddenStatus,
  getLeaseEndStatus,
  getLeaseEndTooltip
} from '../getLeaseEndStatus';
import { IMenuItem } from '../my-vehicles-menu.props';
import { PAGES } from 'common/enums';
import { encodeBase64 } from 'utils/decode-encode-base64';
import { DamagedCarIcon } from 'components/icons-new/damaged-car';
import { PlumpLeftRightArrowIcon } from 'components/icons-new/plump-left-right-arrow';
import { SettingsIcon } from 'components/icons-new/settings';
import { CarPlumpLeftRightArrow } from 'components/icons-new/car-plump-left-right-arrow';
import { DocumentIcon } from 'components/icons-new/document';
import { useReportDamageCustomLink } from 'hooks/custom-links/use-report-damage';
import { useComponent } from 'hooks/use-component/use-component';

export const useLeftMenuSetup = () => {
  const { reportDamageCustomLink } = useReportDamageCustomLink();
  const { getIsDisplayComponent } = useComponent();

  const getMyVehiclesMenuSetupLeft = (
    carStatus: VehicleStatusesLabel | VehicleStateCodeLabel,
    isPoolManager: boolean,
    isAdminOrFleetManager: boolean,
    carNumber: string,
    showCreateDamage: boolean,
    leasingReturnEnabled?: boolean,
    phase?: number,
    openOfferConfirmationModal?: () => void,
    openLeasingContractModal?: () => void
  ): IMenuItem[] => [
    {
      name: showCreateDamage ? 'Schaden anlegen' : 'Schaden melden',
      href: showCreateDamage
        ? PAGES.CREATE_DAMAGE
        : `${reportDamageCustomLink}?data=${encodeBase64(JSON.stringify({ carNumber }))}`,
      icon: <DamagedCarIcon />,
      openInNewTab: !showCreateDamage,
      isEnabled: isAdminOrFleetManager || isPoolManager,
      hidden: showCreateDamage ? false : !getIsDisplayComponent('reportDamageButton')
    },
    {
      name: 'Fahrzeugüberführung',
      href:
        carStatus === VehicleStateCodeLabel.Inactive || isPoolManager ? undefined : PAGES.ASSIGNMENTS_VEHICLE_TRANSFER,
      icon: <PlumpLeftRightArrowIcon />,
      isEnabled: carStatus !== VehicleStateCodeLabel.Inactive && !isPoolManager,
      hidden: !getIsDisplayComponent('menuVehicleTransferButton')
    },
    {
      name: 'Unfallreparatur',
      href: isPoolManager ? undefined : PAGES.ASSIGNMENTS_ACCIDENT_REPAIR,
      icon: <SettingsIcon />,
      isEnabled: !isPoolManager,
      isShowModal: true
    },
    {
      name: 'Fahrzeugrückgabe',
      href: carStatus === VehicleStateCodeLabel.Inactive || isPoolManager ? undefined : PAGES.ASSIGNMENTS_LEASE_RETURN,
      icon: <CarPlumpLeftRightArrow />,
      hidden: !getIsDisplayComponent('vehicleMenuLeasingReturnBasicButton'),
      isEnabled: carStatus !== VehicleStateCodeLabel.Inactive && !isPoolManager
    },
    {
      name: 'Rückführungsformular Pro',
      href:
        carStatus === VehicleStateCodeLabel.Inactive || isPoolManager || !leasingReturnEnabled
          ? undefined
          : PAGES.ASSIGNMENTS_LEASE_RETURN_PRO,
      icon: <CarPlumpLeftRightArrow />,
      hidden: !getIsDisplayComponent('leasingReturnPro'),
      isEnabled: carStatus !== VehicleStateCodeLabel.Inactive && !isPoolManager && !!leasingReturnEnabled
    },
    {
      name: 'Leasingvertragsverlängerung',
      icon: <DocumentIcon />,
      isEnabled: getLeaseEndStatus(phase),
      hidden: isPoolManager || getLeaseEndHiddenStatus(phase),
      tooltip: getLeaseEndTooltip(phase),
      handleClick: getLeaseEndHandleClick(phase, openLeasingContractModal, openOfferConfirmationModal)
    }
  ];

  return { getMyVehiclesMenuSetupLeft };
};
