export enum MainMenuTitles {
  Dashboard = 'Dashboard',
  Vehicle = 'Fahrzeug',
  Fleet = 'Fuhrpark',
  Contact = 'Kontakt',
  APIDoc = 'API Doc',
  Orders = 'Bestellungen',
  Tasks = 'Aufgaben',
  Accounting = 'Buchhaltung',
  BookRentalCar = 'Mietwagen buchen',
  Requests = 'Anfragen',
  Settings = 'Einstellungen',
  BecomeCustomer = 'Kunde werden',
  Customers = 'Kunden',
  Order = 'Bestellung',
  Report = 'Report',
  GREENHOUSE_GAS_EMISSION_QUOTA = 'THG-Quote'
}

export enum SubMainMenuTitles {
  RepairStatus = 'Reparaturstatus',
  ReportDamage = 'Schaden melden',
  Driver = 'Fahrer',
  DriverLicenseCheck = 'Führerscheinkontrolle',
  Vehicles = 'Fahrzeuge',
  Damage = 'Schäden',
  FuelReport = 'Kraftstoff Report',
  FuelCards = 'Tankkarten',
  Assignments = 'Aufträge',
  PoolCarBookings = 'Pool-Car Buchungen',
  MyPoolCarBookings = 'Meine Pool-Car Buchungen',
  LeaseEnd = 'Leasingende',
  PackagesAndPrice = 'Pakete & Preise',
  Tasks = 'Aufgaben',
  ServiceProvider = 'Dienstleisteraufgaben',
  VehicleCost = 'Fahrzeugkosten',
  MonthlyBills = 'Monatsrechnungen',
  WallBoxBilling = 'Wallbox Abrechnung',
  SubmitFuelReceipt = 'Tankquittung einreichen',
  IncomingInvoices = 'Eingangsrechnungen',
  Orders = 'Bestellungen',
  Configurations = 'Konfigurationen',
  EmployeeConfigurations = 'Mitarbeiterkonfigurationen',
  Enterprise = 'Enterprise',
  Finn = 'Finn',
  MyRequests = 'Meine Anfragen',
  VehicleRequests = 'Fahrzeuganfragen',
  LeasingRequests = 'Leasinganfragen',
  Companies = 'Unternehmen',
  Budget = 'Mobilitätsbudget',
  CarPolicy = 'Car-Policy',
  ReferenceVehicle = 'Referenzfahrzeuge',
  Documents = 'Vertragsdokumente',
  Contracts = 'Rahmenverträge',
  SetupAssistant = 'Einrichtungsassistent',
  Import = 'Import',
  UserSettings = 'Konto',
  DecisionSupport = 'Entscheidungshilfen',
  CustomersRecruitCustomers = 'Kunden werben Kunden',
  CreateOffer = 'Angebot erstellen',
  Customers = 'Kunden',
  InviteCustomers = 'Kunden einladen',
  LongTermRental = 'Langzeitmiete',
  DocumentManager = 'Dokumentenmanager',
  Invoices = 'Eingangsrechnungen',
  TopDeals = 'Top Deals',
  Approvals = 'Freigaben',
  Cockpit = 'Portfolio Cockpit',
  Cockpit_2_0 = 'Portfolio Cockpit 2.0',
  CreateDamage = 'Schaden anlegen',
  FleetSettings = 'Flotteneinstellungen',
  AccountingSettings = 'Buchhaltungseinstellungen',
  PersonalEmailTemplate = 'Personalisierte E-Mail-Vorlage'
}
