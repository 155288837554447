import axios from 'axios';
import { AnyObject } from 'common/interfaces';
import authService from './auth-service';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export async function checkFuhrparkClient() {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/FuhrparkLeaseEnd/CheckFuhrparkClient`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {}
  });
}

export async function getCarsListVR() {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/FuhrparkLeaseEnd/GetFahrzeugListVR`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {}
  });
}

export async function saveForm(form: AnyObject, files: File[]) {
  const token = await authService.acquireTokenAsync();
  const data = new FormData();

  const stringified = JSON.stringify(form);

  data.append('AttributesJson', stringified);
  files &&
    files.forEach(file => {
      data.append('Files', file);
    });

  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/FuhrparkLeaseEnd/SaveVehicleReturnInfo`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data
  });
}
