import React, { useEffect, useState } from 'react';
import SideMenuRightContainer from '../../components/side-menu-right-container/side-menu-right-container.js';
import { AnyObject, TableParams } from 'common/interfaces';
import { setValue } from 'utils/get-response-data';
import { useQuery } from '../../hooks';
import PanelPlaceholder from '../../components/panel-placeholder';
import TaskStatus from 'pages/tasks-page/task-status/task-status';
import TaskHandler from 'pages/tasks-page/task-handler/task-handler';
import {
  StyledTableComponent,
  StyledContainer,
  StyledExpandIcon,
  StyledTextContainer,
  StyledWrapper,
  StyledTableContainer
} from 'pages/tasks-page/task-page.styled';
import Snackbar from 'components/snackbar';
import TasksFilters from 'pages/tasks-page/tasks-filters/tasks-filters';
import { Flex } from 'common/common-components.styled';
import { USER_ROLES, isMobileWidth } from '../../constants';
import MobileTableComponent from './mobile-table-component/mobile-table-component';
import { DataModel, TableData } from './tasks-page.props';
import { useServiceProviderTasks } from 'hooks/react-query/service-provider-tasks';
import { ColumnsVariant, ColumnType, RootTagName } from '../../components/table-component/table-component.props';
import Pagination from 'components/pagination';
import { useIsUserHaveRole } from 'hooks/use-is-user-have-role';
import { cloneDeep, merge } from 'lodash';
import { defaultUrlTree } from 'components/breadcrumbs-panel-dashboard/breadcrumbs-panel-dashboard';
import { useDispatch } from 'react-redux';
import { setCustomUrlTree } from 'actions/app_action.js';

export interface SnackbarState {
  message: string;
  open: boolean;
  type: string;
}

export interface FilterDataState {
  statecode: { label: string; value: number }[];
  regardingobjectid: { id: string; logicalName: string; name: string }[];
  uds_executor_contactid: { id: string; logicalName: string; name: string }[];
  uds_isfragesteller: { id: string; logicalName: string; name: string }[];
  id: string | null;
  uds_aufgabeid_name: string[];
}

export interface TasksFiltersObject {
  statecode: number | null;
  subject: string | null;
}

export const DescriptionComponent = ({ row, setSnackbarState }: AnyObject) => {
  const formattedText = row.description
    ? row.description.split('\n').map((text: string, index: number) => (
        <span key={text + index}>
          {text}
          <br />
        </span>
      ))
    : '-';

  const flexProps = {
    top: isMobileWidth ? '0' : '25',
    bottom: isMobileWidth ? '0' : '25',
    left: isMobileWidth ? '0' : '25',
    right: isMobileWidth ? '0' : '25'
  };

  return (
    <Flex {...flexProps} align='flex-end'>
      <StyledTextContainer>{formattedText}</StyledTextContainer>
      <TaskHandler setSnackbarState={setSnackbarState} row={row} />
    </Flex>
  );
};

interface ExpandCollapseIconProps {
  openedItemId: string;
  row: AnyObject;
}

const ExpandCollapseIcon = ({ openedItemId, row }: ExpandCollapseIconProps) => {
  const isOpen = openedItemId === row.activityid;
  return (
    <Flex left='20'>
      <StyledExpandIcon open={isOpen} />
    </Flex>
  );
};

const TasksComponent = () => {
  const query = useQuery();
  const taskId = (query.id as string) || null;
  const [openMobileItemIndx, setOpenMobileItemIndx] = useState(-1);
  const [snackbarState, setSnackbarState] = useState<SnackbarState>({
    message: '',
    open: false,
    type: ''
  });
  const dispatch = useDispatch();

  const isNoNeededDashboardTitle = useIsUserHaveRole([
    USER_ROLES.insurance.id,
    USER_ROLES.workshop.id,
    USER_ROLES.attorney.id
  ]);

  const [tableParams, setTableParams] = useState<TableParams>(DEFAULT_TABLE_PARAMS);
  const { table, filters } = useServiceProviderTasks(tableParams);

  useEffect(() => {
    if (isNoNeededDashboardTitle) {
      const newUrlTree: any = cloneDeep(defaultUrlTree);
      newUrlTree.breadcrumbTitle = '';
      dispatch(setCustomUrlTree(newUrlTree));
    }
  }, []);

  const pageChangeHandler = (page: number) => {
    setTableParams({ ...tableParams, pageNumber: page });
  };

  const onHandleFilter = (filtersObject: TasksFiltersObject) => {
    setTableParams({
      ...tableParams,
      pageNumber: 1,
      searchParam: {
        ...filtersObject
      }
    });
  };

  const sort = (fieldName: string) => {
    setTableParams({
      ...tableParams,
      sortParam: {
        [fieldName]: tableParams.sortParam[fieldName] ? 0 : 1
      }
    });
  };

  const columns: ColumnType[] = [
    {
      name: 'Aufgaben ID',
      type: ColumnsVariant.Text,
      propName: 'uds_aufgabeid_name',
      sort: true,
      sortName: 'aufgabeIdName',
      width: '13%'
    },
    {
      name: 'Bezeichnung',
      type: ColumnsVariant.Text,
      propName: 'subject',
      sort: false,
      width: '13%'
    },
    {
      name: 'Fragesteller',
      type: ColumnsVariant.Text,
      propName: 'uds_isfragesteller',
      sort: false,
      width: '13%'
    },
    {
      name: 'E-Mail',
      type: ColumnsVariant.Text,
      propName: 'emailaddress1',
      sort: false,
      width: '13%'
    },
    {
      name: 'Telefon',
      type: ColumnsVariant.Text,
      propName: 'telephone1',
      sort: false,
      width: '13%'
    },
    {
      name: 'Mobil',
      type: ColumnsVariant.Text,
      propName: 'mobilephone',
      sort: false,
      width: '13%'
    },
    {
      name: 'Zuständige',
      type: ColumnsVariant.Text,
      propName: 'uds_executor_contactid',
      sort: false,
      width: '13%'
    },
    {
      name: 'Besitzer',
      type: ColumnsVariant.Text,
      propName: 'ownerid',
      sort: false,
      width: '20%'
    },
    {
      name: 'Bezug',
      type: ColumnsVariant.Text,
      propName: 'regardingobjectid',
      sort: false,
      width: '10%'
    },
    {
      name: 'Fälligkeitsdatum',
      type: ColumnsVariant.Date,
      propName: 'scheduledend',
      sort: true,
      sortName: 'scheduledend',
      width: '16%'
    },
    {
      name: 'Status',
      type: ColumnsVariant.Chip,
      component: <TaskStatus row={{}} />,
      sort: false,
      width: '5%'
    },
    {
      name: '',
      type: ColumnsVariant.Text,
      component: <ExpandCollapseIcon openedItemId={''} row={{}} />,
      width: '4%'
    }
  ];

  const openTaskMobile = (event: React.MouseEvent<HTMLElement>) => {
    const elem = (event.target as Element).closest('li');
    const elemIndx = Number(elem?.getAttribute('data-index'));
    event.stopPropagation();

    if (elemIndx === openMobileItemIndx) {
      return setOpenMobileItemIndx(-1);
    }

    setOpenMobileItemIndx(elemIndx);
  };

  const getDataForTable = (items: TableData[]) =>
    items.map(item => {
      const sortData: TableData = {};
      Object.entries(item).forEach(([key, value]) => {
        setValue(item, key, value, sortData, true);
      });

      return sortData;
    });

  const rowsForTable = getDataForTable(
    table?.items?.flatMap(({ task, fragestellerContact }) => merge(task, fragestellerContact))
  );

  return (
    <>
      <StyledContainer>
        <StyledWrapper>
          <StyledTableContainer>
            {!isMobileWidth && filters.isShowFilters && (
              <Flex justify='space-between' bottom='20' style={{ display: taskId ? 'none' : 'flex' }}>
                <TasksFilters filterData={filters} onHandleFilter={onHandleFilter} />
              </Flex>
            )}

            {table?.items?.length ? (
              <>
                {isMobileWidth ? (
                  <ul onClick={openTaskMobile}>
                    {rowsForTable?.map((rowData, indx) => (
                      <MobileTableComponent
                        rowData={rowData}
                        key={rowData.activityid}
                        indx={indx}
                        openMobileItemIndx={openMobileItemIndx}
                        setSnackbarState={setSnackbarState}
                      />
                    ))}
                  </ul>
                ) : (
                  <StyledTableComponent
                    columns={columns}
                    rows={rowsForTable}
                    rootTag={{
                      name: RootTagName.Expand,
                      propName: 'activityid',
                      expandComponent: <DescriptionComponent row={{}} setSnackbarState={setSnackbarState} />
                    }}
                    minWidth={1000}
                    sort={sort}
                    sortParams={tableParams.sortParam}
                    openedItem={taskId || ''}
                  />
                )}
              </>
            ) : (
              <PanelPlaceholder title='Nichts gefunden' description='Diese Seite enthält keine Ergebnisse.' />
            )}

            <Snackbar
              open={snackbarState.open}
              type={snackbarState.type}
              onClose={() => setSnackbarState({ open: false, message: '', type: '' })}
              message={snackbarState.message}
            />
          </StyledTableContainer>
        </StyledWrapper>
      </StyledContainer>

      {table.totalRecordCount > tableParams.countOnPage && (
        <Pagination
          activePage={tableParams.pageNumber}
          itemsCountPerPage={tableParams.countOnPage}
          totalItemsCount={table.totalRecordCount}
          onPageChange={pageChangeHandler}
        />
      )}
    </>
  );
};

const TasksPage = () => {
  return (
    <SideMenuRightContainer>
      <TasksComponent />
    </SideMenuRightContainer>
  );
};

export const defaultTableData: DataModel = { items: [], totalRecordCount: 0 };
export const defaultFilterData = {
  statecode: [],
  regardingobjectid: [],
  uds_executor_contactid: [],
  uds_isfragesteller: [],
  id: '',
  uds_aufgabeid_name: ''
};
const DEFAULT_TABLE_PARAMS = {
  pageNumber: 1,
  countOnPage: 20,
  sortParam: {
    aufgabeIdName: 1
  },
  searchParam: {}
};

export const SERVICE_PROVIDER_TASK_CRM_NAME = {
  taskId: 'aufgabeIdName'
};

export default TasksPage;
