import React, { cloneElement, useState } from 'react';
import { SubMenuItemComponentProps } from 'components/side-menu/sub-menu-item/sub-menu-item.props';
import { Distance, Divider, Flex } from 'common/common-components.styled';
import Tooltip from '@mui/material/Tooltip';
import { SubMenuItem } from 'components/side-menu/side-menu-interfaces';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { AnyObject } from 'common/interfaces';
import { IndicatorSelector } from 'selectors';
import { SubMainMenuTitles } from 'components/side-menu/side-menu-enums';
import { setSideMenuOpen } from 'actions/app_action';
import { useHistory, useLocation } from 'react-router-dom';
import { useUnsavedChangesModal } from 'hooks/modals/unsaved-changes.modal/unsaved-changes.modal';
import { useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';
import IconHover from 'components/icon-hover';
import {
  StyledIndicator,
  StyledIndicatorForCollapsedMenu,
  StyledMenuTitle,
  StyledSubMenuItem,
  StyledSubMenuLinkItem
} from 'components/side-menu/sub-menu-item/sub-menu-item.styled';
import InfoChip from 'components/info-chip';
import { INDICATOR_TYPE } from 'hooks/use-refresh-indicator';
import { replaceGermanWordsToEnglish } from 'utils/replace-german-words-to-english';

const SubMenuItemComponent = ({ subMenuItem, showMenuTitle, closePopover }: SubMenuItemComponentProps) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const indicator: AnyObject = useTypedSelector(IndicatorSelector);

  const { openUnsavedChangesModal } = useUnsavedChangesModal();
  const history = useHistory();
  const location = useLocation();

  const path =
    location.pathname[location.pathname.length - 1] === '/' ? location.pathname.slice(0, -1) : location.pathname;

  const [showTooltip, setShowTooltip] = useState(false);

  const checkIndicator = (menuItem: SubMenuItem) => {
    let isIndicatorShown = false;
    let indicatorValue = null;
    if (menuItem.indicator && indicator) {
      switch (menuItem.title) {
        case SubMainMenuTitles.Invoices: {
          const currentIndicator = indicator[INDICATOR_TYPE.freigabe];
          isIndicatorShown =
            menuItem?.indicator && currentIndicator?.isFreigabeActive && Boolean(currentIndicator?.freigabeCount);
          indicatorValue = currentIndicator?.freigabeCount;
          break;
        }
        case SubMainMenuTitles.DocumentManager: {
          const currentIndicator = indicator[INDICATOR_TYPE.doctype];

          isIndicatorShown =
            menuItem?.indicator && currentIndicator?.isOcrEingangActive && Boolean(currentIndicator?.ocrEingangCount);
          indicatorValue = currentIndicator?.ocrEingangCount;
          break;
        }
      }
    }

    return {
      isIndicatorShown,
      indicatorValue
    };
  };

  const openPageHandler = (link: string) => {
    closePopover();
    setTimeout(() =>
      openUnsavedChangesModal(() => {
        history.replace(location.pathname);
        history.push(link);
        dispatch(setSideMenuOpen(false));
      })
    );
  };

  const openExternalPage = () => {
    window.open(subMenuItem.links[0], '_black');
  };

  const indicatorObj = checkIndicator(subMenuItem);
  if (subMenuItem.isVisible === false) {
    return null;
  }
  const isActive = subMenuItem.links.some(link => link === path && !(subMenuItem.hideIfParams && location.search));

  const handleTooltip = (showTooltip: boolean) => {
    if (!showMenuTitle) {
      setShowTooltip(showTooltip);
    }
  };

  return (
    <>
      {subMenuItem.external ? (
        <Tooltip
          open={showTooltip}
          title={subMenuItem.title}
          onOpen={() => handleTooltip(true)}
          onClose={() => handleTooltip(false)}
          disableInteractive
        >
          <StyledSubMenuLinkItem
            active={isActive}
            id={replaceGermanWordsToEnglish(subMenuItem.title) + 'SubItem'}
            onClick={openExternalPage}
          >
            <Tooltip title={subMenuItem.title}>
              <IconHover height='20px' hoveredColor={theme.colors.blackPearl}>
                {cloneElement(subMenuItem.subMenuImg, {
                  color: theme.colors.grey600,
                  width: 20,
                  height: 20
                })}
              </IconHover>
            </Tooltip>
            {showMenuTitle && (
              <>
                <Distance side='20' />
                <StyledMenuTitle className='sub-menu-item-title' active={isActive}>
                  {subMenuItem.title}
                </StyledMenuTitle>
              </>
            )}
            {subMenuItem.infoChip && showMenuTitle && (
              <>
                <Distance side='15' />
                <Tooltip title='Dieses Feature befindet sich noch in der Testphase.'>
                  <div>
                    <InfoChip color={theme.colors.white} bgcolor={theme.colors.grey400} text={subMenuItem.infoChip} />
                  </div>
                </Tooltip>
              </>
            )}
          </StyledSubMenuLinkItem>
        </Tooltip>
      ) : (
        <Tooltip
          open={showTooltip}
          title={subMenuItem.title}
          onOpen={() => handleTooltip(true)}
          onClose={() => handleTooltip(false)}
          disableInteractive
        >
          <StyledSubMenuItem
            key={subMenuItem.title}
            active={isActive}
            onClick={() => openPageHandler(subMenuItem.links[0])}
            id={replaceGermanWordsToEnglish(subMenuItem.title) + 'SubItem'}
          >
            <Flex position='relative'>
              <IconHover height='20px' hoveredColor={theme.colors.blackPearl}>
                {cloneElement(subMenuItem.subMenuImg, {
                  color: theme.colors.grey600,
                  width: 20,
                  height: 20
                })}
              </IconHover>

              {indicatorObj.isIndicatorShown && !showMenuTitle && (
                <Tooltip title={subMenuItem.indicatorText || ''}>
                  <StyledIndicatorForCollapsedMenu />
                </Tooltip>
              )}
            </Flex>

            {showMenuTitle && (
              <>
                <Distance side='20' />
                <StyledMenuTitle className='sub-menu-item-title' active={isActive}>
                  {subMenuItem.title}
                </StyledMenuTitle>
              </>
            )}

            {indicatorObj.isIndicatorShown && showMenuTitle && (
              <>
                <div className='mr-15' />
                <Tooltip title={subMenuItem.indicatorText || ''}>
                  <StyledIndicator>{indicatorObj.indicatorValue}</StyledIndicator>
                </Tooltip>
              </>
            )}

            {subMenuItem.infoChip && showMenuTitle && (
              <>
                <Distance side='15' />
                <Tooltip title='Dieses Feature befindet sich noch in der Testphase.'>
                  <div>
                    <InfoChip color={theme.colors.white} bgcolor={theme.colors.grey400} text={subMenuItem.infoChip} />
                  </div>
                </Tooltip>
              </>
            )}
          </StyledSubMenuItem>
        </Tooltip>
      )}
      {subMenuItem.underline && <Divider color='grey100' margin='0 15px' />}
    </>
  );
};

export default SubMenuItemComponent;
