import styled from 'styled-components';
import { Button } from 'common/common-components.styled';
import Form from 'components/form';

export const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.blue};
`;

export const StyledForm = styled(Form)`
  margin: 0;
  max-width: 300px;
  section {
    padding: 0 !important;
  }
`;
