import React, { useEffect, useMemo, useState } from 'react';
import VehicleOffersListComponent from '../../components/vehicle-offers-list-component/vehicle-offers-list-component';
import ComparisonPanel from '../../components/comparison-panel/comparison-panel-redux';
import { useLocation, withRouter } from 'react-router-dom';
import classes from './fahrzeuge-der-car-policy-page.module.scss';
import FahrzeugeDerCarPolicyFilters from './fahrzeuge-der-car-policy-filters/fahrzeuge-der-car-policy-filters';
import Sorting from '../../components/sorting/sorting-redux';
import { useSessionFilter } from 'hooks/use-session-filter';
import { EmployeeConfigurationsBigFilters } from './fahrzeuge-der-car-policy-filters/employee-configurations/employee-configurations';
import { useSelector } from 'react-redux';
import { Distance, Flex } from '../../common/common-components.styled';
import { ConfigurationTypes, PAGES } from '../../common/enums';
import ConfigurationTypeTile from '../../components/configuration-type-tile';
import SideMenuRightContainer from '../../components/side-menu-right-container/side-menu-right-container';
import { StyledTilesContainer } from '../../components/configuration-type-tile/configuration-type-tile.styled';
import { useReferenceVehicleTypes } from '../../hooks/react-query/show-change-view-button';
import useOverlay from '../../hooks/use-overlay';
import { useSetFilterFromLocationState } from 'hooks/use-set-filter-from-location-state/use-set-filter-from-location-state';

export const vehicleOfferPageLoadingKey = 'vehicleOfferPageOverlay';

const FahrzeugeDerCarPolicyPage = props => {
  const location = useLocation();
  const [showOverlay, hideOverlay] = useOverlay(vehicleOfferPageLoadingKey);
  const comparisonPanelOpen = useSelector(state => state['comparison'].comparisonPanelOpen);
  const comparisonCars = useSelector(state => state['comparison'].cars);

  const [tableParams, setTableParams] = useState(defaultTableParams);
  const [configurationType, setConfigurationsType] = useState(null);

  const isEmployeeConfigurationsPage = location.pathname.includes('mitarbeiterkonfigurationen');
  const isDriverNewConfigurationPage = useMemo(() => {
    return location.pathname.includes(PAGES.NEW_CONFIGURATIONS);
  }, [location]);
  const isFleetManagerNewConfigurationPage = useMemo(() => {
    return location.pathname.includes(PAGES.NEW_REFERENCE_CONFIGURATIONS);
  }, [location]);

  const { isGRVAvailable, isReferenceVehiclesAvailable, isBulkOrdersAvailable, isCarConfiguratorAvailable } =
    useReferenceVehicleTypes(isDriverNewConfigurationPage || isFleetManagerNewConfigurationPage);
  const { sessionFilter, setSessionFilter } = useSessionFilter(
    window.location.pathname,
    EMTY_FILTER_STATE,
    EMTY_FILTER_STATE
  );

  useSetFilterFromLocationState(setSessionFilter, EMTY_FILTER_STATE, EMTY_FILTER_STATE);

  const numberOfTypesChosen = [
    isGRVAvailable,
    isReferenceVehiclesAvailable,
    isBulkOrdersAvailable,
    isCarConfiguratorAvailable
  ].reduce((accumulator, currentValue) => (currentValue ? ++accumulator : accumulator), 0);

  const showConfigurationTypesOptions =
    numberOfTypesChosen > 1 &&
    (isDriverNewConfigurationPage || isFleetManagerNewConfigurationPage) &&
    !configurationType;

  const isSetConfigurationType =
    numberOfTypesChosen === 1 && (isDriverNewConfigurationPage || isFleetManagerNewConfigurationPage);

  useEffect(() => {
    const scrollContainer = document.querySelector('.scroll-container');
    if (scrollContainer) {
      scrollContainer.scrollTo({ top: 0 });
    }
  }, []);

  useEffect(() => {
    if (showConfigurationTypesOptions) {
      hideOverlay();
    }

    return hideOverlay;
  }, [showConfigurationTypesOptions]);

  useEffect(() => {
    if (isSetConfigurationType) {
      setConfigurationsType(
        isGRVAvailable
          ? ConfigurationTypes.Global
          : isReferenceVehiclesAvailable
          ? ConfigurationTypes.Reference
          : isBulkOrdersAvailable
          ? ConfigurationTypes.BulkOrders
          : ConfigurationTypes.CarConfigurator
      );
    }
  }, [isSetConfigurationType]);

  const goToFirstPage = () => {
    setTableParams(prevState => ({
      ...prevState,
      pagination: firstPagePaginationParam
    }));
  };

  const options = [
    { value: 'createdon-true', label: 'Neueste zuerst' },
    { value: 'createdon-false', label: 'Älteste zuerst' },
    { value: 'kraftstoffcostenPlusGesamtrate-false', label: 'Leasingrate aufsteigend' },
    { value: 'kraftstoffcostenPlusGesamtrate-true', label: 'Leasingrate absteigend' }
  ];

  if (showConfigurationTypesOptions) {
    return (
      <SideMenuRightContainer>
        <StyledTilesContainer justify='center'>
          {isBulkOrdersAvailable && (
            <ConfigurationTypeTile type={ConfigurationTypes.BulkOrders} setConfigurationsType={setConfigurationsType} />
          )}
          {isReferenceVehiclesAvailable && (
            <ConfigurationTypeTile type={ConfigurationTypes.Reference} setConfigurationsType={setConfigurationsType} />
          )}
          {isGRVAvailable && (
            <ConfigurationTypeTile type={ConfigurationTypes.Global} setConfigurationsType={setConfigurationsType} />
          )}
          {isCarConfiguratorAvailable && (
            <ConfigurationTypeTile
              type={ConfigurationTypes.CarConfigurator}
              setConfigurationsType={setConfigurationsType}
            />
          )}
        </StyledTilesContainer>
      </SideMenuRightContainer>
    );
  }

  return (
    <section className={classes.fahrzeuge_car_policy_page}>
      <ComparisonPanel />
      <div className={classes.container}>
        {configurationType !== ConfigurationTypes.Global && (
          <div className={classes.filter_wrapper}>
            {isEmployeeConfigurationsPage && (
              <EmployeeConfigurationsBigFilters
                setSessionFilter={setSessionFilter}
                sessionFilter={sessionFilter}
                goToFirstPage={goToFirstPage}
              />
            )}
            <div className={classes.wrapper}>
              <div>
                <FahrzeugeDerCarPolicyFilters
                  key={props.location.pathname}
                  sessionFilter={sessionFilter}
                  setSessionFilter={setSessionFilter}
                  goToFirstPage={goToFirstPage}
                />
              </div>
            </div>
            <div className={classes.sort}>
              <div className={classes.sort_container}>
                <Sorting options={options} />
              </div>
            </div>
          </div>
        )}
        <VehicleOffersListComponent
          filterState={sessionFilter?.filterForServer}
          tableParams={tableParams}
          setTableParams={setTableParams}
          key={props.location.pathname}
          configurationType={configurationType}
        />
      </div>
      {!!comparisonCars.length && comparisonPanelOpen && <Distance top='160' />}
    </section>
  );
};

const firstPagePaginationParam = {
  start: 1,
  number: 10
};

const defaultTableParams = {
  pagination: firstPagePaginationParam,
  sort: {
    predicate: 'createdon',
    reverse: true
  },
  search: {}
};

export const EMTY_FILTER_STATE = {
  driverLevel: null,
  uds_enginetypecode: null,
  uds_carbrandid: null,
  uds_carmodelid: null,
  uds_kstverantw: null,
  uds_app_status_filter_value: null,
  carPolicy: null,
  accountId: null,
  contactId: null,
  uds_name: null
};

export const CRM_VALUE = {
  driverLevel: 'driverLevel',
  engineType: 'uds_enginetypecode',
  manufacturer: 'uds_carbrandid',
  model: 'uds_carmodelid',
  responsiblePerson: 'uds_kstverantw',
  status: 'uds_app_status_filter_value',
  carPolicy: 'carPolicy',
  company: 'accountId',
  driver: 'contactId',
  offerNumber: 'uds_name'
};

export default withRouter(FahrzeugeDerCarPolicyPage);
