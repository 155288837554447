import { useEffect } from 'react';
import { useQuery } from 'react-query';
import useOverlay from 'hooks/use-overlay';
import { getCurrentUserTasks, getListWithCurrentUserTasksFilterData } from 'components/aufgaben-component/utils';
import { PAGES, ReactQueryKeys } from 'common/enums';
import { defaultFilterData, defaultTableData } from 'pages/tasks-page/tasks-page';
import { ResponseModel, TableParams } from 'common/interfaces';
import { DataModel } from 'pages/tasks-page/tasks-page.props';
import { AxiosResponse } from 'axios';
import isEmpty from 'lodash/isEmpty';

export const useServiceProviderTasks = (tableParams: TableParams) => {
  const [showOverlay, hideOverlay] = useOverlay();

  const {
    data: tableDataQuery,
    isFetching: tableIsFetching,
    refetch: tableRefetch,
    isFetched: tableIsFetched
  } = useQuery<AxiosResponse<ResponseModel<DataModel>>>([PAGES.SERVICE_PROVIDER_TASKS, tableParams], () =>
    getCurrentUserTasks(tableParams),
    {
      enabled: !isEmpty(tableParams.searchParam)
    }
  );

  const { data: filtersDataQuery, isFetching: filtersIsFetching } = useQuery(
    [ReactQueryKeys.Filter, PAGES.SERVICE_PROVIDER_TASKS],
    getListWithCurrentUserTasksFilterData
  );

  const isFetching = tableIsFetching || filtersIsFetching;

  useEffect(() => {
    if (isFetching) {
      showOverlay();
    } else {
      hideOverlay();
    }
  }, [isFetching]);

  return {
    table: tableDataQuery?.data.data || defaultTableData,
    filters: filtersDataQuery?.data?.data || defaultFilterData,
    tableRefetch,
    tableIsFetched
  };
};
