import { AxiosRequestConfig } from 'axios';

type FuelReportFiltersPayload = {
  searchValue: string;
  columnName: string;
};

export type FuelReportPayload = {
  search: {
    new_hersteller: string[];
    new_standortid: string[];
    new_fahrerid: string[];
    uds_kostenstelle3: string[];
    uds_kostenstelle2: string[];
    new_kostenstelleid: string[];
    new_model: string[];
    new_name: string[];
    new_sparteid: string[];
    uds_transactiondate: {
      startdate: string | null;
      enddate: string | null;
    };
  };
};

class ReportsRequestConfig {
  getFuelReport = (data?: FuelReportPayload): AxiosRequestConfig => ({
    method: 'POST',
    url: `reports/fuelReport`,
    responseType: 'blob',
    data
  });

  getFuelReportFilters = (
    data: FuelReportFiltersPayload = { searchValue: '', columnName: '' }
  ): AxiosRequestConfig => ({
    method: 'POST',
    url: 'reports/GetFuelReportFilterData',
    data
  });
}

export const reportsRequestConfig = new ReportsRequestConfig();
