/* eslint-disable max-len */
import { PAGES } from 'common/enums';
import authService from './auth-service';
import { API_ENDPOINT } from '../constants';
import Axios, { AxiosResponse } from 'axios';
import { ResponseModel } from 'common/interfaces';
import { GetDocumentTypeByApPageResponse } from 'hooks/react-query/document-type/document-type-by-app/document-type-by-app.props';

class DocumentTypeService {
  private RESOURCE = 'documenttype';

  getDocumentTypeByApPage = async (
    pageUrl: PAGES
  ): Promise<AxiosResponse<ResponseModel<GetDocumentTypeByApPageResponse>>> => {
    const token = await authService.acquireTokenAsync();
    return Axios({
      method: 'POST',
      url: `${API_ENDPOINT}/api/${this.RESOURCE}/getDocumentTypeByApPage`,
      headers: { Authorization: `Bearer ${token}` },
      data: { pageUrl }
    });
  };
}

export const documentTypeService = new DocumentTypeService();
