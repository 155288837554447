import { ApiResponse, ResponseModel } from 'common/interfaces';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import { vehicleCostRequestConfig } from '../../../request-config/vehicle-cost/vehicle-cost.request-config';
import { useQuery } from 'react-query';
import { RequestStatuses } from '../../../constants';
import { ReactQueryKeys } from 'common/enums';

type DownloadFlagResponse = {
  isCorrectLink: boolean;
};

const modifyResponse = ({ data }: ApiResponse<DownloadFlagResponse>) => {
  if (data.level === RequestStatuses.Success) {
    return {
      isCorrectLink: data.data.isCorrectLink
    };
  }
  return undefined;
};

export const useCheckDownloadFlag = (enabled: boolean, recordId: string, invoiceId: string) => {
  const { fetch } = useFetch<ResponseModel<DownloadFlagResponse>>({
    isShowLoading: false,
    isCancelRequestOnUnmount: true,
    isShowErrorSnackbar: false,
    ...vehicleCostRequestConfig.checkDownloadFlag({ RecordId: recordId, InvoiceId: invoiceId })
  });

  const { data, isLoading } = useQuery<ApiResponse<DownloadFlagResponse>, unknown, DownloadFlagResponse | undefined>(
    [ReactQueryKeys.CheckDownloadFlag, recordId],
    () => fetch(),
    {
      select: modifyResponse,
      enabled
    }
  );

  return { isCorrectLink: data?.isCorrectLink, isLoading };
};
