import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toggleModal } from 'actions/app_action.js';
import { MODAL_NAMES, MODALS, REPLACEMENT_VEHICLE_STATUS } from '../../constants';
import { PAGES } from 'common/enums';
import SideMenuRightContainer from '../../components/side-menu-right-container/side-menu-right-container.js';
import {
  StyledColumnSettingsWrapper,
  StyledLeaseendContainer,
  StyledPlaceholderWrapper,
  StyledTableWrapper,
  StyledTooltipText
} from './leasing-end-page.styled';
import { Text, Flex } from 'common/common-components.styled';
import { SettingsIconOutlined } from 'components/icons/settings-icon-outlined';
import ColumnsSettings from 'components/columns-settings/columns-settings';
import { loadContactPreferences, saveContactPreferences } from 'services/portal-customization-service';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { useLeaseEndQuery } from 'hooks/react-query/lease-end';
import TableComponent from 'components/table-component/table-component';
import PanelPlaceholder from 'components/panel-placeholder/panel-placeholder';
import { useOverlay } from 'hooks';
import LeasingEndFilters from './components/leasing-end-filters';
import { useSessionFilter } from 'hooks/use-session-filter';
import { useSessionSorting } from 'hooks/use-session-sorting';
import classes from './lease-end-page.module.scss';
import {
  CustomizedColumnForModalProps,
  ReplacementStatusProps,
  TableParamsData,
  TooltipTextProps
} from './lease-end-page.props';
import { encodeBase64 } from 'utils/decode-encode-base64';
import useWindowSize from 'hooks/use-window-size';
import { Tooltip } from 'components/tooltip/tooltip';
import { ColumnsVariant, ColumnType, RootTagName } from '../../components/table-component/table-component.props';
import { AxiosResponse } from 'axios';
import Pagination from 'components/pagination';
import { getCustomizedOrder } from './utils';
import { useSetFilterFromLocationState } from 'hooks/use-set-filter-from-location-state/use-set-filter-from-location-state';
import { useFileDownload } from 'hooks/useFileDownload';
import { fleetRequestConfig } from 'request-config/fleet/fleet.request-config';
import Button from 'components/button';
import { ButtonComponentType } from 'components/button/button.props';

interface CustomizedColumnsProps {
  customizedColumns: CustomizedColumnForModalProps[];
  refetch: () => void;
  loadPreferences: () => void;
}

const getDefaultColumns = (defaultColumns: any[]) => {
  const newColumns: CustomizedColumnForModalProps[] = [];

  defaultColumns.forEach(column => {
    newColumns.push({
      title: column.name,
      propName: column.propName,
      isChecked: true,
      columnId: column.columnId
    });
  });

  return newColumns;
};

const getLink = (status: string, tooltip: TooltipTextProps) => {
  return status === REPLACEMENT_VEHICLE_STATUS.configured
    ? `${PAGES.EMPLOYEE_CONFIGURATION_DETAILS}?id=${encodeBase64(tooltip.id)}`
    : `${PAGES.ORDER_DETAILS}/?id=${encodeBase64(tooltip.id)}`;
};

const ReplacementState = ({ row }: ReplacementStatusProps) => {
  const handleRedirect = (e: React.ChangeEvent<HTMLInputElement>, status: string, tooltip: TooltipTextProps) => {
    e.preventDefault();
    e.stopPropagation();
    window.open(getLink(status, tooltip), '_blank');
  };

  const getTooltipText = (status: string, tooltips: TooltipTextProps[] | null) => {
    return (
      <Flex width='245px'>
        <StyledTooltipText>
          {status === REPLACEMENT_VEHICLE_STATUS.configured
            ? 'Ersatzbeschaffung wurde konfiguriert, aber noch nicht bestellt. Konfigurationsnummern:'
            : 'Ersatzbeschaffung wurde erfolgreich bestellt. Bestellnummer:'}
          <Flex wrap='wrap'>
            {tooltips?.map((tooltip, index) => (
              <Flex key={tooltip.id}>
                <Text
                  onClick={(e: any) => handleRedirect(e, status, tooltip)}
                  underline
                  pointer
                  style={{ fontFamily: 'Open Sans' }}
                >
                  {tooltip.name}
                </Text>
                {tooltips[index + 1] && <Text whiteSpace='pre-wrap'>{`${tooltips[index + 1] && ', '}`}</Text>}
              </Flex>
            ))}
          </Flex>
        </StyledTooltipText>
      </Flex>
    );
  };

  if (
    row.uds_replacementstate &&
    (row.uds_replacementstate === REPLACEMENT_VEHICLE_STATUS.ordered ||
      row.uds_replacementstate === REPLACEMENT_VEHICLE_STATUS.configured)
  ) {
    return (
      <Flex>
        <Tooltip
          description={getTooltipText(row.uds_replacementstate, row.uds_replacementstate_tooltip)}
          tooltipOpenByClick
          descriptionClassName={classes.tooltip_description}
          tooltipClassName={classes.tooltip}
          title={row.uds_replacementstate}
          titleClassName={classes.tooltip_title}
          closeIconClassName={classes.tooltip_close_icon}
        />
      </Flex>
    );
  }

  return <Text>{row.uds_replacementstate}</Text>;
};

const columns: ColumnType[] = [
  {
    name: 'Kennzeichen',
    type: ColumnsVariant.Text,
    propName: 'new_name',
    sort: true,
    sortName: 'new_name',
    width: '10%',
    columnId: 'plateNumber'
  },
  {
    name: 'Hersteller',
    type: ColumnsVariant.Text,
    propName: 'new_hersteller',
    sort: true,
    sortName: 'new_hersteller',
    width: '10%',
    columnId: 'manufacturer'
  },
  {
    name: 'Modell',
    type: ColumnsVariant.Text,
    propName: 'new_model',
    sort: true,
    sortName: 'new_model',
    width: '10%',
    columnId: 'model'
  },
  {
    name: 'Unternehmen',
    type: ColumnsVariant.Text,
    propName: 'new_sparteid',
    sort: true,
    sortName: 'new_sparteid',
    width: '10%',
    columnId: 'company'
  },
  {
    name: 'Fahrer',
    type: ColumnsVariant.Text,
    propName: 'new_fahrerid',
    sort: true,
    sortName: 'new_fahrerid',
    width: '12%',
    columnId: 'driver'
  },
  {
    name: 'Kostenverantwortlicher',
    type: ColumnsVariant.Text,
    propName: 'new_kstverantw',
    sort: true,
    sortName: 'new_kstverantw',
    width: '12%',
    columnId: 'costManager'
  },
  {
    name: 'Erstzulassung',
    type: ColumnsVariant.Date,
    propName: 'new_ezl',
    sort: true,
    sortName: 'new_ezl',
    width: '10%',
    columnId: 'registration'
  },
  {
    name: 'Leasingdauer',
    type: ColumnsVariant.Text,
    propName: 'uds_leasinglaufzeit_decimal',
    sort: true,
    sortName: 'uds_leasinglaufzeit_decimal',
    width: '12%',
    columnId: 'leaseTerm'
  },
  {
    name: 'Leasingende',
    type: ColumnsVariant.Date,
    propName: 'new_leasingende',
    sort: true,
    sortName: 'new_leasingende',
    width: '10%',
    columnId: 'leaseEnd'
  },
  {
    name: 'Leasingvertrag stillschweigend verlängert bis',
    type: ColumnsVariant.Date,
    propName: 'uds_leaseenddate_extended',
    sort: true,
    sortName: 'uds_leaseenddate_extended',
    width: '10%',
    columnId: 'extendDate'
  },
  {
    name: 'Leasingrückgabe bis',
    type: ColumnsVariant.Date,
    propName: 'uds_lease_return_date',
    sort: true,
    sortName: 'uds_lease_return_date',
    width: '12%',
    columnId: 'leaseReturn'
  },
  {
    name: 'Gesamtlaufleistung',
    type: ColumnsVariant.Text,
    propName: 'uds_laufleistung',
    sort: true,
    sortName: 'uds_laufleistung',
    width: '10%',
    columnId: 'totalMileage'
  },
  {
    name: 'Kilometerstand',
    type: ColumnsVariant.Text,
    propName: 'uds_actualmileage',
    sort: true,
    sortName: 'uds_actualmileage',
    width: '10%',
    columnId: 'mileage'
  },
  {
    name: 'Durschnittliche Kilometer pro Monat',
    type: ColumnsVariant.Text,
    propName: 'uds_averagemonthlymileage',
    sort: true,
    sortName: 'uds_averagemonthlymileage',
    width: '10%',
    columnId: 'averageMileage'
  },
  {
    name: 'Voraussichtlicher Kilometerstand bei Leasingende',
    type: ColumnsVariant.Text,
    propName: 'uds_summeprojektion',
    sort: true,
    sortName: 'uds_summeprojektion',
    width: '10%',
    columnId: 'estimatedMileage'
  },
  {
    name: 'Ersatzbeschaffung',
    type: ColumnsVariant.Text,
    component: <ReplacementState row={{}} />,
    propName: 'uds_replacementstate',
    sort: true,
    sortName: 'uds_replacementstate',
    width: '10%',
    className: classes.replacement_column,
    columnId: 'replacementOrdering'
  },
  {
    name: 'Voraussichtliches Lieferungsdatum von Ersatzbeschaffung',
    type: ColumnsVariant.Date,
    propName: 'uds_vsl_liefertermin_laut_handler',
    sort: true,
    sortName: 'uds_vsl_liefertermin_laut_handler',
    width: '10%',
    columnId: 'replacementDelivery'
  },
  {
    name: 'Leasingrückgabe-Status',
    type: ColumnsVariant.Text,
    propName: 'uds_fahrzeugruckgabe',
    sort: true,
    sortName: 'uds_fahrzeugruckgabe',
    width: '10%',
    columnId: 'leaseReturnStatus'
  },
  {
    name: 'Checkliste für Fahrzeugrückgabe',
    type: ColumnsVariant.Text,
    propName: 'uds_preparationstate',
    sort: true,
    sortName: 'uds_preparationstate',
    width: '10%',
    columnId: 'checklistStatus'
  }
];

const ColumnsSettingsButton = ({ customizedColumns, refetch, loadPreferences }: CustomizedColumnsProps) => {
  const dispatch = useDispatch();
  const userId = useTypedSelector(state => state.app.user).id;
  const [showOverlay, hideOverlay] = useOverlay();

  const closeAlert = useCallback(() => {
    dispatch(toggleModal(MODALS.alert, null));
  }, [dispatch]);

  const saveSettings = useCallback(
    (columnsSettings: CustomizedColumnForModalProps[]) => {
      closeAlert();
      showOverlay();
      saveContactPreferences(userId, 'leasingende', JSON.stringify(columnsSettings)).then(() => {
        loadPreferences();
        refetch();
        hideOverlay();
      });
    },
    [closeAlert, hideOverlay, loadPreferences, refetch, showOverlay, userId]
  );

  const openSettings = useCallback(() => {
    const alertData = {
      title: 'Spalten anpassen',
      children: (
        <ColumnsSettings
          closeAlert={closeAlert}
          saveSettings={saveSettings}
          defaultColumns={getDefaultColumns(columns)}
          customColumns={customizedColumns}
        />
      ),
      buttons: [
        {
          type: 'cancel',
          title: 'Abbrechen',
          action: closeAlert,
          hide: true
        }
      ],
      className: `${classes.settings_modal}`,
      allButtonsHidden: true
    };

    dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, alertData));
  }, [closeAlert, customizedColumns, dispatch, saveSettings]);

  return (
    <StyledColumnSettingsWrapper>
      <SettingsIconOutlined />
      <Text color='blue' fontWeight={500} left='10' onClick={openSettings}>
        Spalten anpassen
      </Text>
    </StyledColumnSettingsWrapper>
  );
};

const LeasingEndComponent = () => {
  const userId = useTypedSelector(state => state.app.user).id;
  const [customizedColumns, setCustomizedColumns] = useState<CustomizedColumnForModalProps[] | null>(null);
  const [tableParams, setTableParams] = useState<TableParamsData>({
    pagination: {
      start: 1,
      number: 20
    },
    sort: {}
  });
  const { sessionSorting, setSessionSorting } = useSessionSorting(PAGES.LEASE_END, {});
  const { sessionFilter, setSessionFilter } = useSessionFilter(PAGES.LEASE_END, EMPTY_FILTER_STATE, EMPTY_FILTER_STATE);
  const { table, tableRefetch } = useLeaseEndQuery(tableParams, sessionFilter, sessionSorting);
  const [filtersContainerSize, setFiltersContainerSize] = useState(0);
  const { windowHeight } = useWindowSize();
  const scrollRef = useRef<HTMLDivElement>(null);
  const scrollValueRef = useRef(0);
  const downloadFile = useFileDownload({
    requestConfig: fleetRequestConfig.getLeaseEndExcel({
      ...tableParams,
      search: { predicateObject: { ...sessionFilter.filterForServer } },
      sort: sessionSorting
    }),
    fileName: 'Leasingende.xlsx',
    encodeConfig: {
      path: [],
      encodeWay: 'newBlob'
    }
  });

  useSetFilterFromLocationState(setSessionFilter, EMPTY_FILTER_STATE, EMPTY_FILTER_STATE);

  const loadPreferences = useCallback(async () => {
    let response: AxiosResponse<any>;
    try {
      response = await loadContactPreferences(userId, 'leasingende');

      if (response.data.data) {
        const actualSettings = JSON.parse(response.data.data);

        columns.forEach(columnDefault => {
          const currentColumn = columnDefault;
          const foundSetting = JSON.parse(response.data.data).find(
            (setting: CustomizedColumnForModalProps) => setting.columnId === columnDefault.columnId
          );
          if (!foundSetting) {
            actualSettings.push({
              title: currentColumn.name,
              propName: currentColumn.propName,
              isChecked: true,
              columnId: currentColumn.columnId
            });
          }
        });

        JSON.parse(response.data.data).forEach((setting: CustomizedColumnForModalProps) => {
          const existingColumn = columns.find(column => column.columnId === setting.columnId);

          if (!existingColumn) {
            const index = actualSettings.findIndex(
              (actualSetting: CustomizedColumnForModalProps) => actualSetting.columnId === setting.columnId
            );
            actualSettings.splice(index, 1);
          }
        });
        setCustomizedColumns(actualSettings);
      } else {
        return null;
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  }, [userId]);

  const pageChangeHandler = useCallback(
    (page: number) => {
      setTableParams({ ...tableParams, pagination: { ...tableParams.pagination, start: page } });
    },
    [tableParams]
  );

  const goToFirstPage = useCallback(() => {
    setTableParams({ ...tableParams, pagination: { ...tableParams.pagination, start: 1 } });
  }, [tableParams]);

  const sortHandler = useCallback(
    (sortParameter: string) => {
      setSessionSorting({
        predicate: sortParameter,
        reverse: sortParameter === sessionSorting.predicate ? !sessionSorting.reverse : false
      });
    },
    [sessionSorting, setSessionSorting]
  );

  useEffect(() => {
    loadPreferences();
  }, [loadPreferences]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      setFiltersContainerSize(entries[0].contentRect.height);
    });

    document.getElementById('filtersContainer') &&
      resizeObserver.observe(document.querySelector('#filtersContainer') as Element);

    return () => {
      document.getElementById('filtersContainer') &&
        resizeObserver.unobserve(document.querySelector('#filtersContainer') as Element);
    };
  }, [table]);

  useEffect(() => {
    const root = document.documentElement;
    const excessHeight = table ? (table?.totalCount > tableParams.pagination.number ? 320 : 255) : 0;
    root?.style.setProperty('--containerHeight', `${windowHeight - excessHeight - filtersContainerSize}px`);
  }, [filtersContainerSize, windowHeight, table, tableParams]);

  const getTableMinWidth = useCallback(() => {
    const columnsQuantity = customizedColumns ? getCustomizedOrder(customizedColumns, columns).length : columns.length;
    return columnsQuantity * 150;
  }, [customizedColumns]);

  const onScroll = useCallback(() => {
    scrollValueRef.current = scrollRef.current?.scrollLeft || 0;
  }, []);

  useEffect(() => {
    scrollValueRef.current && scrollRef.current ? (scrollRef.current.scrollLeft = scrollValueRef.current) : null;
  }, [table]);

  return (
    <Flex direction='column' paddingBottom={'80px'}>
      <ColumnsSettingsButton
        customizedColumns={customizedColumns ? customizedColumns : getDefaultColumns(columns)}
        refetch={tableRefetch}
        loadPreferences={loadPreferences}
      />
      {table && (
        <Flex align='flex-start'>
          <div id='filtersContainer' className={classes.filters}>
            <LeasingEndFilters
              goToFirstPage={goToFirstPage}
              filterData={table.filtersData}
              sessionFilter={sessionFilter}
              setSessionFilter={setSessionFilter}
              customizedColummns={customizedColumns}
            />
          </div>
          <Button
            component={ButtonComponentType.DownloadExcelIcon}
            onClick={downloadFile}
            disabled={!table}
            iconWidth={32}
            tooltip='Download File Excel'
          />
        </Flex>
      )}
      {table?.items.length ? (
        <div className={classes.table_container}>
          <StyledLeaseendContainer ref={scrollRef} onScroll={onScroll}>
            <StyledTableWrapper minWidth={getTableMinWidth()}>
              <TableComponent
                columns={customizedColumns ? getCustomizedOrder(customizedColumns, columns) : columns}
                rows={table.items}
                rootTag={{
                  name: RootTagName.Link,
                  propName: 'new_fuhrparkid',
                  to: `${PAGES.MY_VEHICLES_DETAILS}/?id=`
                }}
                sort={sortHandler}
                sortParams={sessionSorting}
                className={classes.table_wrapper}
              />
            </StyledTableWrapper>
          </StyledLeaseendContainer>
          {table.totalCount > tableParams.pagination.number && (
            <Pagination
              activePage={tableParams.pagination.start}
              itemsCountPerPage={tableParams.pagination.number}
              totalItemsCount={table.totalCount}
              onPageChange={pageChangeHandler}
              className={classes.pagination_wrapper}
            />
          )}
        </div>
      ) : (
        <StyledPlaceholderWrapper>
          <PanelPlaceholder title='Nichts gefunden' description='Diese Seite enthält keine Ergebnisse.' />
        </StyledPlaceholderWrapper>
      )}
    </Flex>
  );
};

const LeasingEndPage = () => {
  return (
    <SideMenuRightContainer>
      <LeasingEndComponent />
    </SideMenuRightContainer>
  );
};

export const CRM_VALUE = {
  company: 'new_sparteid'
};

export const EMPTY_FILTER_STATE = {
  new_ezl: null,
  new_fahrerid: null,
  new_hersteller: null,
  new_kstverantw: null,
  new_leasingende: null,
  uds_laufleistung: null,
  new_model: null,
  new_name: null,
  new_sparteid: null,
  uds_actualmileage: null,
  uds_averagemonthlymileage: null,
  uds_lease_return_date: null,
  uds_leaseenddate_extended: null,
  uds_replacementstate: null,
  uds_summeprojektion: null,
  uds_vsl_liefertermin_laut_handler: null,
  uds_leasinglaufzeit_decimal: null,
  uds_fahrzeugruckgabe: null,
  uds_preparationstate: null
};

export default LeasingEndPage;
