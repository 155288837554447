import { SessionFilter } from '../use-session-filter';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import useOverlay from 'hooks/use-overlay';
import { DEFAULT_TABLE_DATA } from 'pages/fahrzeugkosten-page/fahrzeugkosten-page';
import { PAGES } from 'common/enums';
import { getFahrzeugkostenFilters, getFahrzeugkostenTable } from 'services/vehicle-costs-service';
import { ResponseModel } from 'common/interfaces';
import { AxiosResponse } from 'axios';
import { isEmpty } from 'lodash';

export const useVehicleCostsQuery = (tableParams: TableParams, sessionFilter: SessionFilter) => {
  const [showOverlay, hideOverlay] = useOverlay();

  const {
    data: tableDataQuery,
    isFetching: isFetchingTable,
    isFetched
  } = useQuery<AxiosResponse<ResponseModel>>(
    [PAGES.VEHICLE_COSTS, tableParams, sessionFilter?.filterForServer],
    () => getFahrzeugkostenTable(tableParams, sessionFilter?.filterForServer),
    { enabled: !isEmpty(sessionFilter?.filterForServer) }
  );

  const { data: filterData, isFetching: isFetchingFilter } = useQuery<AxiosResponse<ResponseModel>>(
    [PAGES.VEHICLE_COSTS, 'filters'],
    () => getFahrzeugkostenFilters()
  );

  const isFetching = isFetchingTable || isFetchingFilter;

  useEffect(() => {
    if (isFetching) {
      showOverlay();
    } else {
      hideOverlay();
    }
  }, [isFetching]);

  return {
    table: tableDataQuery?.data?.data || DEFAULT_TABLE_DATA,
    filterData: filterData?.data?.data?.filtersData || {},
    isFetched
  };
};

interface TableParams {
  pagination: { start: number; number: number };
  sort: { predicate?: string; reverse?: boolean };
}
