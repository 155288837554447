import { AxiosRequestConfig } from 'axios';

class UnfoldedTaskRequestConfig {
  getUnfoldedTaskInfo = (taskId: string): AxiosRequestConfig => ({
    method: 'POST',
    url: 'CrmTask/GetTaskLeaseEndInfo',
    data: {
      taskId
    }
  });
}

export const unfoldedTaskRequestConfig = new UnfoldedTaskRequestConfig();
