import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';
import React from 'react';

export const PlumpLeftRightArrowIcon = ({ width = 24, height = 24, color: colorProps }: IconProps) => {
  const { defaultColor } = useDefaultIconsColor();
  const color = colorProps || defaultColor;
  return (
    <svg width={width} height={height} viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12.2 16.8564H9V18.8564L3 14.8564L9 10.8564V12.8564H14.6V14.8564L21 10.8564L14.6 6.85645V8.85645H12.2'
        stroke={color}
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};
